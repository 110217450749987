@import "assets/sass/_variables";

.city__party,
.depto__capital {
    padding: 0;
    display: flex;
    background-color: $c-wh;
    border-radius: 20px 20px 0 0;

    &-photo {
        position: relative;
        background-color: $c-1;
        border-radius: 10px;
        flex: 1 0 auto;
        margin-right: 20px;
        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            margin-right: 10px;
            flex: 1 0 auto;
            object-fit: cover;
            object-position: center;
            background-color: $c-dr;
            position: relative;
            top: 0;
            transition: all 0.2s;
            left: 0;
        }
    }
    &-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $c-dr;
        > span {
            font-family: $f-ppal;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        > strong {
            font-family: $f-second;
            font-weight: 200;
            line-height: 1;
        }
        &-date {
            font-family: $f-second;
            font-size: 16px;
            margin-top: 8px;
            color: $c-dr;
            font-weight: 400;
            opacity: 0.5;
        }
    }

    @media (min-width: 1301px) {
        width: calc(100% - (80px * 2));
        margin-left: 80px;
        margin-bottom: 40px;
        &-photo {
            width: 60px;
            height: 60px;
        }
        &-info {
            > strong {
                font-size: 30px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        width: calc(100% - (60px * 2));
        margin-left: 60px;
        margin-bottom: 30px;
        &-photo {
            width: 60px;
            height: 60px;
        }
        &-info {
            > strong {
                font-size: 30px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        width: calc(100% - (50px * 2));
        margin-left: 50px;
        margin-bottom: 40px;
        &-photo {
            width: 60px;
            height: 60px;
        }
        &-info {
            > strong {
                font-size: 30px;
            }
        }
    }
    @media (max-width: 720px) {
        width: calc(100% - (35px * 2));
        margin-left: 35px;
        margin-bottom: 30px;
        &-photo {
            width: 60px;
            height: 60px;
        }
        &-info {
            > strong {
                font-size: 24px;
            }
        }
    }
}

a {
    &.city__party,
    &.depto__capital {
        cursor: pointer;

        &:hover {
            .city__party,
            .depto__capital {
                &-info {
                    > strong {
                        color: $c-1;
                    }
                }
                &-photo {
                    > img {
                        top: -5px;
                        left: -5px;
                    }
                }
            }
        }
    }
}

.list__items-FaunaFlora,
.list__items-whatToDo,
.list__items-dishes {
    overflow: auto;
    &-container {
        scroll-behavior: smooth;
        > .item {
            text-align: center;
            position: relative;
            flex: 1 0 auto;

            > .tag {
                position: absolute;
                top: 10px;
                left: 15px;
                border-radius: 14px;
                font-size: 13px;
                font-family: $f-second;
                font-weight: 700;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $c-wh;
                opacity: 0.5;
                transition: all 0.2s;
                z-index: 11;
            }

            > [class*="fa-"] {
                position: absolute;
                bottom: 10px;
                right: 10px;
                z-index: 10;
                color: $c-wh;
                font-size: 18px;
                opacity: 0.8;
                transition: all 0.2s;
                pointer-events: none;
            }

            > img,
            > span,
            &::after {
                border-radius: 8px;
            }
            > img {
                width: 100%;
                height: 100%;
                flex: 1 0 auto;
                object-fit: cover;
                object-position: center;
                position: absolute;
                left: 0;
                top: 0;
                transition: all 0.2s;
            }

            > span {
                font-family: $f-ppal;
                font-weight: 900;
                transition: all 0.2s;
                opacity: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: left;
                line-height: normal;
                padding: 0 15px;
                color: rgba(255, 255, 255, 0.8);
                z-index: 10;
            }

            &::after {
                width: 100%;
                height: 100%;
                content: "";
                display: block;
                background-color: $c-1;
            }

            &-recommend {
                max-width: 150px;
                min-width: 150px;
                > .parag {
                    width: 100%;
                    height: 100%;
                    background-color: $c-gr;
                    border: 1px dashed $c-1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 8px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.2s;

                    > [class*="fa"] {
                        min-width: 35px;
                        max-width: 35px;
                        min-height: 35px;
                        max-height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: $c-1;
                        font-size: 20px;
                        color: $c-wh;
                        flex: 1 0 auto;
                    }
                    > span {
                        font-family: $f-ppal;
                        margin-top: 15px;
                        font-size: 15px;
                        line-height: normal;
                        strong {
                            font-weight: bold;
                        }
                    }
                }
            }

            &-link,
            &-recommend,
            &-detail {
                cursor: pointer;
                &:hover {
                    > img,
                    > span,
                    > .parag,
                    > .extra-actions {
                        top: -5px;
                        left: -5px;
                    }
                    > [class*="fa-"] {
                        bottom: 15px;
                        right: 15px;
                    }

                    > .tag {
                        top: 5px;
                        left: 10px;
                    }
                    > .parag {
                        border-style: double;
                    }
                    > span,
                    > .tag {
                        opacity: 0;
                    }
                }
            }

            &-detail {
                display: flex;
                align-items: center;
                background-color: $c-wh;
                transition: all 0.3s;
                > img {
                    width: 150px;
                }

                .extra-actions {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    z-index: 10;
                    transition: all 0.2s;
                    opacity: 0;
                    overflow: hidden;
                    border-radius: 8px;
                    > .ant-btn {
                        padding: 0;
                        width: 100%;
                        height: 50%;
                        border: none;
                        border-radius: 0;
                        > [class*="fa-"],
                        > span {
                            color: $c-wh;
                        }
                        &:hover {
                            background-color: $c-wh;
                            > [class*="fa-"],
                            > span {
                                color: $c-dr;
                            }
                        }
                    }
                }

                &:hover {
                    > [class*="fa"] {
                        opacity: 0;
                    }
                    > span {
                        opacity: 1;
                        color: transparent;
                    }
                    .extra-actions {
                        opacity: 1;
                        animation: showActions 0.8s ease-in-out;
                    }
                }
            }
        }
    }
    @media (min-width: 1301px) {
        &-container {
            margin-top: -5px;
            padding: 40px 80px;
            > .item {
                width: 150px;
                max-width: 150px;
                height: 150px;
                margin-left: 10px;
                > span {
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        &-container {
            padding: 30px 60px;
            margin-top: -5px;
            > .item {
                width: 150px;
                max-width: 150px;
                height: 130px;
                margin-left: 10px;
                > span {
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &-container {
            padding: 40px 50px;
            margin-top: -5px;
            > .item {
                width: 150px;
                max-width: 150px;
                height: 130px;
                margin-left: 10px;
                > span {
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: 720px) {
        &-container {
            padding: 35px;
            margin-top: -5px;
            > .item {
                width: 150px;
                max-width: 150px;
                height: 130px;
                margin-left: 10px;
                > span {
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.5);
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
.list__items-FaunaFlora,
.list__items-whatToDo,
.list__items-dictionary,
.list__items-dishes {
    position: relative;
    > .ant-btn {
        &-prev,
        &-next {
            width: 45px;
            height: 45px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 20;
            opacity: 0;
            transition: all 0.4s;
        }
        &-prev {
            left: 20px;
        }
        &-next {
            right: 20px;
        }
    }

    &:hover {
        > .ant-btn {
            &-next,
            &-prev {
                opacity: 1;
            }
        }
    }
    @media (max-width: 860px) {
        > .ant-btn {
            display: none;
        }
    }
}
.list__items-FaunaFlora {
    &-container {
        > .item {
            > span {
                display: flex;
                align-items: flex-end;
                padding: 0 15px 15px;
                font-size: 17px;
            }
        }
    }
}

@keyframes showActions {
    0% {
        pointer-events: none;
        opacity: 0;
    }
    25% {
        pointer-events: none;
        opacity: 0;
    }
    50% {
        pointer-events: none;
        opacity: 1;
    }
    75% {
        pointer-events: none;
        opacity: 1;
    }
    100% {
        pointer-events: all;
        opacity: 1;
    }
}
