@import "assets/sass/_variables";

.radio__default {
    width: 100%;
    height: auto;
    display: flex;
    border-radius: 10px;
    .ant-radio-button-wrapper {
        width: 50%;
        height: 70px;
        border: none;
        padding: 0;
        border: 1px solid $c-wh;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.2s;
        &::before {
            display: none;
        }
        > span:not(.ant-radio-button) {
            color: $c-wh;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 10px;
            > [class*="fa-"] {
                font-size: 20px;
                margin-bottom: 10px;
            }
            > span {
                font-family: $f-second;
                line-height: 1;
                padding: 0;
            }
        }
        &-checked {
            background: $c-wh !important;
            border-color: $c-wh !important;

            span:not(.ant-radio-button) {
                color: $c-dr;
            }
        }
        &:first-child {
            border-radius: 10px 0 0 10px;
        }
        &:last-child {
            border-radius: 0 10px 10px 0;
            border-left: none;
        }
    }

    &-group {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        .ant-radio {
            &-wrapper {
                margin-right: 0;
                position: relative;
                white-space: initial;
                display: flex;
                align-items: flex-start;
                > span {
                    &.ant-radio {
                        margin-right: 15px;
                    }
                    &:not(.ant-radio) {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: 0;
                        > span {
                            font-size: 18px;
                            font-weight: 200;
                            line-height: 1.2;
                        }
                        > .ant-form-item {
                            margin-top: 10px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &-inner {
                width: 25px;
                height: 25px;
                margin-top: -4px;
                &::after {
                    width: 13px;
                    height: 13px;
                    top: 5px;
                    left: 5px;
                    background-color: $c-1;
                }
            }
            &-input {
                &:focus {
                    + .ant-radio-inner {
                        box-shadow: 0 0 0 3px $c-gr;
                    }
                }
            }
            &-checked {
                &::after {
                    display: none;
                }
                + span {
                    &:not(.ant-radio) {
                        > span {
                            font-weight: 500;
                        }
                    }
                }
                .ant-radio {
                    &-inner {
                        border-color: $c-1;
                    }
                }
            }
            &:hover {
                .ant-radio-inner {
                    border-color: $c-1;
                }
            }
        }
    }
}
