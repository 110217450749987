@import "assets/sass/_variables";

.post__detail {
    &-container {
        .post__detail {
            &-back {
                > a {
                    display: flex;
                    align-items: center;
                    > [class*="fa-"] {
                        color: $c-1;
                        font-size: 20px;
                        margin-right: 15px;
                    }
                    > span {
                        color: $c-dr;
                        font-size: 17px;
                        font-family: $f-second;
                        margin-top: -3px;
                        opacity: 0.8;
                    }

                    &:hover {
                        > span {
                            opacity: 1;
                        }
                    }
                }
            }
            &-headline {
                display: flex;
                flex-direction: column;
                position: relative;

                > img {
                    display: block;
                }
                .copy-image {
                    margin-top: 10px;
                    font-family: $f-second;
                    font-size: 13px;
                    opacity: 0.5;
                    font-weight: 400;
                }
                > .title__ppal {
                    margin: 20px 0 0;
                }
            }
            &-actions {
                display: flex;
                .person {
                    display: flex;
                    > img {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        margin-right: 15px;
                    }
                    &__data {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-family: $f-second;
                        &-name {
                            font-size: 15px;
                            margin-bottom: 2px;
                            > strong {
                                font-weight: bold;
                            }
                            > a {
                                position: relative;
                                top: 3px;
                                margin-left: 5px;
                                > [class*="fa-"] {
                                    color: $c-dr;
                                    opacity: 0.5;
                                    font-size: 20px;
                                }
                                &:hover {
                                    > [class*="fa-"] {
                                        color: $c-1;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                        &-date {
                            font-size: 14px;
                            opacity: 0.6;
                            font-weight: 300;
                        }
                    }
                }
                .share {
                    display: flex;
                    margin-top: 0;
                    .ant-btn {
                        max-width: 40px;
                        height: 40px;
                        margin-right: 5px;
                        flex: 1 0 auto;
                        padding: 0;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            &-content {
                margin-bottom: 50px;
                .text__enr {
                    margin-bottom: 30px;
                    > h2,
                    > h3,
                    > h4,
                    > h5 {
                        font-family: $f-ppal;
                        strong,
                        b {
                            font-weight: 600;
                        }
                        em,
                        i {
                            font-style: italic;
                        }
                    }
                    > h2 {
                        font-size: 28px;
                        margin-bottom: 10px;
                    }
                    > h3 {
                        font-size: 26px;
                    }
                    > p {
                        line-height: 1.4;
                        > a {
                            color: $c-1;
                            text-decoration: underline;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    > h6 {
                        background-color: $c-gr;
                        border-radius: 10px;
                        padding: 20px;
                        font-family: $f-second;
                        font-style: 20px;
                        line-height: 1.3;
                        > a {
                            color: $c-1;
                            text-decoration: underline;
                            font-weight: bold;
                        }
                    }

                    > ol,
                    > ul {
                        display: flex;
                        flex-direction: column;
                        padding-left: 10px;
                        > li {
                            position: relative;
                            padding-left: 40px;
                            margin-bottom: 20px;
                            font-family: $f-second;
                            line-height: 1.3;
                            &::before {
                                width: 25px;
                                height: 25px;
                                background-color: $c-gr;
                                margin-right: 10px;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            strong,
                            b {
                                font-weight: 600;
                            }
                            > a {
                                color: $c-1;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    > ol {
                        counter-reset: counter-ols;
                        padding-bottom: 20px;
                        > li {
                            &::before {
                                content: counter(counter-ols);
                                counter-increment: counter-ols;
                                color: rgba(0, 0, 0, 0.6);
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;
                            }
                        }
                    }
                    > ul{
                        background-color: transparent;
                        padding-top: 0;
                        margin-top: 0;
                        > li{
                            &::before{
                                border-radius: 100%;
                                content: '';
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .image__full {
                    width: calc(100% + 100px);
                    margin-left: -50px;
                    margin-bottom: 30px;

                    > img {
                        width: 100%;
                    }
                    > .copy-image {
                        margin-top: 6px;
                        font-size: 13px;
                        font-family: $f-second;
                        font-weight: 300;
                        opacity: 0.6;
                        padding: 0 50px;
                    }
                }
                .ad__google {
                    margin-bottom: 30px;
                }
            }
        }
        @media (min-width: 861px) {
            margin: 100px auto 80px !important;
            width: 630px;
        }
        @media (min-width: 721px) and (max-width: 860px) {
            $spacing: 80px;
            width: 100%;
            padding: 0 $spacing;
            margin: 90px 0 80px;
        }
        @media (min-width: 721px) {
            height: auto;
            > .post__detail {
                &-back {
                    margin-bottom: 30px;
                }
                &-headline {
                    margin-bottom: 30px;
                    > img {
                        width: calc(100% + 160px);
                        margin-left: -80px;
                    }
                }
                &-actions {
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 30px;
                }
                &-content {
                    .text__enr {
                        > h2 {
                            font-size: 25px;
                        }
                        > h3 {
                            font-size: 22px;
                        }
                        > p {
                            margin-bottom: 20px;
                            &.block-img {
                                > img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 720px) {
            $spacing: 35px;
            height: auto;
            width: 100%;
            margin: 0 0 35px !important;
            padding: 0 $spacing;
            > .post__detail {
                &-back {
                    position: fixed;
                    top: 20px;
                    left: 20px;
                    > a {
                        width: 40px;
                        height: 40px;
                        background-color: $c-wh;
                        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        > [class*="fa-"] {
                            color: $c-dr;
                            margin-right: 0;
                        }
                        > span {
                            display: none;
                        }
                    }
                }
                &-headline {
                    margin-bottom: 25px;
                    > img {
                        width: calc(100% + (35px * 2));
                        height: auto;
                        margin-left: -$spacing;
                        object-fit: cover;
                        object-position: center;
                    }
                    > .title__ppal {
                        font-size: 30px;
                        margin-bottom: 0;
                    }
                }
                &-actions {
                    margin-bottom: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    .share {
                        width: 100%;
                        margin-top: 20px;
                        justify-content: space-between;
                    }
                }
                &-content {
                    .text__enr {
                        > h2 {
                            font-size: 25px;
                        }
                        > h3 {
                            font-size: 22px;
                        }
                        > p {
                            line-height: 1.6;
                            margin-bottom: 25px;
                            &.block-img {
                                > img {
                                    width: calc(100% + (35px * 2));
                                    margin-left: -$spacing;
                                }
                            }
                        }
                    }
                    .image__full {
                        width: calc(100% + (35px * 2));
                        margin-left: -35px;
                        > .copy-image {
                            padding: 0 35px;
                        }
                    }
                }
            }
        }
    }
}
