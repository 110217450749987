@import "assets/sass/_variables";

.access__principles {
    position: fixed;
    counter-reset: counterPrinciple;
    display: flex;
    flex-direction: column;
    background-color: $c-wh;
    z-index: 2;

    &-item {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        &::before {
            color: $c-dr;
            font-size: 17px;
            font-family: $f-ppal;
            content: counter(counterPrinciple);
            counter-increment: counterPrinciple;
        }
        &-active {
            background-color: $c-1;
            &::before {
                color: $c-wh;
            }
        }
    }
    @media (min-width: 1301px) {
        top: 100px;
        left: 38px;
        border-radius: 40px;
        padding: 5px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        &-item {
            border-radius: 100%;
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        top: 100px;
        left: 35px;
        border-radius: 40px;
        padding: 5px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        &-item {
            border-radius: 100%;
        }
    }

    @media (max-width: 860px) {
        top: 59px;
        left: 0;
        width: 100%;
        flex-direction: row;
        padding: 5px;
        background-color: $c-gr-2;
        &-item {
            width: auto;
            flex: 1 0 auto;
            border-radius: 20px;
        }
    }
}

.lnt__principles-list {
    counter-reset: counter-principles;
    .lnt__principle {
        display: flex;

        &-message {
            width: 100%;
            &-title {
                display: flex;
                flex-direction: column;
                position: relative;
                &::before {
                    content: "#";
                    color: $c-dr;
                    opacity: 0.4;
                    font-family: $f-ppal;
                    position: absolute;
                    left: 0;
                    transition: all 0.2s;
                }

                > .title__counter {
                    text-transform: uppercase;
                    font-family: $f-ppal;
                    letter-spacing: 1px;
                    color: $c-dr;
                    font-weight: 600;
                    opacity: 0.5;
                    margin-bottom: 4px;
                    transition: all 0.2s;
                    &::after {
                        margin-left: 5px;
                        content: counter(counter-principles);
                        counter-increment: counter-principles;
                    }
                }

                &:hover {
                    > .title__counter,
                    &::before {
                        color: $c-1;
                        opacity: 1;
                    }
                }
            }
        }
        &-image {
            background-color: $c-gr;
            flex: 1 0 auto;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    @media (min-width: 1301px) {
        margin-top: -40px;
        .lnt__principle {
            padding-top: 120px;
            &-image {
                width: 300px;
                height: 400px;
            }
            &-message {
                padding: 0 50px;
                &-title {
                    max-width: 500px;
                    margin-bottom: 10px;
                    &::before {
                        font-size: 40px;
                        top: 20px;
                    }
                }
                &-title,
                > .text__enr-2 {
                    padding-left: 40px;
                }
            }

            &:nth-child(even) {
                .lnt__principle {
                    &-image {
                        margin-right: -30px;
                        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
                    }
                }
            }
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .lnt__principle {
                    &-image {
                        margin-left: -30px;
                        clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
                    }
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        margin-top: -40px;
        .lnt__principle {
            padding-top: 120px;
            &-image {
                width: 200px;
                height: 300px;
            }
            &-message {
                padding: 0 50px;
                &-title {
                    max-width: 500px;
                    margin-bottom: 10px;
                    &::before {
                        font-size: 40px;
                        top: 20px;
                    }
                }
                &-title,
                > .text__enr-2 {
                    padding-left: 40px;
                }
            }

            &:nth-child(even) {
                .lnt__principle {
                    &-image {
                        margin-right: 0;
                        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
                    }
                }
            }
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .lnt__principle {
                    &-image {
                        margin-left: 0;
                        clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
                    }
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        margin-top: -150px;
        .lnt__principle {
            padding-top: 150px;
            &-image {
                width: 200px;
                height: 300px;
            }
            &-message {
                &-title {
                    margin-bottom: 10px;
                    &::before {
                        font-size: 40px;
                        top: 20px;
                    }
                }
                &-title,
                > .text__enr-2 {
                    padding-left: 40px;
                }
            }

            &:nth-child(even) {
                .lnt__principle {
                    &-message {
                        padding-right: 50px;
                    }
                    &-image {
                        margin-right: -80px;
                        clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
                    }
                }
            }
            &:nth-child(odd) {
                flex-direction: row-reverse;
                .lnt__principle {
                    &-message {
                        padding-left: 20px;
                    }
                    &-image {
                        margin-left: -80px;
                        clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        .lnt__principle {
            position: relative;
            flex-direction: column-reverse;
            margin-bottom: 50px;
            &-image {
                width: calc(100% + 70px);
                margin-left: -35px;
                margin-bottom: 30px;
                height: 200px;
            }
            &-message {
                &-title {
                    margin-bottom: 10px;
                    &::before {
                        font-size: 40px;
                        top: 20px;
                        left: -10px;
                    }
                }
                &-title {
                    padding-left: 35px;
                }
                > .text__enr-2 {
                    padding-left: 0;
                }
            }
        }
    }
}
