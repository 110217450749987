@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "assets/sass/variables";
@import "assets/sass/mixins";

.image-gallery {
    &-banner {
        height: 100%;
        width: 100%;
        [class*="image-gallery-"] {
            &:not(button) {
                &:not(.image-gallery-description) {
                    &:not(.image-gallery-index) {
                        &:not(.image-gallery-bullets) {
                            &:not(.image-gallery-bullets-container) {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
        .image-gallery {
            &-slide {
                opacity: 0;
                transition: opacity 450ms ease-out !important;
                transform: none !important;
                &.center {
                    opacity: 1;
                }
            }
            &.images-auto {
                .image-gallery-slide {
                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .image-gallery-image {
                            width: auto !important;
                            height: auto !important;
                        }
                    }
                }
            }
            &-content {
                overflow: hidden;
                &.fullscreen {
                    padding: 30px;

                    .image-gallery-slide {
                        .image-gallery-image {
                            object-fit: contain;
                        }
                    }

                    .image-gallery-fullscreen-button {
                        &::before {
                            @include fontAwesome("\f422");
                        }
                    }

                    @media (max-width: 500px) {
                        background-color: #000;
                        padding: 0;
                        .image-gallery-image {
                            border-radius: 0;
                        }
                    }
                }
            }
            &-icon {
                padding: 0;
                width: 40px;
                height: 40px;
                filter: none;
                transform: none;
                top: auto;
                left: auto;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(255, 255, 255, 0.7);
                backdrop-filter: blur(3px);
                > svg {
                    display: none;
                }
                &::before {
                    color: $c-dr;
                    font-weight: 200;
                    font-size: 20px;
                }
                &:hover {
                    background-color: $c-1;
                    &::before {
                        color: $c-wh;
                    }
                }

                @media (min-width: 861px) {
                    bottom: 15px;
                }

                @media (max-width: 860px) {
                    bottom: 30px;
                }
            }
            &-index {
                @media (min-width: 1101px) {
                    display: none;
                }
                @media (min-width: 861px) and (max-width: 1100px) {
                    display: none;
                }
                @media (max-width: 860px) {
                    bottom: 35px;
                    left: 122px;
                    right: auto;
                    top: auto;
                    padding: 6px 12px;
                    font-family: $f-second;
                    font-size: 14px;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 5px;
                }
            }

            &-fullscreen-button {
                &::before {
                    @include fontAwesome("\f424");
                }

                @media (min-width: 1101px) {
                    right: 40px;
                }

                @media (min-width: 861px) and (max-width: 1100px) {
                    right: 40px;
                }

                @media (max-width: 860px) {
                    right: auto;
                    left: 75px;
                }
            }
            &-left-nav {
                &::before {
                    @include fontAwesome("\f053");
                }

                @media (min-width: 1101px) {
                    right: 130px;
                }
                @media (min-width: 861px) and (max-width: 1100px) {
                    right: 130px;
                }
                @media (max-width: 860px) {
                    display: none;
                }
            }
            &-right-nav {
                &::before {
                    @include fontAwesome("\f054");
                }

                @media (min-width: 1101px) {
                    right: 85px;
                }
                @media (min-width: 861px) and (max-width: 1100px) {
                    right: 85px;
                }
                @media (max-width: 860px) {
                    display: none;
                }
            }
            &-bullets {
                @media (min-width: 861px) {
                    display: none;
                }
                width: auto;
                right: auto;
                left: 130px;
                bottom: 42px;
                &-container {
                    > .image-gallery-bullet {
                        padding: 0;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            &-slide {
                > div {
                    width: 100%;
                    height: 100%;
                    > .image-gallery {
                        &-image {
                            max-height: none !important;
                            object-fit: cover;
                            object-position: center;
                        }
                        &-description {
                            padding: 0;
                            background-color: transparent;
                            font-family: $f-second;
                            font-weight: 200;
                            font-size: 14px;

                            @media (min-width: 861px) {
                                left: 15px;
                                bottom: 15px;
                                text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
                                opacity: 1;
                            }
                            @media (max-width: 860px) {
                                transform: rotate(-90deg);
                                transform-origin: top left;
                                bottom: 20px;
                                left: 5px;
                                opacity: 0.7;
                                text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4);
                            }
                        }
                    }
                }
            }
        }
    }
}
