@import "assets/sass/_variables";

.share {
    margin-top: 10px;
    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        > img {
            width: 55px;
            height: 55px;
            margin-right: 20px;
            border-radius: 5px;
            object-fit: cover;
            object-position: center;
            flex: 1 0 auto;
        }
        > .title__ppal {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-weight: 200;
            line-height: 1;
            font-size: 15px;
            margin-bottom: 0 !important;
            strong{
                font-weight: 500;
                font-size: 25px;
            }
        }
    }
    &-body {
        .list {
            &__social {
                display: grid;
                gap: 15px 10px;
                grid-template-columns: repeat(2, 1fr);
                .ant-btn {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 400px) {
        &-body {
            .list {
                &__social {
                    grid-template-columns: repeat(2, 1fr);
                    .ant-btn{
                        padding: 0;
                        text-align: left;
                        border: none;
                        border-radius: 0;
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}
