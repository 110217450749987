@import "assets/sass/_variables";
@import "../../../assets/sass/animations/visited";

.dest__sidebar-interactions {
    display: flex;
    position: absolute;
    z-index: 11;
    background-color: $c-wh;
    > li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.2s;
        background-color: $c-wh;
        box-shadow: inset 0 0 0 20px $c-wh;
        margin-top: 0;

        > [class*="fa-"] {
            font-size: 18px;
        }

        &:hover {
            &:not(.dest__sidebar-interactions-visited) {
                background-color: $c-gr;
                box-shadow: inset 0 0 0 20px $c-gr;
            }
        }

        &.disabled {
            opacity: 0.6;
            &:hover {
                background-color: transparent;
            }
        }
    }

    &::before,
    &::after {
        content: "";
        background-color: transparent;
        position: absolute;
        border-radius: 100%;
    }

    @media (min-width: 861px) {
        flex-direction: column;
        top: 80px;
        left: -25px;
        padding: 5px;
        border-radius: 22px;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
        &::before,
        &::after {
            width: 20px;
            height: 20px;
            left: 5px;
        }
        &::before {
            top: -20px;
            transform: rotate(-40deg);
            box-shadow: 2px 7px 0px -2px $c-wh;
        }
        &::after {
            bottom: -20px;
            transform: rotate(-140deg);
            box-shadow: -2px 7px 0px -2px $c-wh;
        }
        > li {
            width: 40px;
            height: 40px;
            margin-bottom: 5px;
            > [class*="fa-"] {
                margin-right: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        top: -24px;
        right: 45px;
        padding: 5px;
        border-radius: 25px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        transition: right 0.3s;
        &--tablet {
            position: fixed;
            top: 78px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            &::before,
            &::after {
                display: none;
            }
        }
        &::before,
        &::after {
            width: 20px;
            height: 20px;
            top: 5px;
        }
        &::before {
            left: -18px;
            transform: rotate(-14deg);
            box-shadow: 2px 7px 0px -2px $c-wh;
        }
        &::after {
            right: -18px;
            transform: rotate(40deg);
            box-shadow: -1px 7px 0px -3px $c-wh;
        }
        > li {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            > [class*="fa-"] {
                opacity: 0.5;
            }
            &.active {
                > [class*="fa-"] {
                    opacity: 1;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }

        &--fixed {
            position: fixed;
            top: 68px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

            &::before,
            &::after {
                display: none;
            }
            > li {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    @media (max-width: 720px) {
        top: -24px;
        right: 25px;
        padding: 5px;
        border-radius: 25px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        transition: right 0.3s;
        &--mobile {
            position: fixed;
            top: 18px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            &::before,
            &::after {
                display: none;
            }
        }
        &::before,
        &::after {
            width: 20px;
            height: 20px;
            top: 5px;
        }
        &::before {
            left: -18px;
            transform: rotate(-14deg);
            box-shadow: 2px 7px 0px -2px $c-wh;
        }
        &::after {
            right: -18px;
            transform: rotate(40deg);
            box-shadow: -1px 7px 0px -3px $c-wh;
        }
        > li {
            width: 40px;
            height: 40px;
            margin-right: 5px;
            > [class*="fa-"] {
                opacity: 0.5;
            }
            &.active {
                > [class*="fa-"] {
                    opacity: 1;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }

        &--fixed {
            position: fixed;
            top: 68px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

            &::before,
            &::after {
                display: none;
            }
            > li {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
