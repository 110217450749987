@import "assets/sass/_variables";

.search {
    &__big {
        display: flex;
        position: relative;
        > .ant {
            &-input {
                background-color: $c-gr-2;
                border: 1px solid transparent;
                border-radius: 10px;
                height: 100%;
                padding: 0 100px 0 20px;
                font-size: 18px;
                &:hover {
                    border-color: $c-gr-2;
                    background-color: $c-wh;
                    box-shadow: 4px 4px 0 0 $c-gr-2;
                }
                &:focus {
                    background-color: $c-wh;
                    box-shadow: 4px 4px 0 0 $c-2;
                    border: 1px solid $c-2;
                    padding-right: 130px;
                    + .ant-btn {
                        background-color: $c-3;
                        > [class*="fa-"] {
                            color: $c-wh;
                        }
                    }
                }
                // placeholder
                &::placeholder {
                    color: $c-dr;
                    opacity: 0.6;
                }
            }
        }

        &-actions {
            height: 45px;
            display: flex;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
            .ant {
                &-btn {
                    width: 45px;
                    height: 100%;
                    padding: 0;
                    border: none;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.2s;
                    > [class*="fa-"] {
                        color: $c-dr;
                        margin-right: 0;
                    }
                    &:first-child{
                        background-color: transparent !important;
                        margin-right: 5px;
                        transform: scale(0);
                        > [class*="fa-"]{
                            color: $c-dr !important;
                            opacity: .6;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &-active {
            > .ant {
                &-input {
                    background-color: $c-wh;
                    border-color: $c-2;
                    + .search__big {
                        &-actions {
                            .ant-btn {
                                background-color: $c-3;
                                &:first-child{
                                    transform: scale(1);
                                }
                                > [class*="fa-"] {
                                    color: $c-wh;
                                }
                            }
                        }
                    }
                    &:hover {
                        border-color: $c-2;
                    }
                }
            }
        }
    }
}
