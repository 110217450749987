@import "assets/sass/_variables";

.lnt__message {
    background-color: #4d5858;
    display: flex;
    align-items: center;

    &-content {
        > .ant-typography {
            color: $c-wh;
            font-weight: 200;
            font-size: 15px;
            opacity: 0.7;
            > strong{
                color: #c2d500;
                text-transform: uppercase;
            }
        }
        > a {
            display: flex;
            align-items: center;
            color: $c-wh;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 2px;
            font-weight: 500;
            font-family: $f-second;
            > [class*="fa-"] {
                font-size: 20px;
                margin-right: 10px;
                opacity: 0.5;
                transition: all 0.2s;
            }

            &:hover {
                > [class*="fa-"] {
                    color: #c2d500;
                    opacity: 1;
                    margin-right: 20px;
                    margin-left: 10px;
                }
            }
        }
    }

    @media (min-width: 1301px) {
        padding: 40px 80px;

        &-image {
            height: 70px;
            margin-right: 20px;
        }
        &-content {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            > .title__ppal {
                margin-bottom: 5px;
            }
            > a {
                margin-top: 10px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        padding: 35px 60px;
        &-image {
            height: 70px;
            margin-right: 20px;
        }
        &-content {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            > .title__ppal {
                margin-bottom: 5px;
            }
            > a {
                margin-top: 10px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        padding: 40px 50px;
        &-image {
            height: 70px;
            margin-right: 20px;
        }
        &-content {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid rgba(255, 255, 255, 0.1);
            > .title__ppal {
                margin-bottom: 5px;
            }
            > a {
                margin-top: 10px;
            }
        }
    }
    @media (max-width: 720px) {
        padding: 35px;
        flex-direction: column;
        align-items: flex-start;
        &-image {
            height: 70px;
            margin-bottom: 20px;
        }
        &-content {
            display: flex;
            flex-direction: column;
            > .title__ppal {
                margin-bottom: 5px;
            }
            > a {
                margin-top: 10px;
            }
        }
    }
}
