@import "assets/sass/variables";
@import "assets/sass/mixins";

.blog__main {
    > .container {
        > .blog__main {
            &-featured,
            &-latest {
                display: grid;
                .item {
                    &__blog {
                        border-radius: 17px;

                        &-content {
                            background-color: $c-gr;
                            transition: all 0.2s;
                            overflow: hidden;
                            position: relative;

                            &::after {
                                content: "";
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: $c-dr;
                                z-index: 1;
                                opacity: 0.45;
                            }

                            > .category {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                padding: 10px 15px;
                                border-radius: 20px;
                                background-color: rgba(255, 255, 255, 0.8);
                                z-index: 2;
                                font-family: $f-second;
                                font-size: 12px;
                                color: $c-dr;
                            }

                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                pointer-events: none;
                            }
                        }

                        &-content,
                        &-false {
                            width: 100%;
                            height: 100%;
                            border-radius: 15px;
                        }

                        &-false {
                            box-shadow: 7px 7px 0 0 $c-2, 8px 8px 20px 0 rgba(0, 0, 0, 0.3);
                            padding: 20px 30px;
                            margin-top: -7px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            > .parag__ppal {
                                margin: 10px 0 20px;
                                font-size: 16px;
                                line-height: 1.3;
                                font-weight: $f-second;
                            }

                            > .social {
                                display: flex;
                                > li {
                                    margin-right: 25px;
                                    > a {
                                        > [class*="fa-"] {
                                            font-size: 20px;
                                            color: $c-dr;
                                            opacity: 0.5;
                                            transition: all 0.2s;
                                        }
                                        &:hover {
                                            background-color: $c-gr;
                                            > [class*="fa-"] {
                                                opacity: 1;
                                            }
                                        }
                                    }
                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .item__blog {
                                &-content {
                                    margin-left: -7px;
                                    margin-top: -7px;
                                }
                            }
                        }
                    }
                }
            }
            &-featured {
                .item {
                    &__blog {
                        background-color: $c-1;
                    }
                }
            }
            &-latest {
                .item {
                    &__blog {
                        background-color: $c-2;
                    }
                }
            }
            &-all {
                display: grid;
                .item {
                    &__blog {
                        &-content {
                            display: flex;
                            flex-direction: column;

                            &-image {
                                width: 100%;
                                height: 120px;
                                background-color: $c-2;
                                border-radius: 12px;
                                margin-bottom: 10px;
                                > img {
                                    width: 100%;
                                    height: 120px;
                                    object-position: center;
                                    object-fit: cover;
                                    border-radius: 10px;
                                    transition: all 0.2s;
                                }
                            }

                            .pre__info {
                                color: $c-dr;
                                padding: 0 10px;
                                &-title {
                                    margin-bottom: 10px;
                                    font-size: 18px;
                                    line-height: 1.1;
                                }
                                &-date {
                                    font-size: 14px;
                                }
                            }
                            &:hover {
                                .item__blog-content-image {
                                    > img {
                                        margin-left: -6px;
                                        margin-top: -6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &-featured,
            &-latest {
                .pre__info {
                    position: absolute;
                    bottom: 20px;
                    color: $c-wh;
                    padding: 0 20px;
                    text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
                    &-title {
                        margin-bottom: 10px;
                        font-size: 22px;
                        line-height: 1.1;
                    }
                    &-date {
                        font-size: 15px;
                    }
                }
            }
            &-featured,
            &-latest,
            &-all {
                .pre__info {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    z-index: 2;
                    &-title {
                        font-family: $f-ppal;
                    }
                    &-date {
                        font-family: $f-second;
                        font-weight: 300;
                        opacity: 0.9;
                    }
                }
            }
        }
    }

    @media (min-width: 1301px) {
        > .container {
            > .blog__main {
                &-featured,
                &-latest {
                    gap: 20px;
                    margin-bottom: 80px;
                }
                &-featured {
                    grid-template-columns: 2fr 1fr 1fr;
                    grid-template-rows: minmax(230px, 230px);
                }
                &-latest {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: minmax(230px, 230px);
                    padding: 7px;
                    margin-left: -10px;
                }
                &-all {
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 20px;
                    row-gap: 40px;
                }
            }
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        $spacing: 60px;
        margin-top: 100px !important;
        > .container {
            > .blog__main {
                &-featured,
                &-latest {
                    gap: 20px;
                    margin-bottom: 50px;
                }
                &-featured {
                    grid-template-columns: 2fr 1fr 1fr;
                    grid-template-rows: minmax(230px, 230px);
                }
                &-latest {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: minmax(230px, 230px);
                    padding: 7px 0;
                }
                &-all {
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 20px;
                    row-gap: 40px;
                }
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 80px;
        margin-top: 90px !important;
        > .container {
            .blog__main {
                &-featured,
                &-latest {
                    width: calc(100% + 80px * 2);
                    margin-left: -$spacing;
                }
            }
            > .blog__main {
                &-featured,
                &-latest {
                    display: flex;
                    overflow: auto;
                    padding: 10px $spacing 50px;
                    @include scrollHor($c-wh, $c-wh, 2px);

                    .item__blog,
                    .item__blog-false{
                        width: 257px;
                        max-width: 257px;
                        height: 230px;
                        flex: 1 0 auto;
                        margin-right: 20px;

                        &:first-child:not(.item__blog-false){
                            width: 400px;
                            max-width: 400px;
                        }

                        &:last-child{
                            margin-right: 0;
                        }
                    }

                    &::after {
                        content: "";
                        width: 60px;
                        height: 1px;
                        display: block;
                    }
                }

                &-all {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;
                }
            }
        }
    }

    @media (max-width: 720px) {
        $spacing: 35px;
        > .container {
            .blog__main {
                &-featured,
                &-latest {
                    width: calc(100% + 35px * 2);
                    margin-left: -$spacing;
                }
            }
            > .blog__main {
                &-featured,
                &-latest {
                    display: flex;
                    overflow: auto;
                    padding: 10px $spacing 50px;
                    @include scrollHor($c-wh, $c-wh, 2px);

                    &::after {
                        content: "";
                        width: 15px;
                        height: 1px;
                        display: block;
                    }

                    .item__blog,
                    .item__blog-false{
                        width: 257px;
                        max-width: 257px;
                        height: 230px;
                        flex: 1 0 auto;
                        margin-right: 15px;

                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }

                &-all {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px 40px;
                    padding-top: 10px;
                }
            }
        }
    }
}
