.visited-page {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__content {
    flex: 1;
  }

  &--full {
    .visited-page {
      &__content {
        display: grid;
        grid-auto-rows: 200px;
        grid-template-columns: repeat(auto-fit, 150px);
        gap: 25px;
        justify-content: center;
      }
    }
  }

  &--loading, &--empty {
    .visited-page {
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}