@import "assets/sass/_variables";

.pnn__message {
    background-color: #4d5858;
    display: flex;
    
    > .ant-typography{
        color: $c-wh;
        > p{
            font-family: $f-second;
            font-weight: 200;
            line-height: 1.4;
            > a{
                color: $c-wh;
                text-decoration: underline;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    @media (min-width: 1301px) {
        padding: 0 80px 40px;
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        padding: 35px 60px;
    }
    @media (min-width: 721px) and (max-width: 860px) {
        padding: 40px 50px;
    }
    @media (max-width: 720px) {
        padding: 35px;
    }
}