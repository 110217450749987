@import "../variables";
@import "../mixins";

.ant-dropdown {
    min-width: auto !important;
    .menu__container {
        max-height: 300px;
        overflow: auto;
        background-color: $c-wh;
        @include scroll(transparent, rgba(0, 0, 0, 0.15));
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .ant-menu {
            border-right: none;
            background-color: transparent;
            padding: 0 0 5px 15px;
            &-item-group {
                margin-top: 5px;
                &-title {
                    text-transform: uppercase;
                    color: $c-dr;
                    padding: 0;
                    opacity: 0.6;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 2px;
                }
                &-list {
                    margin: 0;
                }
            }
            &-item {
                padding: 0;
                margin: 0 0 0 -15px;
                height: auto;
                > a,
                > span,
                > .ant-btn {
                    width: 100%;
                    line-height: normal;
                    font-family: $f-second;
                    text-align: left;
                    height: auto;
                    font-weight: 400;
                    font-size: 13px;
                    padding: 12px 15px;
                    white-space: normal;
                    display: block;
                    color: $c-dr;
                    &:hover {
                        font-weight: 400;
                    }
                }
                > .ant-btn {
                    border: none;
                    background-color: transparent;
                }
                &:hover {
                    background-color: $c-gr-3;
                }
                &-disabled {
                    opacity: 0.5;
                    > .ant-btn {
                        pointer-events: none;
                    }
                    &:hover {
                        background-color: inherit;
                    }
                }
                &-selected {
                    background-color: $c-wh;
                }
                &:hover,
                &-selected {
                    color: $c-2;
                    > a {
                        color: $c-2;
                    }
                }

                // states
                &-have-noti {
                    > a,
                    > .ant-btn {
                        display: flex;
                        justify-content: flex-start;
                        &::after {
                            content: "";
                            width: 8px;
                            height: 8px;
                            background-color: $c-1;
                            display: block;
                            border-radius: 100%;
                            margin-left: 4px;
                            margin-top: 1px;
                        }
                    }
                }
            }
            .ant-divider{
                width: calc(100% - 15px);
                min-width: auto;
            }
        }
        &-xs {
            width: 150px;
            padding-top: 5px;
        }
        &-default {
            width: 180px;
        }
        &-xd {
            width: 100px;
        }
    }
    &-deptos {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        display: inline-block;
        position: relative;
        padding-right: 43px;

        > [class*="fa-"] {
            font-size: 20px;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e9e9e9;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.2s;
        }

        &:hover {
            > [class*="fa-"] {
                background-color: $c-1;
                color: $c-wh;
            }
        }
    }
}
// dropdowns
.ant-picker-dropdown,
.ant-select-dropdown {
    border-radius: $radius;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    font-family: $f-second;
}
.ant-select-dropdown {
    .ant-select-item {
        &-option {
            padding: 15px 15px;
            &:hover {
                background-color: $c-gr;
            }
            &-selected {
                background-color: $c-gr;
            }
            &-content {
                font-size: 15px;
                color: $c-dr;
                opacity: 0.8;
                font-weight: 400;
                white-space: normal;
                font-family: $f-second;
                line-height: 1.2;
            }
        }
    }
    &:not(.ant-select-dropdown-hidden) {
        &.ant-dropdown {
            &-always {
                &-bottom {
                    top: 71px !important;
                }
            }
        }
    }
}
.ant-picker-dropdown {
    .ant-picker-content {
        > tbody {
            > tr {
                .ant-picker-cell {
                    &-selected {
                        > .ant-picker-cell-inner {
                            background-color: $c-1;
                            border-radius: 5px;
                            &::before {
                                border-radius: 5px;
                                border-color: $c-1;
                            }
                        }
                    }
                    &-today {
                        > .ant-picker-cell-inner {
                            &::before {
                                border-radius: 5px;
                                border-color: $c-1;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-picker {
        &-header {
            display: flex;
            align-items: center;
            &-view {
                > button {
                    line-height: normal;
                    padding: 5px 15px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 15px;
                    transition: all 0.2s;
                    &:hover {
                        background-color: rgba(0, 0, 0, 0.15);
                        color: $c-dr;
                    }
                }
            }
            &-next,
            &-prev,
            &-super-next,
            &-super-prev {
                &-btn {
                    width: 30px;
                    height: 30px;
                    background-color: $c-gr-5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    padding: 0;
                }
            }
        }
        &-body {
            .ant-picker-content {
                tbody {
                    > tr {
                        .ant-picker-cell {
                            &-inner {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;
                            }
                            &:hover {
                                &-inner {
                                    background-color: $c-gr-5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
