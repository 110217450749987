@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.provider__detail {
    z-index: 10;
    position: relative;
    &-gallery {
        overflow: auto;
        @include scroll($c-wh, rgba(0, 0, 0, 0.2));
        position: relative;
        display: flex;
        z-index: -1;
        &-empty {
            height: 100px;
            background-color: $c-3;
            border-radius: 10px;
        }
        > img {
            width: auto;
            margin-right: 10px;
            object-fit: cover;
            object-position: center;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-header {
        display: flex;
        margin-top: -20px;
        > img {
            margin-right: 20px;
            background-color: $c-wh;
            border-radius: 100%;

            object-fit: cover;
            object-position: center;
        }
        > .title__ppal {
            margin-top: 35px;
            line-height: 1;
            margin-bottom: 0 !important;
            display: flex;
            flex-direction: column;
            > span {
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 500;
                opacity: 0.3;
            }
        }
    }
    &-body {
        margin-top: 20px;
        .ant-typography {
            padding-right: 0;
            margin-bottom: 0;
            font-weight: 300;
            font-size: 17px;
            color: $c-dr;
            > p {
                line-height: 1.4;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .price__from {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            margin-bottom: 30px;
            background-color: $c-gr-2;
            font-size: 30px;
            line-height: normal;
            border-radius: 10px;
            > [class*="fa-"] {
                color: $c-2;
                margin-right: 20px;
            }
            &-content {
                display: flex;
                flex-direction: column;
                > span {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-family: $f-ppal;
                    letter-spacing: 0.8px;
                    opacity: 0.5;
                }
                > strong {
                    font-family: $f-second;
                    font-weight: 200;
                    font-size: 25px;
                    > sup {
                        font-size: 12px;
                        top: -10px;
                        opacity: 0.5;
                        font-weight: 500;
                    }
                }
            }
        }
        .list__provider {
            &-services {
                display: grid;
                &-item {
                    display: flex;
                    align-items: center;
                    > [class*="fa-"] {
                        width: 19px;
                        text-align: center;
                        font-size: 16px;
                        color: $c-1;
                        margin-right: 10px;
                    }
                    .text {
                        font-size: 14px;
                        font-weight: 200;
                        text-align: left;
                        line-height: 1.2;
                    }
                }
            }
            &-schedules {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                &-item {
                    display: flex;
                    align-items: center;
                    > [class*="fa-"] {
                        width: 20px;
                        display: flex;
                        justify-content: center;
                        font-size: 20px;
                        margin-right: 15px;
                        opacity: 0.8;
                    }
                    > span {
                        display: flex;
                        flex-direction: column;
                        font-size: 12px;
                        font-family: $f-ppal;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.4);
                        line-height: 1.2;
                        text-transform: uppercase;
                        letter-spacing: 0.8px;
                        > strong {
                            font-size: 16px;
                            text-transform: none;
                            font-weight: 200;
                            line-height: normal;
                            letter-spacing: normal;
                            color: $c-dr;
                            opacity: 0.8;
                        }
                    }
                }
            }
            &-actions {
                width: calc(100% + 40px);
                margin-left: -20px;
                display: flex;
                margin-top: 20px;
                background-color: rgba(0, 0, 0, 0.01);
                flex: 1 0 auto;
                &-price {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 10px 25px;
                    background-color: $c-2;
                    color: $c-wh;
                    > strong {
                        font-family: $f-second;
                        text-transform: uppercase;
                        letter-spacing: 2.5px;
                        font-size: 10px;
                        opacity: 0.8;
                        font-weight: 400;
                    }
                    > span {
                        margin-top: 1px;
                        font-family: $f-ppal;
                        font-size: 25px;
                        font-weight: 200;
                        text-transform: uppercase;
                        line-height: 1;
                        &::first-letter {
                            opacity: 0.5;
                            margin-right: 3px;
                        }
                    }
                }
                &-contact {
                    width: 100%;
                    display: flex;
                    > .ant-btn {
                        margin-right: 0;
                        height: 100%;
                        padding: 0;
                        box-shadow: none;
                        border-radius: 0;
                        flex: 1 1;
                        border-top: 1px solid rgba(0, 0, 0, 0.05);
                        border-right: 1px solid rgba(0, 0, 0, 0.05);
                        > [class*="fa-"] {
                            opacity: 0.6;
                            transition: all 0.2s;
                        }
                        &:last-child {
                            border-right: none;
                        }
                        &:hover {
                            box-shadow: none;
                            color: $c-2;
                            > [class*="fa-"] {
                                opacity: 1;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
        &-services {
            .list__provider-services {
                &-item {
                    > [class*="fa-"] {
                        color: $c-dr;
                        width: 20px;
                        font-size: 18px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
        &-location {
            display: flex;
            flex-direction: column;
            &-btns {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                > .ant-btn {
                    margin: 0;
                }
            }
        }
        &-languages {
            margin-top: 10px;
            margin-bottom: 20px;
            position: relative;
            display: inline-flex;
            padding: 0 15px;
            .ant-tag {
                padding: 3px 13px;
                background-color: transparent;
                border: none;
                border-radius: 20px;
                font-family: $f-ppal;
                font-size: 13px;
                font-weight: 300;
                background-color: $c-wh;
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
            }

            &::before {
                content: "Hablamos";
                display: flex;
                font-weight: 200;
                margin-right: 7px;
                margin-top: 2px;
                font-size: 15px;
                opacity: 0.5;
            }
        }
    }

    @media (min-width: 1301px) {
        &-gallery {
            padding: 20px 40px 0;
            > img {
                height: 200px;
                border-radius: 10px;
            }
        }
        &-header {
            padding: 0 80px;
            > img {
                width: 65px;
                height: 65px;
                box-shadow: 0 0 0 8px $c-wh;
            }
            > .title__ppal {
                > span {
                    font-size: 13px;
                    margin-bottom: 7px;
                }
            }
        }
        &-body {
            > .ant-typography {
                padding: 0 80px;
            }
            .list__provider {
                &-services {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 25px 20px;
                }
                &-actions {
                    &-price {
                        height: 60px;
                    }
                }
            }
            &-services,
            &-languages,
            &-schedules,
            &-location {
                padding: 0 80px;
                margin-top: 40px;
            }
            &-services {
                .list__provider-services {
                    margin-top: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 25px 20px;
                    &-item {
                        > [class*="fa-"] {
                            margin-right: 15px;
                        }
                        > .text {
                            font-size: 16px;
                            opacity: 0.8;
                        }
                    }
                }
            }
            &-schedules {
                .list__provider-schedules {
                    margin-top: 10px;
                }
            }
            &-languages {
                margin-top: 0;
            }
            &-location {
                padding-bottom: 50px;
                &-btns {
                    margin-top: 10px;
                    gap: 20px;
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        &-gallery {
            padding: 20px 40px 0;
            > img {
                height: 200px;
                border-radius: 10px;
            }
        }
        &-header {
            padding: 0 60px;
            > img {
                width: 65px;
                height: 65px;
                box-shadow: 0 0 0 8px $c-wh;
            }
            > .title__ppal {
                > span {
                    font-size: 13px;
                    margin-bottom: 7px;
                }
            }
        }
        &-body {
            > .ant-typography {
                padding: 0 60px;
            }
            .list__provider {
                &-services {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 25px 20px;
                }
                &-actions {
                    &-price {
                        height: 60px;
                    }
                }
            }
            &-services,
            &-languages,
            &-schedules,
            &-location {
                padding: 0 60px;
                margin-top: 40px;
            }
            &-services {
                .list__provider-services {
                    margin-top: 20px;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 25px 20px;
                    &-item {
                        > [class*="fa-"] {
                            margin-right: 15px;
                        }
                        > .text {
                            font-size: 16px;
                            opacity: 0.8;
                        }
                    }
                }
            }
            &-schedules {
                .list__provider-schedules {
                    margin-top: 10px;
                }
            }
            &-languages {
                margin-top: 0;
            }
            &-location {
                padding-bottom: 50px;
                &-btns {
                    margin-top: 10px;
                    gap: 20px;
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &-gallery {
            padding: 10px 10px 0;
            > img {
                height: 150px;
                border-radius: 10px;
                margin-right: 5px;
            }
        }
        &-header {
            padding: 0 50px;
            > img {
                width: 60px;
                height: 60px;
                box-shadow: 0 0 0 5px $c-wh;
            }
            > .title__ppal {
                > span {
                    margin-bottom: 3px;
                    font-size: 12px;
                }
            }
        }
        &-body {
            padding-bottom: 35px;
            > .ant-typography {
                padding: 0 50px;
            }
            .list__provider {
                &-services {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 25px 20px;
                }
                &-actions {
                    &-price {
                        padding: 0 20px;
                        height: 55px;
                        > span {
                            margin-top: -2px;
                            font-size: 20px;
                        }
                    }
                    &-contact {
                        > .ant-btn {
                            flex: 1 1;
                            > [class*="fa-"] {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            &-languages {
                margin-top: 0;
                margin-bottom: 20px;
                width: 100%;
                padding: 0 50px;
            }
            &-services,
            &-schedules,
            &-location {
                padding: 0 50px;
                margin-top: 40px;
            }
            &-services {
                .list__provider-services {
                    margin-top: 15px;
                }

                > .ant-btn {
                    width: 100%;
                }
            }
            &-schedules {
                .list__provider-schedules {
                    margin-top: 15px;
                }
            }
            &-location {
                &-btns {
                    margin-top: 15px;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 15px;
                }
            }
        }
    }
    @media (max-width: 720px) {
        &-gallery {
            padding: 10px 10px 0;
            > img {
                height: 150px;
                border-radius: 10px;
                margin-right: 5px;
            }
        }
        &-header {
            padding: 0 35px 0 35px;
            > img {
                width: 60px;
                height: 60px;
                box-shadow: 0 0 0 5px $c-wh;
            }
            > .title__ppal {
                > span {
                    margin-bottom: 3px;
                    font-size: 12px;
                }
            }
        }
        &-body {
            padding-bottom: 35px;
            > .ant-typography {
                padding: 0 35px;
            }
            .list__provider {
                &-services {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 25px 20px;
                }
                &-actions {
                    &-price {
                        padding: 0 20px;
                        height: 55px;
                        > span {
                            margin-top: -2px;
                            font-size: 20px;
                        }
                    }
                    &-contact {
                        > .ant-btn {
                            flex: 1 1;
                            > [class*="fa-"] {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            &-languages {
                margin-top: 0;
                margin-bottom: 20px;
                width: 100%;
                padding: 0 35px;
            }
            &-services,
            &-schedules,
            &-location {
                padding: 0 35px;
                margin-top: 40px;
            }
            &-services {
                .list__provider-services {
                    margin-top: 15px;
                }

                > .ant-btn {
                    width: 100%;
                }
            }
            &-schedules {
                .list__provider-schedules {
                    margin-top: 15px;
                }
            }
            &-location {
                &-btns {
                    margin-top: 15px;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 15px;
                }
            }
        }
    }
}
