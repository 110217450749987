@import "assets/sass/_variables";

.additional__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0 20px;
    > .progress {
        position: relative;
        color: $c-wh;
        background-color: $c-2;
        width: fit-content;
        padding: 6px 8px;
        border-radius: 5px;
        text-transform: uppercase;
        font-family: $f-second;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        position: relative;
        overflow: hidden;
    }
    > .ant-typography {
        padding: 8px 0;
        font-size: 16px;
    }
    > * {
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}
