.ant-skeleton {
    &-basic,
    &-text,
    &-blog,
    &-mosaic,
    &-trips,
    &-notifi,
    &-only-one,
    &-reviews,
    &-search {
        .ant-skeleton-content {
            .ant-skeleton {
                &-title {
                    margin-top: 0;
                    border-radius: 10px;
                }
                &-paragraph {
                    display: grid;
                    margin-top: 0;
                    > li {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
    &-blog {
        .ant-skeleton-content {
            .ant-skeleton {
                &-paragraph {
                    > li {
                        width: auto !important;
                        height: 100%;
                    }
                }
            }
        }
    }
    &-buttons {
        margin-bottom: 15px;
        .ant-skeleton {
            &-paragraph {
                display: flex !important;
                > li {
                    border-radius: 100%;
                    &:first-child,
                    &:last-child {
                        width: 50px !important;
                        height: 50px;
                        flex: 1 0 auto;
                    }
                    &:nth-child(2) {
                        width: 100%;
                        background: none !important;
                    }
                }
            }
        }
    }
    &-status {
        &-trip {
            .ant-skeleton {
                &-title {
                    margin-bottom: 0 !important;
                }
                &-paragraph {
                    margin: 5px 0 20px !important;
                    gap: 10px !important;
                    > li {
                        height: 35px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
    &-text {
        .ant-skeleton-content {
            .ant-skeleton {
                &-title {
                    margin-bottom: 15px;
                }
                &-paragraph {
                    gap: 10px;
                }
            }
        }
    }
    &-basic {
        .ant-skeleton-content {
            .ant-skeleton {
                &-title {
                    margin-bottom: 15px;
                }
                &-paragraph {
                    > li {
                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &-filters {
            .ant-skeleton {
                &-title {
                    margin-bottom: 15px;
                }
                &-paragraph {
                    margin-bottom: 30px;
                    gap: 10px;
                    display: inline-flex !important;
                    > li {
                        width: 200px;
                        flex: 1 0 auto;
                        height: 45px;
                        border-radius: 25px;
                        &:first-child {
                            width: 86px;
                        }
                    }
                }
            }
        }
    }
    &-cards {
        width: 100%;
        overflow: hidden;
        padding: 0 40px;
        display: block;
        > .ant-skeleton-content {
            width: 100%;
            > .ant-skeleton-paragraph {
                overflow: hidden;
                width: 100%;
                display: flex;
                > li {
                    margin-top: 0;
                    width: 150px;
                    height: 200px;
                    border-radius: 17px;
                    flex: 1 0 auto;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &-trips {
        .ant-skeleton {
            &-content {
                display: flex;
                border-radius: 15px;
                .ant-skeleton {
                    &-title {
                        max-width: 80px;
                        height: 80px;
                        margin-right: 20px;
                    }
                    &-paragraph {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        > li {
                            border-radius: 5px;
                            margin-bottom: 7px;
                            &:first-child {
                                width: 100px;
                                height: 20px;
                            }
                            &:nth-child(2) {
                                width: 70%;
                                height: 24px;
                            }
                            &:last-child {
                                width: 120px !important;
                                height: 20px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &-mosaic {
        .ant-skeleton-content {
            .ant-skeleton {
                &-paragraph {
                    > li {
                        height: 200px;
                        border-radius: 17px;
                        &:last-child {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
    &-search {
        .ant-skeleton {
            &-content {
                width: 100%;
                display: flex;
            }
            &-title {
                flex: 1 0 auto;
                margin-right: 15px;
            }
            &-paragraph {
                width: 100%;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                > li {
                    margin-bottom: 0;
                }
            }
        }
    }
    &-notifi {
        margin-bottom: 10px;
        .ant-skeleton {
            &-content {
                width: 100%;
                display: flex;
                border: 1px solid #f3f3f3;
                padding: 15px;
                border-radius: 10px;
            }
            &-title {
                width: 40px !important;
                height: 40px;
                flex: 1 0 auto;
                border-radius: 5px !important;
                margin-right: 15px;
            }
            &-paragraph {
                width: 100%;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                > li {
                    &:first-child {
                        width: 70%;
                        height: 15px;
                    }
                    &:last-child {
                        width: 40% !important;
                        height: 20px;
                        margin-top: 5px !important;
                    }
                }
            }
        }
    }
    &-reviews {
        margin-bottom: 40px;
        .ant-skeleton {
            &-content {
                width: 100%;
                display: flex;
            }
            &-title {
                width: 35px !important;
                height: 35px;
                flex: 1 0 auto;
                border-radius: 100% !important;
                margin-right: 18px;
            }
            &-paragraph {
                width: 100%;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                > li {
                    &:first-child {
                        width: 40%;
                        height: 20px;
                        margin-bottom: 5px;
                    }
                    &:nth-child(2) {
                        width: 70%;
                        height: 14px;
                        margin-bottom: 5px;
                    }
                    &:last-child {
                        width: 40% !important;
                        height: 20px;
                        margin-top: 5px !important;
                    }
                }
            }
        }
    }
    &-only-one {
        .ant-skeleton-content {
            .ant-skeleton {
                &-title {
                    width: 70%;
                    margin-top: 15px;
                }
            }
        }
        &.basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-left: 10px;
                        width: 150px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    @media (min-width: 1301px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 30px;
                    }
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(6, 1fr);
                    }
                }
            }
            &-filters {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-title {
                            margin-bottom: 20px;
                        }
                        &-paragraph {
                            gap: 10px;
                        }
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(6, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 30px;
                            grid-template-columns: repeat(5, 1fr);
                        }
                    }
                }
            }
        }
        &-trips {
            .ant-skeleton-content {
                padding: 10px;
                border: 1px solid #f3f3f3;
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 30px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }
        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 60px !important;
                    height: 60px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 200px;
                            height: 24px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 80px !important;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1051px) and (max-width: 1300px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(5, 1fr);
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(5, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 30px;
                            grid-template-columns: repeat(4, 1fr);
                        }
                    }
                }
            }
        }
        &-trips {
            .ant-skeleton-content {
                padding: 10px;
                border: 1px solid #f3f3f3;
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }
        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 60px !important;
                    height: 60px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 200px;
                            height: 24px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 80px !important;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1050px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: repeat(5, 1fr);
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: repeat(5, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 20px;
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                }
            }
        }
        &-trips {
            .ant-skeleton-content {
                padding: 0;
                border: none;
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }
        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 60px !important;
                    height: 60px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 200px;
                            height: 24px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 80px !important;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 741px) and (max-width: 860px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(4, 1fr);
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 30px;
                        grid-template-columns: repeat(4, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 30px;
                            grid-template-columns: repeat(4, 1fr);
                        }
                    }
                }
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }
        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 50px !important;
                    height: 50px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 50%;
                            height: 20px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 20% !important;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 521px) and (max-width: 740px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 20px;
                    }
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: repeat(3, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 20px;
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                }
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 10px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }
        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 50px !important;
                    height: 50px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 50%;
                            height: 20px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 20% !important;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 520px) {
        &-basic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        padding-top: 3px;
                        gap: 20px;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
        &-trips {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-right: 15px;
                        height: 70px;
                        width: 70px !important;
                        flex: 1 0 auto;
                    }
                    &-paragraph {
                        > li {
                            &:nth-child(2) {
                                height: 20px;
                            }
                            &:last-child {
                                height: 17px;
                            }
                        }
                    }
                }
            }
        }
        &-mosaic {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
            &-profile {
                .ant-skeleton-content {
                    .ant-skeleton {
                        &-paragraph {
                            gap: 20px;
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                }
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        margin-bottom: 10px;
                    }
                    &-paragraph {
                        > li {
                            border-radius: 17px;
                        }
                    }
                }
            }
        }

        &-search {
            margin-bottom: 15px;
            .ant-skeleton {
                &-title {
                    width: 50px !important;
                    height: 50px;
                }
                &-paragraph {
                    > li {
                        &:first-child {
                            width: 70%;
                            height: 20px;
                            margin-bottom: 5px;
                        }
                        &:nth-child(2) {
                            width: 40% !important;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 861px) {
        &-basic,
        &-text,
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        width: 350px !important;
                        height: 48px;
                    }
                }
            }
        }
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        gap: 20px;
                        grid-template-columns: 2fr 1fr 1fr;
                        grid-template-rows: minmax(230px, 230px);
                    }
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &-basic,
        &-text,
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        width: 350px !important;
                        height: 48px;
                    }
                }
            }
        }
        &-blog {
            display: flex;
            width: calc(100% + 160px);
            margin-left: -80px;
            padding-left: 80px;
            > .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        padding-top: 10px;
                        overflow: hidden;
                        gap: 20px;
                        grid-template-columns: 400px 230px 230px 230px 1px;
                        grid-template-rows: minmax(230px, 230px);
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        &-basic,
        &-text,
        &-blog {
            .ant-skeleton-content {
                .ant-skeleton {
                    &-title {
                        width: 70% !important;
                        height: 37px;
                    }
                }
            }
        }
        &-blog {
            display: flex;
            width: calc(100% + 70px);
            margin-left: -35px;
            padding-left: 35px;
            > .ant-skeleton-content {
                .ant-skeleton {
                    &-paragraph {
                        padding-top: 10px;
                        overflow: hidden;
                        gap: 20px;
                        grid-template-columns: 80% 80% 80% 80% 1px;
                        grid-template-rows: minmax(230px, 230px);
                    }
                }
            }
        }
    }
}
