@import "assets/sass/_variables";

.account__dashboard {
    display: flex;
    &-data {
        width: 100%;
        .title__ppal {
            margin-bottom: 25px;
        }
        .ant-form-item {
            &-label {
                font-weight: 200;
            }
            &-control {
                &-input {
                    min-height: auto;
                    &-content {
                        min-height: auto;
                    }
                }
            }
            &-photo{
                @media (min-width: 861px){
                    display: none;
                }
                @media (max-width: 860px){
                    display: flex;
                }
            }
        }
        &-block{
            padding: 20px 30px;
            background-color: $c-danger-bg;
            border-radius: 15px;
            margin-top: 50px;
            border: 1px solid $c-danger;
            .title__ppal{
                font-size: 25px !important;
                color: $c-danger !important;
                > [class*="fa-"]{
                    margin-right: 15px;
                }
            }
            .ant-btn{
                box-shadow: none;
                margin: 0 auto;
            }
        }
    }
    &-faqs {
        height: fit-content;
        border-radius: 15px;
        box-shadow: 8px 8px 0 0 $c-3, 12px 12px 25px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;

        &-item{
            display: flex;
            flex-direction: column;
            > [class*="fa-"]{
                color: $c-2;
                font-size: 30px;
                margin-bottom: 10px;
            }
            > .subtitle__ppal{
                margin-bottom: 10px;
            }
        }
    }

    @media (min-width: 1201px) {
        &-faqs {
            width: 370px;
            margin-left: 100px;
            flex: 1 0 auto;
            padding: 30px 40px;
        }
    }
    @media (min-width: 1101px) and (max-width: 1200px) {
        &-faqs {
            width: 350px;
            margin-left: 70px;
            flex: 1 0 auto;
            padding: 20px 30px;
        }
    }
    @media (min-width: 861px) and (max-width: 1100px) {
        flex-direction: column;
        &-faqs {
            width: 100%;
            padding: 30px 40px;
            margin-top: 50px;
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        flex-direction: column;
        &-faqs {
            width: 100%;
            padding: 30px 40px;
            margin-top: 50px;
        }
    }
    @media (max-width: 720px) {
        flex-direction: column;
        &-faqs {
            width: 100%;
            padding: 25px 35px;
            margin-top: 35px;
        }
    }
}
