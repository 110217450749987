@import "assets/sass/_variables";

.empty__data {
    display: flex;
    @media (min-width: 861px) {
        align-items: center;
        padding: 40px;
        border-radius: 15px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);

        .text__enr-2 {
            width: 100%;
            margin-right: 40px;
        }
        > svg {
            width: 300px;
            flex: 1 0 auto;
        }
        > img{
            width: 200px;
            flex: 1 0 auto;
        }
    }
    @media (max-width: 860px) {
        flex-direction: column-reverse;
        > svg {
            width: 200px;
            margin-bottom: 20px;
        }
        > img{
            width: 150px;
            margin-bottom: 20px;
        }
    }
    &-vertical {
        flex-direction: column-reverse;
        box-shadow: none;
        padding: 0;
        border-radius: 0;
        > svg{
            margin-bottom: 20px;
        }
        > .text__enr-2{
            margin-right: 0;
        }
    }
}
