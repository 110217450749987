@import "assets/sass/_variables";

.place__ {
    &dates {
        display: inline-flex;
        &-item {
            display: flex;
            color: $c-wh;
            position: relative;
            transition: all 0.2s;

            &-disabled{
                opacity: .7;
                pointer-events: none;
                cursor: not-allowed;
            }

            > .text {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 8px 20px;
                font-size: 12px;
                position: relative;
                top: 0;
                color: $c-wh;
                left: 0;
                font-weight: 200;
                transition: all 0.2s;
                > strong {
                    text-transform: uppercase;
                    font-size: 17px;
                    margin-top: 2px;
                    font-weight: 600;
                }
            }
            &.depto {
                background-color: $c-2;
                > .text {
                    > .deptos {
                        display: flex;
                        font-size: 17px;
                        font-weight: 600;
                        flex-wrap: wrap;
                        margin-left: -5px;
                        > .depto-item {
                            color: $c-wh;
                            text-transform: uppercase;
                            margin-top: 2px;
                            margin-left: 5px;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            &.municipality {
                background-color: $c-3;
            }
            &:first-child {
                border-radius: 5px 0 0 5px;
                &:last-child {
                    border-radius: 5px;
                }
            }
            &:last-child {
                border-radius: 0 5px 5px 0;
            }
            &:hover {
                background-color: #314854;
            }
        }
    }
    &parag {
        font-family: $f-second;
        > p {
            margin-bottom: 15px;
            > strong,
            > b {
                font-weight: 600;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &info {
        display: flex;
        flex-wrap: wrap;
        border-radius: 0;
        > li {
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            padding: 10px 15px;
            border-radius: 10px;

            > span {
                width: 100%;
                font-size: 15px;
                font-family: $f-second;
                font-weight: 200;
                display: block;
                > strong {
                    font-weight: 700;
                    margin-bottom: 14px;
                }
            }

            > [class*="fa"] {
                width: 20px;
                height: 30px;
                font-size: 16px;
                margin-top: 0px;
                margin-right: 10px;
                padding-top: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $c-1;
                flex: 1 0 auto;
            }

            a {
                color: $c-1;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }
            }

            .ant-skeleton {
                margin-top: -8px;
            }

            &.icon {
                display: flex;
                align-items: center;
                > span {
                    > strong {
                        display: block;
                        width: 100%;
                        font-size: 11px;
                        margin-bottom: 1px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                }
            }
            &.rate {
                cursor: pointer;
                transition: all 0.2s;
                > span {
                    display: flex;
                    align-items: center;
                    > strong {
                        font-size: 20px;
                        font-weight: 400;
                        width: auto;
                        margin-right: 5px;
                        position: relative;
                        &::after {
                            content: "/";
                            font-weight: 400;
                            opacity: 0.2;
                            display: inline-flex;
                            margin-left: 5px;
                        }
                    }
                }
                &:hover {
                    border-color: $c-1;
                }
            }
            &.state {
                background-color: transparent;
                align-items: center;
                min-height: 50px;
                &::before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    margin-right: 12.5px;
                    margin-left: 2.5px;
                    border-radius: 100%;
                    flex: 1 0 auto;
                }
                &.open {
                    &::before {
                        background-color: $c-success;
                    }
                }
                &.reservation {
                    &::before {
                        background-color: $c-warning;
                    }
                }
                &.closed {
                    &::before {
                        background-color: $c-danger;
                    }
                }
            }
            &.full {
                width: 100%;
            }
            &.no-border {
                border-color: transparent;
            }
            &.near-cities {
                background-color: $c-gr-2;
            }
        }
    }
    @media (min-width: 1101px) {
        &info {
            margin-top: -10px;
            > li {
                width: calc(50% - 5px);
                margin-right: 10px;
                margin-top: 10px;
                &:nth-child(2n+0):not(.full) {
                    margin-right: 0;
                }
                &.full {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1100px) {
        &info {
            margin-top: -10px;
            > li {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &info {
            margin-top: -10px;
            > li {
                width: calc(50% - 5px);
                margin-right: 10px;
                margin-top: 10px;
                &:nth-child(2n+0):not(.full) {
                    margin-right: 0;
                }
                &.full {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        &dates {
            &-item {
                min-height: 50px;
            }
        }
    }
    @media (max-width: 720px) {
        &info {
            margin-top: -10px;
            > li {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}
