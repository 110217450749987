@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.tabs {
    &__default {
        > .ant-tabs {
            &-nav {
                margin-bottom: 0 !important;
                &::before {
                    display: none;
                }
                > .ant-tabs-nav-wrap {
                    > .ant-tabs-nav-list {
                        flex: 1 0 auto;
                        > .ant-tabs {
                            &-tab {
                                margin-right: 0;
                                padding: 0;
                                > .ant-tabs-tab-btn {
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    padding: 13px 25px;
                                    font-weight: 400;
                                    font-size: 15px;
                                    font-family: $f-second;
                                    color: $c-dr;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    opacity: 0.7;
                                    transition: all 0.2s;

                                    > [class*="fa"] {
                                        width: 22px;
                                        display: flex;
                                        justify-content: center;
                                        font-size: 17px;
                                        margin-top: 2px;
                                    }
                                }
                                &-active {
                                    > .ant-tabs-tab-btn {
                                        border-bottom: none;
                                        color: $c-dr;
                                        opacity: 1;
                                        > span {
                                            display: flex;
                                        }
                                    }
                                }
                                &:first-child {
                                    border-left: none;
                                }
                            }
                            &-ink-bar {
                                height: 50px;
                                background-color: $c-gr-2;
                                border-radius: 10px 10px 0 0;
                                z-index: -1;
                            }
                        }
                    }
                    &::after {
                        width: 50px;
                        box-shadow: none;
                        background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0));
                    }
                }
                > .ant-tabs-nav-operations {
                    > .ant-tabs-nav-more {
                        width: 49px;
                        height: 49px;
                        background-color: $c-gr-2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        > .anticon {
                            display: none;
                        }
                        &::before {
                            @include fontAwesome("\f067");
                            font-weight: 200;
                            font-size: 20px;
                        }
                    }
                }
            }
            &-content-holder {
                overflow: hidden;
                > .ant-tabs-content {
                    > .ant-tabs-tabpane {
                        background-color: $c-gr-2;
                    }
                }
            }
        }
        // modificators
        &-little {
            .ant-tabs {
                &-tab {
                    &-btn {
                        > span {
                            display: block !important;
                        }
                    }
                }
            }
        }

        @media (min-width: 1301px) {
            > .ant-tabs {
                &-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            padding: 0 80px;
                            .ant-tabs {
                                &-tab {
                                    &-btn {
                                        > span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                            &::after {
                                width: 1px;
                                content: "";
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                }
                &-content {
                    &-holder {
                        .ant-tabs {
                            &-tabpane {
                                &-container {
                                    padding: 40px 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: 1101px) and (max-width: 1300px) {
            > .ant-tabs {
                &-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            padding: 0 60px;
                            .ant-tabs {
                                &-tab {
                                    &-btn {
                                        > span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-content {
                    &-holder {
                        .ant-tabs {
                            &-tabpane {
                                &-container {
                                    padding: 30px 60px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: 861px) and (max-width: 1100px) {
            > .ant-tabs {
                &-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            padding: 0 60px;
                            .ant-tabs {
                                &-tab {
                                    &-btn {
                                        > span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-content {
                    &-holder {
                        .ant-tabs {
                            &-tabpane {
                                &-container {
                                    padding: 30px 60px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: 721px) and (max-width: 860px) {
            > .ant-tabs {
                &-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            padding: 0 50px;
                            .ant-tabs {
                                &-tab {
                                    &-btn {
                                        > span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-content {
                    &-holder {
                        .ant-tabs {
                            &-tabpane {
                                &-container {
                                    padding: 40px 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 720px) {
            > .ant-tabs {
                &-nav {
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            padding: 0 35px;
                            display: flex;
                            .ant-tabs {
                                &-tab {
                                    &-btn {
                                        > span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-content {
                    &-holder {
                        .ant-tabs {
                            &-tabpane {
                                &-container {
                                    padding: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-tabs {
    &-dropdown {
        &-menu {
            border-radius: 8px;
            &-item {
                padding: 10px 15px;
                > [class*="fa-"] {
                    margin-right: 8px;
                }
            }
        }
    }
}
