@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.list__myTrips {
    display: grid;
    &-item {
        display: flex;
        align-items: center;
        border-radius: 15px;
        transition: all 0.2s;
        position: relative;
        border: 1px solid transparent;
        cursor: pointer;
        &-images {
            width: 70px;
            height: 70px;
            border-radius: 10px;
            overflow: hidden;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $c-gr-2;
            font-size: 25px;
            > [class*="fa-"] {
                color: $c-dr;
                opacity: 0.5;
            }
        }
        &-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $c-dr;
            &-goal {
                position: relative;
                margin-bottom: 1px;
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                .goal {
                    &__state {
                        width: fit-content;
                        display: block;
                        color: rgba(0, 0, 0, 0.8);
                        background-color: $c-gr-2;
                        padding: 3px 8px;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font-family: $f-second;
                        font-size: 11px;
                        font-weight: 500;
                        letter-spacing: 1px;
                    }
                    &__invited {
                        display: flex;
                        align-items: center;
                        &-item {
                            opacity: 0.5;
                            font-family: $f-ppal;
                            font-weight: 300;
                            font-size: 14px;
                            margin-right: 10px;
                            > [class*="fa-"] {
                                font-size: 12px;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            &-name {
                font-family: $f-ppal;
            }
            &-quantity {
                display: flex;
                align-items: center;
                width: 100%;
                font-family: $f-second;
                opacity: 0.7;
            }
        }

        // states
        &--selected {
            background-color: $c-gr-2;
            border-color: transparent;
            .list__myTrips-item-images,
            .list__myTrips-item-content-goal {
                > .goal {
                    &-state {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }
            }

            &::before {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: $c-1;
                color: $c-wh;
                position: absolute;
                right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                @include fontAwesome("\f00c");
            }

            &:hover {
                border-color: transparent !important;
            }
        }

        &--disabled {
          pointer-events: none;
          opacity: 0.6;
        }

        // trips
        &--trip {
            &-1 {
                .list__myTrips {
                    &-item {
                        &-images {
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
            &-2 {
                .list__myTrips {
                    &-item {
                        &-images {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 2px;
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
            &-3 {
                .list__myTrips {
                    &-item {
                        &-images {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-areas:
                                "image-trip-1 image-trip-2"
                                "image-trip-1 image-trip-3";
                            gap: 2px;
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                &:first-child {
                                    grid-area: image-trip-1;
                                }
                                &:nth-child(2) {
                                    grid-area: image-trip-2;
                                }
                                &:nth-child(3) {
                                    grid-area: image-trip-3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1051px) {
        &-two {
            grid-template-columns: repeat(2, 1fr);
        }
        &-one {
            grid-template-columns: repeat(1, 1fr);
        }
        gap: 15px;
        &-item {
            padding: 10px;
            border: 1px solid $c-gr-2;
            &-images {
                margin-right: 15px;
            }
            &-content {
                &-name {
                    margin-bottom: 5px;
                    font-weight: 400;
                    @include truncate(22px, 1.2, 1);
                }
                &-quantity {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1;
                }
            }

            &--trip {
                &-3 {
                    .list__myTrips {
                        &-item {
                            &-images {
                                grid-template-rows: 34px 34px;
                            }
                        }
                    }
                }
            }
            &--selected {
                &::before {
                    bottom: 10px;
                }
            }
            &:hover {
                border-color: $c-dr;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1050px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        margin-bottom: 120px;
        &-item {
            &-images {
                width: 70px;
                height: 70px;
                margin-right: 20px;
            }
            &-content {
                &-name {
                    margin-bottom: 4px;
                    font-weight: 400;
                    @include truncate(21px, 1, 1);
                }
                &-quantity {
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            &--trip {
                &-3 {
                    .list__myTrips {
                        &-item {
                            &-images {
                                grid-template-rows: 34px 34px;
                            }
                        }
                    }
                }
            }
            &--selected {
                box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                &::before {
                    right: 0;
                    bottom: 10px;
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 35px;
        &-item {
            &-images {
                width: 70px;
                height: 70px;
                margin-right: 20px;
            }
            &-content {
                &-name {
                    margin-bottom: 6px;
                    font-weight: 400;
                    @include truncate(20px, 1, 1);
                }
                &-quantity {
                    font-size: 14px;
                    font-weight: 300;
                }
            }
            &--trip {
                &-3 {
                    .list__myTrips {
                        &-item {
                            &-images {
                                grid-template-rows: 34px 34px;
                            }
                        }
                    }
                }
            }
            &--selected {
                box-shadow: 0 0 0 10px $c-gr-2;
                border-radius: 5px;
                &::before {
                    right: 0;
                    bottom: 20px;
                }
            }
        }
    }
    @media (max-width: 720px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        &-item {
            align-items: flex-start;
            &-images {
                width: 60px;
                height: 60px;
                margin-right: 15px;
            }
            &-content {
                &-goal {
                    margin-bottom: 3px;
                    padding-top: 4px;
                    line-height: 1;
                }
                &-name {
                    margin-bottom: 4px;
                    font-weight: 400;
                    @include truncate(19px, 1.15, 1);
                }
                &-quantity {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 1;
                }
            }
            &--trip {
                &-3 {
                    .list__myTrips {
                        &-item {
                            &-images {
                                grid-template-rows: 30px 28px;
                            }
                        }
                    }
                }
            }
            &--selected {
                box-shadow: 0 0 0 10px $c-gr-2;
                border-radius: 5px;
                &::before {
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}
