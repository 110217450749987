@import "assets/sass/variables";
@import "assets/sass/mixins";

.generic {
    display: flex;
    align-items: center;
    &__image {
        background-color: #fff;
        flex: 1 0 auto;
        overflow: hidden;

        > img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    @media (min-width: 1301px) {
        &__image {
            width: 300px;
            height: 300px;
            border-radius: 20px;
        }
        &.right {
            flex-direction: row-reverse;
            > .generic {
                &__image {
                    margin-left: 70px;
                    box-shadow: 12px 12px 0 0 $c-3, 25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
        &.left {
            > .generic {
                &__image {
                    margin-right: 70px;
                    box-shadow: -12px 12px 0 0 $c-3, -25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        $spacing: 60px;
        padding: 0 $spacing;
        &__image {
            width: 200px;
            height: 200px;
            border-radius: 20px;
        }

        &.right {
            flex-direction: row-reverse;
            > .generic {
                &__image {
                    margin-left: 50px;
                    box-shadow: 12px 12px 0 0 $c-3, 25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
        &.left {
            > .generic {
                &__image {
                    margin-right: 50px;
                    box-shadow: -12px 12px 0 0 $c-3, -25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 80px;
        padding: 0 $spacing;

        &__image {
            width: 200px;
            height: 200px;
            border-radius: 20px;
        }

        &.right {
            flex-direction: row-reverse;
            > .generic {
                &__image {
                    margin-left: 70px;
                    box-shadow: 12px 12px 0 0 $c-3, 25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
        &.left {
            > .generic {
                &__image {
                    margin-right: 70px;
                    box-shadow: -12px 12px 0 0 $c-3, -25px 25px 50px 0 rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    @media (max-width: 720px) {
        $spacing: 35px;

        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0 $spacing;
        &__image {
            width: 45%;
            height: 150px;
            border-radius: 10px;
            box-shadow: -3px 3px 0 3px $c-1;
            margin-top: 20px;
        }
        &.right {
            > .generic {
                &__image {
                    margin-top: 40px;
                    box-shadow: none;
                }
            }
        }
        &.left {
            flex-direction: column;
            align-items: flex-end;
            > .generic {
                &__image {
                    margin-top: -130px;
                    margin-bottom: 44px;
                    box-shadow: none;
                }
            }
        }
    }
}
