@import "assets/sass/_variables";

.comments__list {
    display: flex;
    flex-direction: column;
    &-item {
        display: flex;
        margin-bottom: 30px;
        .comment {
            &__photo {
                width: 33px;
                height: 33px;
                margin-right: 16px;
                flex: 1 0 auto;
                > .first-letter,
                > img {
                    width: 100%;
                    height: 100%;
                    margin-top: 7px;
                    background-color: $c-gr-2;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                > .first-letter{
                    color: #949191;
                }
                > img {
                    object-fit: cover;
                    object-position: center;
                }
            }
            &__content {
                width: 100%;
                &-fullname {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    > span {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.1;
                        font-family: $f-ppal;
                        word-break: break-word;
                    }
                    > .ant-btn {
                        flex: 1 0 auto;
                        width: 30px;
                        height: 30px;
                        margin-left: 20px;
                        border: none;
                        opacity: 0;
                        pointer-events: none;
                        > [class*="fa-"] {
                            font-size: 25px;
                        }

                        &.ant-dropdown-open {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                &-date {
                    font-size: 13px;
                    font-weight: 200;
                    margin-top: -6px;
                    margin-bottom: 2px;
                    opacity: 0.5;
                }
                &-message {
                    font-size: 15px;
                    font-family: $f-second;
                    font-weight: 400;
                    color: #606060;
                }
            }
        }

        &--me {
            .comment {
                &__photo {
                    > .first-letter,
                    > img {
                        box-shadow: 0 0 0 3px $c-wh, 0 0 0 4px $c-1;
                    }
                }
            }
        }

        &:hover {
            .comment {
                &__content {
                    &-fullname {
                        > .ant-btn {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: 1301px) {
        &-item {
            .comment {
                &__content {
                    &-fullname {
                        > .ant-btn {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
}
