@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.list__items-providers {
    &-item {
        width: 260px;
        height: 104px;
        background-color: $c-1;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 20px;
        .provider {
            &--content {
                height: 100%;
                background-color: $c-wh;
                border: 1px solid rgba(0, 0, 0, 0.08);
                padding: 13px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                top: 0;
                left: 0;
                transition: all 0.2s;
                pointer-events: none;
                &-header {
                    width: 70px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    flex: 1 0 auto;
                    margin-right: 15px;
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 7px;
                    }
                }
                &-body {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    > .title__ppal {
                        font-size: 19px;
                        line-height: 1;
                    }
                    .ant-typography {
                        padding-right: 0;
                        margin-top: 7px;
                        font-weight: 400;
                        margin-bottom: 0;
                        margin-top: 5px;
                        @include truncate(14px, 1.2, 3);
                    }
                }
            }
            &--priceFrom {
                width: calc(100% + 30px);
                font-size: 14px;
                color: $c-dr;
                margin-left: -15px;
                border-radius: 0 0 10px 10px;
                padding: 15px;
                background-color: $c-gr-2;
                font-weight: 200;
                text-transform: uppercase;
                font-size: 16px;
                > strong {
                    letter-spacing: 1px;
                    display: inline-flex;
                    align-items: center;
                    font-weight: 400;
                    > [class*="fa-"] {
                        margin-right: 4px;
                    }
                }
            }
        }
        &:hover {
            .provider {
                &--content {
                    top: -5px;
                    left: -5px;
                    border-color: $c-1;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
