@import "assets/sass/_variables";

.banner__home {
    background-color: #f9f9f9;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25);
    }

    &-video {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        video {
            object-fit: cover;
        }
    }

    &-caption {
        z-index: 2;
        display: flex;
        flex-direction: column;
        > .title__ppal {
            font-weight: 700;
            text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
            width: 100%;
        }

        > .ant-btn {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            flex: 1 0 auto;
            border: none;
            padding-top: 3px;
            background-color: rgba(255, 255, 255, 0.5);
            > [class="fas fa-play"] {
                padding-left: 4px;
                margin-top: 0;
            }
            > [class*="fa-"]{
                margin-top: 0;
                font-size: 15px;
            }
            &:hover {
                background-color: $c-1;
                color: $c-wh !important;
            }
            &:focus,
            &:active {
                box-shadow: none;
                color: inherit;
            }
        }
    }

    @media (min-width: 1301px) {
        $pdn-rest: 15px;

        width: 100%;
        height: auto;
        padding: ($s-header + 40px) (60px + $pdn-rest) 40px 60px;
        justify-content: flex-end;
        background-position: center bottom;
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        $pdn-rest: 15px;

        width: 100%;
        height: auto;
        padding: ($s-header + 40px) (60px + $pdn-rest) 40px 60px;
        justify-content: flex-end;
        background-position: center bottom;

        &-caption {
            > .title__ppal {
                font-size: 30px !important;
            }
        }
    }
    @media (max-width: 860px) {
        display: none;
    }
}
