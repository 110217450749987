@import "assets/sass/variables";
@import "assets/sass/mixins";

.banner__page {
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    border-radius: 0 0 25px 25px;

    > .title__ppal {
        z-index: 2;
        > p {
            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
            font-weight: 300;
            margin-bottom: 20px;
            > strong,
            > b {
                font-weight: 600;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.light {
        > .title__ppal {
            color: $c-dr;
        }
    }
    &.dark {
        > .title__ppal {
            color: $c-wh;
        }
    }

    @media (min-width: 1301px) {
        width: calc(100% - 60px);
        height: 450px;
        padding: $s-header 10% 50px;
        > .title__ppal {
            > p {
                font-size: 35px;
            }
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        width: calc(100% - 60px);
        height: 450px;
        padding: $s-header 15% 50px;
        > .title__ppal {
            > p {
                font-size: 30px;
            }
        }
    }
    
    @media (min-width: 721px) and (max-width: 860px) {
        width: calc(100% - 20px);
        height: 400px;
        padding: $s-header 50px 50px;
        > .title__ppal {
            > p {
                font-size: 30px;
            }
        }
    }

    @media (max-width: 720px) {
        width: 100%;
        height: 300px;
        padding: $s-header-mob 35px 35px;
        > .title__ppal {
            > p {
                &:first-child {
                    font-size: 20px;
                }
            }
        }
    }
}
