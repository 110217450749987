@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;500;600;700&family=Ubuntu:wght@300;400;500;700&display=swap&family=Amatic+SC:wght@400;700&display=swap');


// fonts
$f-ppal: 'Ubuntu';
$f-second: 'Oxygen';
$f-third: 'Amatic SC';
$tx-parag: 18px;
// generics colors
$c-wh: #fff;
$c-dr: #333;
$c-gr: #F2EDE4;
$c-gr-2: #f7f7f7;
$c-gr-3: #dae5e1;
$c-gr-4: rgb(250, 250, 250);
$c-gr-5: #eee;
$c-gr-g: #f3fffb;
// ---- colors - app
$c-1: #f76c6c;
$c-2: #466475;
$c-3: #799db1;
// ---- colors - socialnetworks
$c-google: #4285f4;
$c-waze: #42d4ff;
$c-whatsapp: #25D366;
$c-whatsapp-2: #128C7E;
// alerts
$c-success: #549076;
$c-success-bg: #d1e7dd;
$c-danger: #d23040;
$c-danger-bg: #ffebed;
$c-warning: #FFE430;
// sizes    
$s-header: 80px;
$s-header-mob: 65px;
$s-controls: 50px;

$radius: 10px;