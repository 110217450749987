@import "assets/sass/_variables";

.sidebar__profile {
    display: flex;
    flex-direction: column;
    &-container {
        overflow: auto;
        height: 100%;
        padding: 0 10px 15px;
        .user {
            &__profile {
                display: flex;
                &-image {
                    background-color: $c-gr-2;
                    border-radius: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 50px;
                    font-family: $f-ppal;
                    font-weight: 200;
                    color: rgba(0, 0, 0, 0.6);
                    > .ant-btn{
                        position: absolute;
                        width: 32px;
                        height: 32px;
                        right: -5px;
                        bottom: -5px;
                        box-shadow: 0 0 0 3px $c-wh;
                        > [class*="fa-"]{
                            font-size: 14px;
                        }
                    }

                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 100%;
                    }
                }
                &-fullname {
                    display: flex;
                    flex-direction: column;
                    font-family: $f-ppal;
                    > strong {
                        font-weight: 600;
                    }
                    > span {
                        display: block;
                        font-family: $f-second;
                        margin-bottom: 2px;
                    }
                }
            }
            &__navigation {
                display: flex;
                flex-direction: column;
                &-item {
                    padding: 15px 17px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    font-family: $f-second;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    margin-bottom: 2px;
                    color: $c-dr;
                    cursor: pointer;
                    transition: all 0.2s;
                    position: relative;

                    > [class*="fa-"] {
                        width: 20px;
                        text-align: center;
                        font-size: 17px;
                        margin-right: 12px;
                        margin-bottom: -2px;
                    }
                    &.active {
                        background-color: #e9e9e9;
                        font-weight: 600;
                        > [class*="fa-"] {
                            font-weight: 600;
                            color: $c-1;
                        }
                        &::after{
                            background-color: $c-1;
                            color: $c-wh;
                        }
                    }
                    &-comingsoon {
                        position: relative;

                        &::before {
                            content: "próximamente";
                            position: absolute;
                            background-color: $c-1;
                            color: $c-wh;
                            padding: 4px 6px;
                            font-size: 13px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 17px;
                            border-radius: 4px;
                            opacity: 0;
                            transition: all 0.2s;
                            z-index: 2;
                        }

                        &:hover {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }

                    &[aria-disabled="true"] {
                        > [class*="fa-"],
                        > span {
                            opacity: 0.7;
                        }
                    }

                    &--pro{
                        &::after{
                            position: absolute;
                            right: 17px;
                            top: 50%;
                            transform: translateY(-50%);
                            content: 'pro';
                            text-transform: uppercase;
                            background-color: $c-gr;
                            color: $c-1;
                            font-size: 10px;
                            padding: 8px;
                            border-radius: 4px;
                        }
                    }

                    &:hover {
                        background-color: #e9e9e9;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &-footer{
        padding: 15px 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        font-family: $f-second;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span{
            font-weight: 400;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            opacity: .6;
        }
        &-social{
            display: flex;
            > a{
                padding: 5px 6px;
                opacity: .4;
                color: $c-dr;
                &:hover{
                    color: $c-1;
                    opacity: 1;
                }
            }
        }
    }
    .user {
        &__profile {
            flex-direction: column;
            margin-bottom: 30px;
            &-image {
                width: 100px;
                height: 100px;
                margin: 0 auto 20px;
            }
            &-fullname {
                text-align: center;
                padding: 0 35px;
                > strong {
                    font-size: 27px;
                }
                > span {
                    font-size: 15px;
                }
            }
        }
    }
    @media (min-width: 861px) {
        width: 250px;
        height: 100%;
        overflow: hidden;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        flex: 1 0 auto;
        padding: 80px 0 0;
    }
    @media (max-width: 860px) {
        display: none;
    }
}
