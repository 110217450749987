@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.list__items-dictionary {
    &-container {
        overflow: auto;
        scroll-behavior: smooth;
        > .item {
            margin-right: 20px;
            border-top: 5px solid $c-1;
            background-color: $c-wh;
            display: flex;
            padding: 10px 0;
            flex: 1 0 auto;
            flex-direction: column;
            border-radius: 5px;

            > .word {
                margin-top: -10px;
                padding: 5px 15px;
                display: flex;
                align-items: center;
                background-color: $c-gr;
                border-radius: 0 0 5px 5px;

                > [class*="fa"] {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 23px;
                    border-radius: 5px;
                    margin-top: 3px;
                    margin-right: 10px;
                    color: $c-1;
                }
                > span {
                    width: 100%;
                    font-size: 18px;
                    font-family: $f-ppal;
                    font-weight: 500;
                    line-height: 1;
                }
            }
            > .description {
                font-size: 14px;
                font-family: $f-second;
                line-height: 1.3;
                background-color: $c-wh;
                font-weight: 200;
                padding: 10px 15px;
                > i {
                    font-style: italic;
                    font-weight: 900;
                    margin-right: 4px;
                }
            }
            .examples {
                display: flex;
                flex-direction: column;
                padding: 10px 15px;
                background-color: $c-wh;
                > .subtitle {
                    font-size: 13px;
                    font-weight: 600;
                    font-family: $f-ppal;
                    margin-bottom: 7px;
                }
                &__list {
                    > ul {
                        display: flex;
                        flex-direction: column;
                        > li {
                            font-size: 14px;
                            margin-bottom: 10px;
                            position: relative;
                            padding-left: 13px;
                            display: flex;
                            flex-direction: column;
                            > em {
                                font-style: italic;
                                opacity: 0.7;
                                margin-top: 2px;
                                font-size: 12px;
                            }
                            &::before {
                                width: 7px;
                                height: 7px;
                                border-radius: 7px;
                                background-color: $c-1;
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 4px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @media (min-width: 1301px) {
        &-container {
            padding: 40px 80px;
            margin-top: -5px;
            > .item {
                width: 250px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        &-container {
            padding: 30px 40px;
            margin-top: -5px;
            > .item {
                width: 250px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &-container {
            padding: 40px 50px;
            margin-top: -5px;
            > .item {
                width: 250px;
            }
        }
    }
    @media (max-width: 720px) {
        &-container {
            padding: 35px;
            margin-top: -5px;
            > .item {
                width: 250px;
            }
        }
    }
}
