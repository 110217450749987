@import "assets/sass/_variables";

.destination__item {
    $b-radius: 15px;
    background-color: $c-1;
    border-radius: $b-radius + 2px;
    flex: 0 0 auto;
    position: relative;
    &-data {
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        left: 0;
        top: 0;
        position: relative;
        transition: all 0.2s;
        &--img {
            object-fit: cover;
            object-position: center;
            z-index: 1;
        }
        &--caption {
            width: 100%;
            height: auto;
            position: absolute;
            left: 0;
            z-index: 3;
            color: $c-wh;
            display: flex;
            flex-direction: column;

            > .title {
                font-family: $f-ppal;
                font-weight: 500;
                line-height: 1;
                color: $c-wh;
            }
            > .location {
                font-family: $f-second;
                opacity: 0.85;
                line-height: 1.2;
                font-weight: 200;
                > strong {
                    font-weight: 600;
                }
            }
        }

        &--img,
        &::after {
            width: 100%;
            height: 100%;
            border-radius: $b-radius;
            position: absolute;
            left: 0;
            top: 0;
        }

        &::after {
            content: "";
            z-index: 2;
            background: linear-gradient(to bottom, transparent 30%, rgba(0, 0, 0, 0.9) 110%);
        }
    }
    &-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 3;
        display: flex;
        transition: all 0.2s;
        opacity: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50px;

        > .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: none;
            background-color: transparent;
            > [class*="fa-"] {
                margin-right: 0;
                color: $c-dr;
                transition: all 0.2s;
            }
            &:hover {
                background-color: $c-gr;
                > [class*="fa-"] {
                    font-weight: 600;
                    color: $c-1;
                }
            }
            &.active{
                background-color: $c-1;
                > [class*="fa-"]{
                    color: $c-wh;
                }
            }
        }
        .ant-checkbox {
            &-checked {
                .ant-checkbox {
                    &-inner {
                        background-color: $c-1;
                    }
                }
            }
        }
        &:hover{
            background-color: $c-wh;
        }
    }

    &--liked,
    &--visited {
        .destination__item {
            &-actions {
                opacity: 1;
                .ant-btn {
                    &-save {
                        background-color: $c-1;
                        > [class*="fa-"] {
                            color: $c-wh;
                        }
                    }
                }
            }
        }
    }
    &--visited{
        opacity: .7;
        transition: all .2s;
        &:hover{
            opacity: 1;
        }
    }
    &--see-all {
        .destination__item {
            &-data {
                background-color: $c-gr;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $c-dr;
                border-radius: 15px;
                > [class*="fa-"] {
                    font-size: 20px;
                    margin-bottom: 10px;
                    color: $c-1;
                }
                > span {
                    font-weight: 500;
                    font-family: $f-ppal;
                }
                &::after {
                    display: none;
                }
            }
        }
    }
    &--edit {
        background-color: rgba(0, 0, 0, 0.3);
        .destination__item {
            &-actions {
                opacity: 1;
            }
            &-data {
                opacity: 0.5;
            }
        }
        &-selected,
        &:hover {
            .destination__item {
                &-data {
                    opacity: 1;
                }
            }
        }
    }
    &:hover:not(.destination__item--edit) {
        .destination__item {
            &-data {
                top: -5px;
                left: -5px;
            }
            &-actions {
                right: 13px;
                top: 3px;
                opacity: 1;
            }
        }
    }

    @media (min-width: 861px) {
        width: 150px;
        height: 200px;
        &-data {
            &--caption {
                padding: 0 15px;
                bottom: 15px;

                > .title {
                    font-size: 18px;
                }
                > .location {
                    font-size: 14px;
                    letter-spacing: 0.4px;
                    margin-top: 5px;
                }
            }
        }

        &-actions {
            > .ant-btn {
                width: 32px;
                height: 32px;
                > [class*="fa-"] {
                    font-size: 15px;
                    margin-top: 3px;
                }
            }
            .ant-checkbox {
                &-checked {
                    &::after {
                        display: none;
                    }
                }
                &-inner {
                    width: 23px;
                    height: 23px;
                    border: none;
                    border-radius: 6px;
                    &::after {
                        left: 32%;
                    }
                }
            }
        }
    }
    @media (max-width: 1300px) {
        .destination__item {
            &-actions {
                opacity: 1;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        width: 180px;
        height: 200px;
        &-data {
            &--caption {
                padding: 0 20px;
                bottom: 20px;

                > .title {
                    font-size: 20px;
                }
                > .location {
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
        }

        &-actions {
            > .ant-btn {
                width: 40px;
                height: 40px;
                > [class*="fa-"] {
                    font-size: 20px;
                    margin-top: 3px;
                }
            }
            .ant-checkbox {
                &-checked {
                    &::after {
                        display: none;
                    }
                }
                &-inner {
                    width: 23px;
                    height: 23px;
                    border: none;
                    border-radius: 6px;
                    &::after {
                        left: 32%;
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        width: 150px;
        height: 200px;
        &-data {
            &--caption {
                padding: 0 15px;
                bottom: 15px;

                > .title {
                    font-size: 18px;
                }
                > .location {
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
        }

        &-actions {
            > .ant-btn {
                width: 35px;
                height: 35px;
                > [class*="fa-"] {
                    font-size: 15px;
                    margin-top: 3px;
                }
            }
            .ant-checkbox {
                &-checked {
                    &::after {
                        display: none;
                    }
                }
                &-inner {
                    width: 26px;
                    height: 26px;
                    border: none;
                    border-radius: 6px;
                    &::after {
                        left: 34%;
                    }
                }
            }
        }
    }
}
