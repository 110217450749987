@import "assets/sass/_variables";

.footer__ppal {
    width: 100%;
    height: auto;
    background-color: $c-gr-2;
    display: flex;
    flex-direction: column;
    z-index: 5;
    &-links {
        > .cell {
            display: flex;
            flex-direction: column;

            > .title {
                font-family: $f-ppal;
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 20px;
                color: $c-dr;
            }
            > .list__items {
                > li {
                    margin-bottom: 12px;
                    > a {
                        color: $c-dr;
                        font-size: 15px;
                        font-family: $f-second;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &-bottom {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        padding: 35px 0;
        font-size: 15px;
        font-family: $f-second;
        display: flex;
        > .copy {
            color: $c-dr;
            a {
                color: $c-dr;
                margin: 0 10px;
                &:hover {
                    color: $c-dr;
                    text-decoration: underline;
                }
            }
        }
        > .extra {
            display: flex;
            > li {
                margin-right: 25px;
                > a {
                    > [class*="fa-"] {
                        font-size: 20px;
                        color: $c-dr;
                        opacity: 0.5;
                        transition: all 0.2s;
                    }
                    &:hover {
                        background-color: $c-gr;
                        > [class*="fa-"] {
                            opacity: 1;
                        }
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @media (min-width: 1301px) {
        $spacing: 100px;
        padding: 0 $spacing 0;
        &-links {
            padding: 50px 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 50px;
            .cell {
                &__column {
                    &-1 {
                        &:first-child {
                            grid-column: 1;
                            grid-row: 1;
                        }
                        &:nth-child(2) {
                            grid-column: 2;
                            grid-row: 1;
                        }
                    }
                    &-2 {
                        grid-row: 1;
                        .list__items {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }


                        &:first-child {
                            grid-column: 1 / 3;
                        }
                        &:last-child {
                            grid-column: 3 / 5;
                        }
                    }
                }
            }
        }
        &-bottom {
            justify-content: space-between;
            align-items: center;
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        padding: 0 50px;
        &-links {
            padding: 50px 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 50px;
            .cell {
                &__column {
                    &-1 {
                        &:first-child {
                            grid-column: 1;
                            grid-row: 1;
                        }
                        &:nth-child(2) {
                            grid-column: 2;
                            grid-row: 1;
                        }
                    }
                    &-2 {
                        grid-row: 1;
                        .list__items {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }
                        &:first-child {
                            grid-column: 1 / 3;
                        }
                        &:last-child {
                            grid-column: 3 / 5;
                        }
                    }
                }
            }
        }
        &-bottom {
            justify-content: space-between;
            align-items: center;
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        padding: 0 50px;
        &-links {
            padding: 50px 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;
            .cell {
                &__column {
                    &-1 {
                        &:first-child {
                            grid-column: 1;
                            grid-row: 1;
                        }
                        &:nth-child(2) {
                            grid-column: 2;
                            grid-row: 1;
                        }
                    }
                    &-2 {
                        grid-row: 1;
                        .list__items {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }
                        &:first-child {
                            grid-column: 1 / 3;
                        }
                        &:last-child {
                            grid-column: 3 / 5;
                        }
                    }
                }
            }
        }
        &-bottom {
            justify-content: space-between;
        }
    }
    @media (max-width: 720px) {
        $spacing: 35px;
        padding: 0 $spacing 130px;
        &-links {
            padding: $spacing 0;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            .cell {
                .title {
                    margin-bottom: 10px;
                }
                &__column {
                    &-1 {
                        &:first-child {
                            grid-column: 1;
                            grid-row: 1;
                        }
                        &:nth-child(2) {
                            grid-column: 2;
                            grid-row: 1;
                        }
                    }
                }
            }
        }
        &-bottom {
            flex-direction: column;
            > .copy {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: 20px;
                > span {
                    margin-bottom: 10px;
                    line-height: 1.3;
                }
                a {
                    margin: 10px 0;
                }
            }
            > .extra {
                justify-content: center;
                flex-direction: row-reverse;
                > li {
                    > a {
                        > [class*="fa-"] {
                            font-size: 22px;
                        }
                    }
                    &:first-child {
                        margin-right: 0;
                    }
                    &:last-child {
                        margin-right: 25px;
                    }
                }
            }
        }
    }
}
