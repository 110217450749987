@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.ant-notification{
    &-notice{
        padding: 20px 50px 20px 20px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.9);
        margin-bottom: 10px;
        transition: all .2s;
        &-message,
        &-description{
            margin-left: 0 !important;
            color: $c-wh;
        }
        &-message{
            font-family: $f-ppal;
            font-weight: 500;
            font-size: 22px !important;
            margin-bottom: 0 !important;
        }
        &-description{
            font-family: $f-second;
            line-height: 1.2;
            font-weight: 200;
            opacity: .8;
            font-size: 16px !important;
            > strong{
                font-weight: 600;
            }
            > a, > .fake-link {
                color: #ffadad;
                font-weight: 500;
                cursor: pointer;
            }
        }
        &-with-icon{
            > .anticon{
                display: none;
            }
        }
        &-close{
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.4);
            color: $c-wh;
            border-radius: 100%;
            top: 10px;
            right: 10px;
            transition: all .2s;
            &:hover{
                color: $c-wh;
            }
        }

        &:hover{
            background-color: rgba(0, 0, 0, 1);
            .ant-notification-notice-close{
                background-color: $c-1;
            }
        }
        &:last-child{
            margin-bottom: 20px;
        }
    }
}