@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.container__dest {
    &-gallery {
        position: relative;
        @media (min-width: 1201px) {
            width: calc(60% + 15px);
            height: 100%;
            margin-right: -15px;
            flex: 1 0 auto;
        }
        @media (min-width: 1101px) and (max-width: 1200px) {
            width: calc(50% + 15px);
            height: 100%;
            margin-right: -15px;
            flex: 1 0 auto;
        }
        @media (max-width: 1100px) {
            width: 100%;
            height: calc(300px + 20px);
            margin-bottom: -20px;
        }
    }
    &-info {
        z-index: 3;
        @media (min-width: 1201px) {
            width: 40%;
            background-color: $c-wh;
            border-radius: 15px 0 0 15px;
            padding: 0;
            overflow: hidden;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        }
        @media (min-width: 1101px) and (max-width: 1200px) {
            width: 50%;
            background-color: $c-wh;
            border-radius: 15px 0 0 15px;
            padding: 0;
            overflow: hidden;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        }
        @media (max-width: 1100px) {
            width: 100%;
            background-color: $c-wh;
            border-radius: 20px 20px 0 0;
            padding: 0;
            position: relative;
        }
    }
}