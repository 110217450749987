@import "assets/sass/_reset";
@import "assets/sass/_variables";
@import "assets/sass/_mixins";
@import "assets/sass/components/_buttons";
@import "assets/sass/components/_tooltips";
@import "assets/sass/components/_notifications";
@import "assets/sass/components/_forms";
@import "assets/sass/components/_dropdowns";
@import "assets/sass/components/_typo";
@import "assets/sass/components/_modals";
@import "assets/sass/components/_drawers";
@import "assets/sass/components/skeleton";
@import "assets/sass/components/_popovers";
@import "assets/sass/components/_collapses";
@import "assets/sass/components/_radios";

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
body {
    font-family: $f-ppal, Arial, Helvetica, sans-serif;

    @media (max-width: 860px) {
        overflow: auto;
    }
}
#root {
    @media (max-width: 860px) {
        height: auto;
    }
}
a {
    &:hover {
        text-decoration: none;
    }
}

// selection
::-moz-selection {
    color: inherit;
    background-color: rgba(247, 108, 108, 0.2);
}
::selection {
    color: inherit;
    background-color: rgba(247, 108, 108, 0.2);
}

// delete outline
* {
    outline: none !important;
    box-sizing: border-box;
}

.page-container {
    .page {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
}

// list defualt
.list-default {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.list__items {
    &-home,
    &-activities {
        width: auto;
        padding: 5px 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto !important;

        &::after {
            width: 1px;
            height: 1px;
            content: "";
            flex: 1 0 auto;
        }
    }
    &-dictionary,
    &-whatToDo,
    &-providers,
    &-FaunaFlora,
    &-dishes {
        position: relative;
        &-container {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto !important;
        }
    }
    &-mosaic-card {
        display: grid;

        > .destination__item {
            width: 100%;
            margin: 0;
        }

        @media (min-width: 1301px) {
            grid-template-columns: repeat(6, 1fr);
            gap: 30px;
            &-profile {
                grid-template-columns: repeat(5, 1fr);
            }
            > .destination__item {
                height: 200px;
            }
        }
        @media (min-width: 1051px) and (max-width: 1300px) {
            $spacing: 60px;

            grid-template-columns: repeat(5, 1fr);
            gap: 30px;
            > .destination__item {
                height: 200px;
            }
        }
        @media (min-width: 861px) and (max-width: 1050px) {
            $spacing: 60px;

            grid-template-columns: repeat(5, 1fr);
            gap: 20px;
            > .destination__item {
                height: 200px;
            }
        }
        @media (min-width: 741px) and (max-width: 860px) {
            $spacing: 80px;

            padding-top: 10px;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
            > .destination__item {
                height: 200px;
            }
        }
        @media (min-width: 521px) and (max-width: 740px) {
            $spacing: 35px;

            padding-top: 10px;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }
        @media (max-width: 520px) {
            $spacing: 35px;

            padding-top: 10px;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }
}

// texts
.title__ppal,
.subtitle__ppal {
    &.c-dr {
        color: $c-dr;
    }
    &.c-wh {
        color: $c-wh;
    }

    &.center {
        text-align: center;
    }
}
.subtitle__ppal {
    line-height: normal;
    font-family: $f-ppal;
    font-size: 22px;
    font-weight: 400;

    &.c-dr {
        color: $c-dr;
    }
    &.c-wh {
        color: $c-wh;
    }

    &.xs {
        font-size: 20px;
    }
    &.xxs {
        font-size: 17px;
    }
}
.text__enr,
.text__enr-2 {
    font-family: $f-second;
    color: $c-dr;
    font-weight: 300;
    > p {
        color: $c-dr;
        a {
            color: $c-1;
            text-decoration: underline;
        }
        strong,
        b {
            font-weight: 700;
        }
        em {
            font-style: italic;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    > h3,
    > h4 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: $f-ppal;
        > strong {
            font-weight: 600;
        }
    }
    > h3 {
        font-size: 28px;
        > strong {
            font-weight: 600;
        }
    }
    > h4 {
        font-size: 23px;
        > strong {
            font-weight: 600;
        }
    }

    > .ant-btn {
        width: fit-content;
        margin-top: 20px;
    }

    &.margin {
        &-b {
            margin-bottom: 20px;
        }
    }

    @media (min-width: 1301px) {
        > p {
            line-height: 1.5;
            font-size: $tx-parag;
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        > p {
            line-height: 1.6;
            font-size: $tx-parag;
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        > p {
            line-height: 1.5;
            font-size: 17px;
        }
    }

    @media (max-width: 720px) {
        > p {
            line-height: 1.4;
            font-weight: 300;
            font-size: 17px;
        }
    }
}
.text__enr {
    > p {
        margin-bottom: 10px;
    }
    > ul {
        margin-top: 25px;
        background-color: $c-gr;
        padding: 20px;
        border-radius: 10px;
        > li {
            position: relative;
            padding-left: 25px;
            margin-bottom: 20px;
            font-size: 15px;
            &::before {
                @include fontAwesome("\f138");
                position: absolute;
                left: 0;
                top: 0px;
                color: $c-1;
                font-weight: 300;
            }
            strong {
                font-weight: 700;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    > h5 {
        font-size: 13.5px;
        opacity: 0.6;
        margin-top: 20px;
    }
}
.text__enr-2 {
    counter-reset: counter-ols;
    > p {
        opacity: 0.9;
        margin-bottom: 10px;
        &.block {
            &-img {
                > img {
                    width: 100%;
                }
            }
        }
    }
    > ul {
        > li {
            position: relative;
            &::before {
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: $c-2;
                position: absolute;
                opacity: 0.7;
                left: 0;
                top: 8px;
                content: "";
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    > ol {
        > li {
            position: relative;
            padding-left: 25px;
            &::before {
                content: counter(counter-ols);
                counter-increment: counter-ols;
                position: absolute;
                opacity: 0.5;
                left: 0;
                top: 0;
                font-size: 17px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    > pre {
        white-space: normal;
        border-radius: 10px;
        padding: 20px;
        background-color: $c-gr;
        font-family: $f-second;
        font-style: italic;
        font-weight: 300;
        line-height: 1.6;
    }
    @media (min-width: 1301px) {
        > h1 {
            font-size: 25px;
            margin: 50px 0 10px;
            font-weight: 600;
        }
        > h2 {
            font-size: 22px;
            margin: 30px 0 10px;
            font-weight: 600;
        }
        > ul,
        > ol {
            margin: 15px 0 25px;
            > li {
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        > ul {
            padding-left: 30px;
            padding-right: 200px;
            > li {
                padding-left: 25px;
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        > h1 {
            font-size: 25px;
            margin: 50px 0 10px;
            font-weight: 600;
        }
        > h2 {
            font-size: 22px;
            margin: 30px 0 10px;
            font-weight: 600;
        }
        > h4 {
            font-size: 22px;
        }
        > h3 {
            font-size: 25px;
        }
        > ul,
        > ol {
            margin: 15px 0 25px;
            > li {
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        > ol {
            padding-left: 70px;
            padding-right: 300px;
        }
        > ul {
            padding-left: 30px;
            padding-right: 200px;
            > li {
                padding-left: 25px;
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        > h1 {
            font-size: 25px;
            margin: 50px 0 10px;
            font-weight: 600;
        }
        > h2 {
            font-size: 22px;
            margin: 30px 0 10px;
            font-weight: 600;
        }
        > h4 {
            font-size: 22px;
        }
        > h3 {
            font-size: 25px;
        }
        > ul,
        > ol {
            margin: 15px 0 25px;
            > li {
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        > ol {
            padding-left: 70px;
            padding-right: 300px;
        }
        > ul {
            padding-left: 30px;
            padding-right: 100px;
            > li {
                padding-left: 25px;
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }
    @media (max-width: 720px) {
        > h1 {
            font-size: 22px;
            margin: 50px 0 10px;
            font-weight: 600;
        }
        > h2 {
            font-size: 19px;
            margin: 30px 0 10px;
            font-weight: 600;
        }
        > h4 {
            font-size: 23px;
        }
        > h3 {
            font-size: 20px;
        }
        > ul,
        > ol {
            margin: 15px 0 25px;
            > li {
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
        > ol {
            padding-left: 50px;
        }
        > ul {
            margin: 15px 0 25px;
            padding: 0 20px;
            > li {
                padding-left: 25px;
                font-size: 17px;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }
    }
}

.ant-typography {
    line-height: 1.2;
}
.title {
    &__ppal {
        line-height: normal;
        font-family: $f-ppal;

        strong {
            font-weight: bold;
        }

        &.xs {
            font-size: 25px;

            @media (max-width: 720px) {
                font-size: 22px;
            }
        }
        &.sm {
            font-size: 31px;

            @media (max-width: 720px) {
                font-size: 25px;
            }
        }
        &.md {
            font-size: 42px;

            @media (max-width: 720px) {
                font-size: 32px;
            }
        }
        &.xxl {
            font-size: 120px;

            @media (max-width: 720px) {
                font-size: 60px;
            }
        }
    }
    &__with {
        &-extra {
            display: flex;
            > .title__ppal {
                width: 100%;
            }
            > .extra__container {
                display: flex;
                > span {
                    margin-right: 10px;
                    font-family: $f-ppal;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                    opacity: 0.8;
                    color: $c-dr;
                }
                .ant-form {
                    &-item {
                        margin-bottom: 0;
                        &-control {
                            &-input {
                                &-content {
                                    > .ant-select {
                                        width: 210px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (min-width: 861px) {
                align-items: flex-start;
                justify-content: space-between;
                > .extra__container {
                    margin-left: 20px;
                    margin-top: -10px;
                    align-items: center;
                    flex: 1 0 auto;
                }
                > .ant-btn {
                    margin-left: 20px;
                }
            }
            @media (min-width: 721px) and (max-width: 860px) {
                align-items: center;
                justify-content: space-between;
                > .extra__container {
                    flex-direction: column;
                    > span {
                        margin-bottom: 5px;
                    }
                }
            }
            @media (max-width: 720px) {
                flex-direction: column;
                > .extra__container {
                    margin: 20px 0;
                    flex-direction: column;
                    > span {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

// sections
.section__ {
    &default {
        .title__ppal {
            font-weight: 700;
        }
        @media (min-width: 1201px) {
            padding: 70px 0;
        }
        @media (max-width: 500px) {
            padding: 40px 0;
        }
    }
}

// actions
.actions__generic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &-left,
    &-right {
        display: flex;
        > .ant-btn {
            margin-right: 10px;
            height: 50px;
            padding: 2px 20px;
            box-shadow: none;
            > [class*="fa-"] {
                font-size: 17px;
            }
            > [class*="fa-"] {
                margin-top: 3px;
            }
            > .text {
                margin-top: 1px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            &.solid {
                border: none;
            }
            &-prev {
                > [class*="fa-"] {
                    font-size: 25px;
                    color: $c-dr;
                }
                &:hover {
                    background-color: $c-1;
                    > [class*="fa-"] {
                        color: $c-wh;
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-right {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        margin-left: 10px;
        overflow-x: auto;
        @include scrollHor($c-wh, $c-wh, 1px);
        .guest__users {
            margin-right: 10px;
            display: flex;
            align-items: center;
            &-item {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $c-gr;
                font-size: 13px;
                color: $c-2;
                font-weight: 500;
                text-transform: uppercase;
                border-radius: 100%;
                margin-right: -12px;
                border: 3px solid $c-wh;
                cursor: pointer;
                overflow: hidden;
                transform: scale(1);
                transition: all 0.2s;
                position: relative;

                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 100%;
                }

                > [class*="fa-"] {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $c-wh;
                    position: absolute;
                    padding-top: 2px;
                    left: 0;
                    top: 0;
                    background-color: rgba(0, 0, 0, 0.4);
                    opacity: 0;
                    pointer-events: none;
                }

                &:hover {
                    transform: scale(1.1, 1.1);
                    border-width: 2px;
                    z-index: 2;
                    > [class*="fa-"] {
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                &-share {
                    > [class*="fa-"] {
                        opacity: 1;
                        font-size: 18px;
                        background-color: $c-3;
                    }
                }
                &-all-users {
                    > [class*="fa-"] {
                        opacity: 1;
                        font-size: 18px;
                        color: $c-dr;
                        background-color: $c-gr;
                    }

                    &.ant {
                        &-popover {
                            &-open {
                                transform: scale(1.1, 1.1);
                                border-width: 2px;
                                z-index: 2;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        width: calc(100% + (35px * 2));
        margin-left: -35px;
        padding: 0 35px;
        overflow: hidden;
        position: relative;

        &-left,
        &-right {
            .guest__users {
                margin-right: 5px;
            }
            > .ant-btn {
                margin-right: 10px;
            }
        }
        &-right {
            > .ant-btn {
                min-width: 50px;
                max-width: 50px;
            }
            > .ant-btn {
                > [class*="fa-"] {
                    margin-right: 0;
                }
                > .text {
                    display: none;
                }
            }
        }
    }
}

// filters categories
.filters__category {
    display: flex;
    width: 100%;
    overflow: auto;
    @include scrollHor($c-wh, $c-wh, 1px);
    list-style-type: none;
    > li {
        width: auto;
        margin-right: 10px;
        cursor: pointer;
        > a,
        > div {
            display: flex;
            white-space: nowrap;
            cursor: pointer;
            align-items: center;
            height: 45px;
            border-radius: 45px;
            padding: 0 20px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            color: rgba(0, 0, 0, 0.7);
            font-family: $f-second;
            font-size: 16px;
            font-weight: 500;
            transition: all 0.2s;
            position: relative;

            > .count {
                width: 25px;
                height: 25px;
                margin-left: 8px;
                margin-right: -10px;
                background-color: $c-gr;
                color: $c-2;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
            }

            &:hover {
                border-color: $c-dr;
                color: $c-dr;
            }
        }
        > div{
            pointer-events: none;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    &::after {
        content: "";
        width: 35px;
        height: 1px;
        display: block;
        flex: 1 0 auto;
    }

    &.solid {
        &.c-1 {
            > li {
                > a,
                > div {
                    &.active {
                        border-color: $c-1;
                        background-color: $c-1;
                        color: $c-wh;

                        &:hover {
                            color: $c-wh;
                            border-color: $c-1;
                        }
                    }
                }
            }
        }
        &.c-2 {
            > li {
                > a,
                > div {
                    &.active {
                        border-color: $c-2;
                        background-color: $c-2;
                        color: $c-wh;
                        .count {
                            background-color: $c-wh;
                            color: $c-dr;
                        }
                        &:hover {
                            color: $c-wh;
                            border-color: $c-2;
                        }
                    }
                }
            }
        }
    }

    &-mosaic {
        flex-wrap: wrap;
        margin-top: -5px !important;
        > li {
            margin-top: 10px;
        }
    }
}

// blocks information
.block {
    &__infographic,
    &__aditional {
        position: relative;
        > .text__enr {
            margin-bottom: 20px;

            > .atmosphere-img {
                width: 150px;
                background-color: #4d5858;
                padding: 10px 12px;
                margin-top: 10px;
            }
        }

        @media (min-width: 1301px) {
            margin-bottom: 80px;
        }
        @media (min-width: 861px) and (max-width: 1300px) {
            margin-bottom: 60px;
        }
        @media (min-width: 721px) and (max-width: 860px) {
            margin-bottom: 80px;
        }
        @media (max-width: 720px) {
            margin-bottom: 35px;
        }
    }
    &__infographic {
        @media (min-width: 1301px) {
            &.side {
                &-right {
                    padding-right: 350px;
                    > svg {
                        width: 400px;
                        right: -100px;
                        top: 50%;
                        margin-top: -30px;
                        transform: translateY(-50%);
                        position: absolute;
                    }
                }
            }
        }
        @media (min-width: 861px) and (max-width: 1300px) {
            &.side {
                &-right {
                    padding-right: 370px;
                    > svg {
                        width: 400px;
                        right: -60px;
                        top: 50%;
                        margin-top: -30px;
                        transform: translateY(-50%);
                        position: absolute;
                    }
                }
            }
        }
        @media (min-width: 721px) and (max-width: 860px) {
            &.side {
                &-right {
                    padding-right: 260px;
                    > svg {
                        width: 300px;
                        right: -80px;
                        position: absolute;
                    }
                }
            }
        }
        @media (max-width: 720px) {
            &.side {
                &-right {
                    > svg {
                        display: none;
                    }
                }
            }
        }
    }
    &__aditional {
        display: flex;
        > .text__enr {
            width: 100%;
        }
        .block__info {
            > .title__ppal {
                margin-bottom: 20px;
            }

            &-list {
                display: flex;
                flex-direction: column;

                > li {
                    display: flex;
                    margin-bottom: 30px;
                    > [class*="fa-"] {
                        color: $c-1;
                        font-size: 22px;
                        margin-right: 20px;
                        flex: 1 0 auto;
                        margin-top: 5px;
                    }
                    > .ant-typography {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        > a {
                            display: block;
                            margin-bottom: 3px;
                            color: $c-dr;
                            font-weight: 400;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (min-width: 861px) {
            > .text__enr {
                padding-right: 50px;
            }
            .block__info {
                width: 400px;
                flex: 1 0 auto;
                padding: 40px;
                margin-top: -50px;
                background-color: $c-wh;
                border-radius: 15px;
                box-shadow: 8px 8px 0 0 #f24c27, 12px 12px 25px 0 rgba(0, 0, 0, 0.3);
            }
        }
        @media (max-width: 860px) {
            flex-direction: column;
            .block__info {
                margin-top: 30px;
            }
        }
    }
}

// masks

// mask
.ant-modal,
.ant-drawer {
    &-mask {
        backdrop-filter: blur(6px);
        background-image: -webkit-linear-gradient(
            bottom left,
            rgba(43, 40, 50, 0.8) 0%,
            rgba(83, 86, 99, 0.8) 45%,
            rgba(69, 77, 91, 0.6) 60%
        );
        background-image: -moz-linear-gradient(
            bottom left,
            rgba(43, 40, 50, 0.8) 0%,
            rgba(83, 86, 99, 0.8) 45%,
            rgba(69, 77, 91, 0.6) 60%
        );
        background-image: -o-linear-gradient(
            bottom left,
            rgba(43, 40, 50, 0.8) 0%,
            rgba(83, 86, 99, 0.8) 45%,
            rgba(69, 77, 91, 0.6) 60%
        );
        background-image: linear-gradient(
            to top right,
            rgba(50, 46, 40, 0.8) 0%,
            rgba(83, 86, 99, 0.8) 45%,
            rgba(69, 77, 91, 0.6) 60%
        );

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background-image: radial-gradient(#556c79 0.5px, transparent 0.5px);
            background-size: 7px 7px;
        }
    }
}

// block default
.block__form-default {
    width: 400px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 10px;

    .title__ppal {
        margin-bottom: 20px;
    }

    @media (max-width: 720px) {
        width: 100%;
        padding: 0;
        border: none;
    }
}
