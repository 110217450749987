@import "../variables";

.ant-tooltip {
    &-inner {
        font-family: $f-ppal;
        border-radius: 15px;
        padding: 5px 14px;
        min-height: auto;
    }
    @media (max-width: 800px) {
        &:not(.ant-tooltip-visible) {
            display: none;
        }
    }
}
