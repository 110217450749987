@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.modal {
    &__default {
        .ant-modal {
            padding-bottom: 0;
            &-content {
                box-shadow: none;
                border-radius: 10px;
                .ant-modal-close {
                    top: 10px;
                    right: 10px;
                    z-index: 1001;
                    &-x {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background-color: $c-gr-2;
                        line-height: normal;
                        transition: all 0.2s;
                        color: $c-dr;
                        &::before {
                            @include fontAwesome("\f00d");
                            font-weight: 200;
                            font-size: 20px;
                            margin-left: 2px;
                            color: $c-dr;
                            opacity: 0.6;
                        }
                        > span {
                            display: none;
                        }
                    }
                }
                > .ant-modal {
                    &-header {
                        border-radius: 10px 10px 0 0;
                    }
                    &-body {
                        .place__preview {
                            display: flex;
                            align-items: flex-start;
                            &-image {
                                width: 45px;
                                height: 45px;
                                flex: 1 0 auto;
                                object-fit: cover;
                                object-position: center;
                                border-radius: 7px;
                                margin-right: 16px;
                            }
                            > .title__ppal {
                                width: 100%;
                                margin-bottom: 0;
                                margin-top: 3px;
                                > span {
                                    font-size: 17px;
                                }
                                > strong {
                                    margin-top: -2px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                    &-footer {
                        border: none;
                        padding: 10px 50px;
                        position: relative;
                        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                        display: flex;
                        justify-content: space-between;

                        > .ant-btn {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
        @media (max-width: 500px) {
            .ant-modal {
                overflow: auto;
                &-content{
                    border-radius: 0;
                }
            }
            &--full-mobile {
                .ant-modal {
                    margin: 0;
                    width: 100% !important;
                    height: 100%;
                    max-width: none;
                    border-radius: 0;
                    &-content {
                        height: 100%;
                    }
                }
            }
            &--bottom-mobile {
                .ant-modal {
                    margin: 0;
                    width: 100% !important;
                    height: auto;
                    max-height: calc(100% - 35px);
                    max-width: none;
                    border-radius: 0;
                    position: absolute;
                    overflow: initial;
                    left: 0;
                    top: auto;
                    bottom: 0;
                    &-content {
                        height: 100%;
                        border-radius: 20px 20px 0 0;
                    }
                }
            }
        }
    }
    &__auth {
        .ant-modal {
            height: 86%;
            &-content {
                height: 100%;
                overflow: hidden;
                background-color: transparent;
                > .ant-modal {
                    &-body {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        > .ant-modal {
                            &-header,
                            &-footer {
                                flex: 1 0 auto;
                            }
                            &-body {
                                height: 100%;
                                overflow: auto;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 860px) {
            .ant-modal {
                .ant-modal-close {
                    &-x {
                        width: 50px;
                        height: 50px;
                        background-color: transparent;
                        &::before {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
    &__steps {
        .ant-modal {
            margin: 0;
            &-content {
                > .ant-modal {
                    &-header {
                        background-color: transparent;
                    }
                    &-body {
                        position: relative;
                        overflow: hidden;
                        padding: 0;
                        height: 100%;

                        .step {
                            &__message,
                            &__fields,
                            &__default {
                                width: 100%;
                                height: 100%;
                                transition: all 0.45s;
                            }
                            &__default {
                                display: flex;
                                flex-direction: column;
                            }
                            &__message {
                                z-index: 1;
                                position: relative;
                                background-repeat: no-repeat;
                                right: 0;
                                transform: scale(1);
                                display: flex;
                                flex-direction: column;
                                overflow: hidden auto;
                                > svg {
                                    position: absolute;
                                }
                                &-features {
                                    display: flex;
                                    padding-left: 30px;
                                    flex-direction: column;
                                    > li {
                                        margin-bottom: 15px;
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        > .tag {
                                            position: absolute;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            left: -35px;
                                            padding: 8px 35px 8px 8px;
                                            border-radius: 4px;
                                            background-color: $c-gr;
                                            color: $c-1;
                                            font-size: 10px;
                                            text-transform: uppercase;
                                        }
                                        > [class*="fa-"] {
                                            text-align: center;
                                            display: flex;
                                            justify-content: center;
                                            margin-right: 15px;
                                            flex: 1 0 auto;
                                            font-weight: 200;
                                            color: $c-1;
                                        }
                                        > .text {
                                            width: 100%;
                                            font-family: $f-second;
                                        }
                                        &.pro {
                                            > [class*="fa-"] {
                                                color: $c-1;
                                                z-index: 2;
                                                opacity: 0.8;
                                            }
                                        }
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                                &-actions {
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row-reverse;
                                    justify-content: space-between;
                                    align-items: center;
                                    .ant-btn {
                                        margin: 0;
                                        &-send {
                                            margin-right: 0;
                                            &:last-child {
                                                margin-right: 0;
                                            }
                                            @media (max-width: 721px) {
                                                min-height: 55px;
                                                max-height: 55px;
                                            }
                                        }
                                        &:last-child {
                                            margin-left: 0;
                                        }
                                    }
                                }
                                &-invitations {
                                    display: grid;
                                    grid-template-columns: repeat(1, 1fr);
                                    gap: 5px;
                                    margin-bottom: 20px;

                                    &-item {
                                        display: flex;
                                        align-items: center;
                                        padding: 7px 7px 7px 12px;
                                        border-radius: 5px;
                                        background-color: $c-gr-2;
                                        position: relative;

                                        > span {
                                            width: 100%;
                                            padding-right: 30px;
                                            word-break: break-all;
                                            @include truncate(15.5px, 1.2, 1);
                                            font-family: $f-second;
                                        }

                                        > .delete {
                                            width: 25px;
                                            height: 25px;
                                            flex: 1 0 auto;
                                            padding-top: 3px;
                                            border: none;
                                            background-color: rgba(0, 0, 0, 0.1);
                                            color: $c-dr;
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border-radius: 100%;
                                            transition: all 0.2s;
                                            > [class*="fa-"] {
                                                font-size: 15px;
                                            }
                                        }

                                        &-error,
                                        &-sended,
                                        &-error {
                                            > .status {
                                                &::after {
                                                    width: 25px;
                                                    height: 25px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    flex: 1 0 auto;
                                                    border-radius: 100%;
                                                }
                                            }
                                        }
                                        &-error {
                                            > .status {
                                                &::after {
                                                    @include fontAwesome("\f00d");
                                                    background-color: $c-danger;
                                                    color: $c-wh;
                                                }
                                            }
                                        }
                                        &-sended {
                                            > .status {
                                                &::after {
                                                    @include fontAwesome("\f00c");
                                                    background-color: $c-success;
                                                    color: $c-wh;
                                                }
                                            }
                                        }

                                        &:hover {
                                            > .delete {
                                                background-color: $c-dr;
                                                > [class*="fa-"] {
                                                    color: $c-wh;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &__fields {
                                position: absolute;
                                top: 0;
                                left: 100px;
                                opacity: 0;
                                transform: scale(1);
                                overflow: auto;
                                @include scroll(#e8ecf3, rgba(0, 0, 0, 0.2));
                            }
                        }

                        .ant-btn {
                            &-prev {
                                position: absolute;
                                left: 55px;
                                z-index: 1;
                                top: 50px;
                                opacity: 0;
                                transition: all 0.2s;
                                box-shadow: none;
                                pointer-events: none;
                                box-shadow: 5px 0 10px rgba(0, 0, 0, 0.07);
                                > [class*="fa-"] {
                                    font-size: 23px;
                                }
                            }
                        }

                        .title__ppal {
                            display: flex;
                            flex-direction: column;
                            line-height: normal;
                            > strong {
                                > [class*="fa-"] {
                                    margin-right: 10px;
                                }
                            }
                            > span {
                                font-weight: 200;
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &-show-form {
            .ant-modal {
                &-content {
                    > .ant-modal {
                        &-body {
                            > .ant-btn-prev {
                                opacity: 1;
                                left: 15px;
                                pointer-events: all;
                            }
                            .step {
                                &__message {
                                    border-radius: 10px;
                                    right: calc(100% - 40px);
                                    transform: scale(0.9);
                                    transform-origin: right;
                                    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
                                    pointer-events: none;
                                }
                                &__fields {
                                    opacity: 1;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 501px) {
            .ant-modal {
                &-content {
                    height: 550px;
                    > .ant-modal {
                        &-body {
                            .title__ppal {
                                margin-bottom: 15px;
                                > span {
                                    margin-top: -5px;
                                    font-size: 18px;
                                }
                            }
                            .step {
                                &__message,
                                &__default {
                                    padding: 30px 50px;
                                }
                            }
                            .step {
                                &__message {
                                    &-features {
                                        margin: 20px 0 0;
                                        > li {
                                            > [class*="fa-"] {
                                                width: 20px;
                                                font-size: 20px;
                                                opacity: 0.5;
                                            }
                                            > .text {
                                                font-weight: 400;
                                                line-height: 1.2;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                    &-actions {
                                        margin-top: 20px;
                                    }
                                    > .ant-btn-prev {
                                        width: 50px;
                                    }
                                    > .text__enr {
                                        > p {
                                            font-size: 16px;
                                            line-height: 1.3;
                                            margin-bottom: 10px;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                                &__fields {
                                    padding: 50px 50px 50px 90px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 720px) and (min-height: 501px) {
            &-register {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-size: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 500px) {
            .ant-modal {
                &-content {
                    > .ant-modal {
                        &-body {
                            .title__ppal {
                                margin-bottom: 20px;
                            }
                            .step {
                                &__message,
                                &__default {
                                    padding: 35px;
                                }
                            }
                            .step {
                                &__message {
                                    background-size: 250px;
                                    > .title__ppal {
                                        > strong {
                                            font-size: 30px;
                                        }
                                        > span {
                                            margin-top: -5px;
                                            font-size: 20px;
                                        }
                                    }
                                    &-features {
                                        margin: 30px 0 10px;
                                        > li {
                                            > [class*="fa-"] {
                                                width: 30px;
                                                font-size: 22px;
                                                opacity: 0.5;
                                            }
                                            > .tag {
                                                left: -30px;
                                            }
                                            > .text {
                                                font-weight: 3400;
                                                line-height: 1.2;
                                                opacity: 0.6;
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                    > .text__enr {
                                        > p {
                                            font-size: 18px;
                                            line-height: 1.3;
                                            margin-bottom: 10px;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                    &-actions {
                                        margin-top: 30px;
                                    }
                                }
                                &__fields {
                                    padding: 50px 35px 50px 90px;
                                }
                            }

                            > .ant-btn-prev {
                                width: 55px;
                                top: 70px;
                            }
                        }
                    }
                }
            }
        }
        // sizes backgrounds and svgs
        @media (min-width: 501px) and (min-height: 551px) {
            &-experiences {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-position: -20px 101%;
                                        background-size: 90%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-recommend {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-position: center 50px;
                                        background-size: 300px;
                                        padding-top: 319px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-suggestions {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        padding-top: 319px;

                                        > svg {
                                            width: 250px;
                                            top: 50px;
                                            left: 50%;
                                            transform: translateX(-50%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-register {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-position: -10px -20px;
                                        background-size: 200px;
                                        justify-content: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 500px) and (min-height: 549px) {
            &-recommend {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-position: 35px calc(50% - 130px);
                                        background-size: 280px;
                                        padding-top: 285px;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-experiences {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-position: 35px calc(50% - 130px);
                                        background-size: 280px;
                                        padding-top: 285px;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-suggestions {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        padding-top: 285px;
                                        justify-content: center;
                                        > svg {
                                            width: 280px;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            padding-bottom: 220px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-register {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-image: none !important;
                                        justify-content: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-type {
                &-1 {
                    .ant-modal {
                        &-content {
                            .ant-modal {
                                &-body {
                                    > .step {
                                        &__message {
                                            background-position: 10px 20%;
                                            background-size: 95%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 500px) and (max-height: 548px) {
            &-recommend,
            &-experiences,
            &-suggestions {
                .ant-modal {
                    &-content {
                        .ant-modal {
                            &-body {
                                > .step {
                                    &__message {
                                        background-image: none !important;
                                        justify-content: center;

                                        > svg {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__auto {
        @media (min-width: 501px) {
            .ant-modal {
                overflow: auto;
                &-content {
                    height: auto;
                    .step__message{
                        height: auto !important;
                    }
                }
            }
        }
        @media (max-width: 500px) {
            .ant-modal {
                &-content {
                    height: 100%;
                }
            }
        }
    }
    &__delete {
        .ant-modal {
            &-title {
                display: flex;
                align-items: center;
                font-size: 25px;
                color: $c-danger;
                > [class*="fa-"] {
                    margin-right: 15px;
                }
            }
            &-header {
                background-color: transparent;
                padding: 25px 35px 0;
                border: none;
            }
            &-body {
                padding: 35px;
            }
        }
    }
}

// confirm
.ant-modal {
    &-confirm {
        .ant-modal-content {
            border-radius: 17px;
            .ant-modal-body {
                .ant-modal-confirm-body-wrapper {
                    .ant-modal-confirm {
                        &-body {
                            padding-left: 65px;
                            > [class*="fa-"] {
                                font-size: 22px;
                                width: 45px;
                                height: 45px;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $c-danger-bg;
                                color: $c-danger;
                                position: absolute;
                                left: 32px;
                                top: 29px;
                            }
                            > .ant-modal-confirm {
                                &-title {
                                    font-family: $f-ppal;
                                    font-size: 22px;
                                    line-height: 1.2;
                                    font-weight: 300;
                                    > strong {
                                        font-weight: 600;
                                    }
                                }
                                &-content {
                                    font-family: $f-second;
                                    font-size: 17px;
                                    line-height: 1.4;
                                    font-weight: 300;
                                    margin-top: 0;
                                    > strong {
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                        &-btns {
                            display: flex;
                        }
                    }
                }
            }
        }

        @media (max-width: 500px) {
            top: auto;
            bottom: 0;
            position: absolute;
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;
            padding-bottom: 0;
            .ant-modal-content {
                height: 100%;
                border-radius: 17px 17px 0 0;
                .ant-modal-body {
                    height: 100%;
                    .ant-modal-confirm-body-wrapper {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .ant-modal-confirm {
                            &-body {
                                height: 100%;
                                overflow: auto;
                                padding-left: 0;
                                > [class*="fa-"] {
                                    display: none;
                                }
                            }
                            &-content {
                                font-size: 19px !important;
                            }
                            &-btns {
                                flex: 1 0 auto;
                                display: flex;
                                justify-content: flex-end;
                                > .ant-btn {
                                    margin: 0 0 0 15px;
                                }
                            }
                        }

                        &::before,
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
