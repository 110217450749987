@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.guides {
    &__list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        background-color: transparent;
        border: none;
    }
    .guide {
        &__item {
            display: flex;
            flex-direction: column;
            border-bottom: none;
            background-color: $c-wh;
            border-radius: 10px !important;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            &-info {
                display: flex;
                align-items: center;
            }
            &-tags{
                margin-top: 10px;
                position: relative;
                display: flex;
                align-items: center;
                white-space: nowrap;
                flex-wrap: nowrap;
                overflow: auto hidden;
                width: calc(100% + 30px);
                margin-left: -15px;
                padding: 0 15px;
                @include scrollHor($c-wh, $c-wh, 1px);
                .ant-tag{
                    padding: 3px 13px;
                    background-color: transparent;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 20px;
                    font-family: $f-ppal;
                    font-size: 13px;
                    font-weight: 300;
                }

                &::before{
                    content: 'Idiomas';
                    display: flex;
                    margin-right: 7px;
                    font-size: 13px;
                    opacity: .5;
                }
            }

            .ant-collapse {
                &-header {
                    width: 100%;
                    padding: 15px;
                }
                &-content{
                    border-top: none;
                    &-box{
                        padding: 10px 20px 0;
                    }
                }
            }
        }

        &-image {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
            margin-right: 15px;
        }
        &-name{
            font-family: $f-ppal;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            > span{
                font-size: 13px;
                margin-top: -5px;
                font-weight: 200;
                opacity: .8;
            }
        }
    }
    @media (min-width: 1301px) {
        padding: 0 80px 50px;
        margin-top: 30px;
        &__list {
            margin-top: 10px;
            gap: 15px;
        }
    }
    @media (max-width: 720px) {
        padding: 0 35px;
        margin-top: 30px;
        &__list {
            margin-top: 15px;
            gap: 20px;
        }
    }
}
