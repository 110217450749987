@import "assets/sass/variables";
@import "assets/sass/mixins";

.dest {
    &__gallery {
        position: relative;
        width: 100%;
        background-color: $c-dr;

        .report-problem {
            width: 40px;
            height: 40px;
            position: absolute;
            border: none;
            background-color: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(3px);
            color: $c-dr;
            font-weight: 500;
            font-size: 15px;
            padding: 0;
            > [class*="fa-"] {
                font-size: 17px;
                margin-right: 0;
            }
            &:hover {
                background-color: $c-1;
                color: $c-wh;
            }
        }
    }
    &__sidebar {
        z-index: 3;
        position: relative;
        background-color: $c-wh;
        &-extra {
            &-caption {
                background-color: #e9e9e9;
            }
        }
    }

    @media (min-width: 1301px) {
        &__sidebar {
            width: 600px;
            flex: 1 0 auto;
            height: 100%;
            padding-top: 80px;
            margin-left: -20px;
            border-radius: 20px 0 0 20px;
            &-depto {
                width: 550px;
                padding-top: 0;
                .dest__sidebar-caption {
                    border-radius: 20px 0 0 0;
                    &-resume {
                        padding-top: 60px;
                    }
                }
            }
            &-extra {
                margin-left: 0px;
                width: 450px;
                padding-top: 70px;
                &-caption {
                    padding: 35px 0 40px;
                    height: 100%;
                    overflow: auto;
                    @include scroll(#e9e9e9, #e9e9e9);
                    border-radius: 20px 0 0 20px;

                    > .title__ppal,
                    > .subtitle__ppal {
                        padding: 0 40px;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }

                    > .subtitle__ppal {
                        margin-top: 30px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    > .list__items-home {
                        padding: 5px 40px;
                        margin-top: -5px;
                        > .destination__item {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    &:hover {
                        @include scroll(#e9e9e9, rgba(0, 0, 0, 0.2));
                    }
                }

                > .lnt__message {
                    display: none;
                }
            }
            &-comments{
                width: 400px;
            }
        }
    }
    @media (min-width: 1101px) and (max-width: 1300px) {
        &__sidebar {
            width: 600px;
            flex: 1 0 auto;
            height: 100%;
            padding-top: 80px;
            margin-left: -20px;
            border-radius: 20px 0 0 20px;
            &-depto {
                width: calc(100% - (420px + 280px));
                padding-top: 30px;
            }
            &-extra {
                margin-left: 0;
                width: 420px;
                padding-top: 70px;
                &-caption {
                    padding: 30px 0 40px;
                    height: 100%;
                    overflow: auto;
                    border-radius: 20px 0 0 20px;

                    > .title__ppal,
                    > .subtitle__ppal {
                        padding: 0 40px;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }

                    > .subtitle__ppal {
                        margin-top: 30px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    > .list__items-home {
                        padding: 5px 40px;
                        margin-top: -5px;
                        > .destination__item {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                > .lnt__message {
                    display: none;
                }
            }
        }
    }
    @media (min-width: 1101px) {
        &__gallery {
            .report-problem {
                right: 175px;
                bottom: 15px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1100px) {
        &__gallery {
            .report-problem {
                width: 40px;
                right: 175px;
                bottom: 15px;
                > [class*="fa-"]{
                    margin-right: 0;
                }
                > span {
                    display: none;
                }
            }
        }
        &__sidebar {
            padding-top: 80px;
            width: 50%;
            flex: 1 0 auto;
            height: 100%;
            margin-left: -20px;
            border-radius: 20px 0 0 20px;
            &-depto {
                width: calc(100% - (280px + 280px));
                padding-top: 65px;
                .dest__sidebar-caption {
                    &-resume {
                        > .title__ppal,
                        > .text__enr {
                            padding: 0 40px;
                        }
                    }
                    > .place__info {
                        padding: 0 40px 30px;
                        grid-template-columns: repeat(1, 1fr);
                    }
                    &-extra {
                        > .depto__capital {
                            width: calc(100% - (40px * 2));
                            margin-left: 40px;
                        }
                        .ant-tabs-nav-list {
                            padding: 0 40px !important;
                        }
                    }
                }

                .lnt__message {
                    flex-direction: column;
                    align-items: flex-start;
                    &-image {
                        margin-bottom: 20px;
                    }
                    &-content {
                        border-left: none;
                        padding-left: 0;
                    }
                }
            }
            &-extra {
                width: 300px;
                padding-top: 70px;
                &-caption {
                    padding: 30px 0 40px;
                    height: 100%;
                    overflow: auto;
                    border-radius: 20px 0 0 20px;

                    > .title__ppal,
                    > .subtitle__ppal {
                        padding: 0 40px;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }

                    > .subtitle__ppal {
                        margin-top: 30px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    > .list__items-home {
                        padding: 5px 40px;
                        margin-top: -5px;
                        > .destination__item {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                > .lnt__message {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 860px) {
        &__gallery {
            .report-problem {
                width: 40px;
                left: 30px;
                padding: 0;
                bottom: 30px;
                > [class*="fa-"]{
                    margin-right: 0;
                }
                > span {
                    display: none;
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &__gallery {
            height: 500px;
            flex: 1 0 auto;
            &-depto {
                height: 300px;
            }
        }
        &__sidebar {
            width: 100%;
            height: max-content;
            z-index: 5;
            border-radius: 20px 20px 0 0;
            padding-top: 50px;
            padding-bottom: 20px;
            margin-top: -20px;
            margin-bottom: -20px;
            &-depto {
                .lnt__message {
                    display: none;
                }
            }
            &-extra {
                margin-top: 0;
                border-radius: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                &-caption {
                    background-color: $c-wh;
                    padding: 40px 0;
                    > .title__ppal,
                    > .subtitle__ppal {
                        padding: 0 50px;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }

                    > .subtitle__ppal {
                        margin-top: 30px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    > .list__items-home {
                        padding: 5px 50px;
                        margin-top: -5px;
                        > .destination__item {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        &__gallery {
            height: 350px;
            flex: 1 0 auto;
            &-depto {
                height: 200px;
            }
        }
        &__sidebar {
            width: 100%;
            height: max-content;
            z-index: 5;
            border-radius: 20px 20px 0 0;
            padding-bottom: 20px;
            margin-top: -20px;
            margin-bottom: -20px;
            &-depto {
                .lnt__message {
                    display: none;
                }
            }
            &-extra {
                margin-top: 0;
                border-radius: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                z-index: 1;
                &-caption {
                    background-color: $c-wh;
                    padding: 35px 0;
                    > .title__ppal,
                    > .subtitle__ppal {
                        padding: 0 35px;
                        margin-bottom: 15px;
                        font-weight: 500;
                    }

                    > .subtitle__ppal {
                        margin-top: 40px;
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                    > .list__items-home {
                        padding: 5px 35px;
                        margin-top: -5px;
                        > .destination__item {
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
