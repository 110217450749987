@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.collapse {
    &__default {
        border: none;
        padding: 10px;
        background-color: $c-wh;
        border-radius: 15px;
        box-shadow: 8px 8px 0 0 $c-1, 12px 12px 25px 0 rgba(0, 0, 0, 0.3);

        > .ant-collapse {
            &-item {
                border: none;

                &-active {
                    .ant-collapse {
                        &-header {
                            border-radius: 10px 10px 0 0 !important;
                            background-color: #f3f3f3;
                        }
                    }
                }

                .ant-collapse {
                    &-header {
                        font-size: 17px;
                        font-family: $f-ppal;
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        > .ant-collapse-arrow {
                            top: auto;
                            padding: 10px;
                            background-color: #f3f3f3;
                            border-radius: 5px;
                            position: relative;
                            left: auto;
                            margin-right: 15px;
                        }
                    }
                    &-content {
                        border: none;
                        background-color: transparent;
                        padding: 2px 2px 2px 0;
                        border: 5px solid #f3f3f3;
                        &-box {
                            border-radius: 0 0 10px 10px;
                            max-height: 200px;
                            padding: 10px 10px 10px;
                            overflow: auto;
                            @include scroll($c-wh, rgba(0, 0, 0, 0.2));
                            > .text__enr {
                                > p {
                                    font-size: 14px;
                                    line-height: 1.5;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__trips {
        &-guest {
            border: none;
            background-color: transparent;

            .ant-collapse {
                &-item {
                    border: 1px solid $c-gr-2;
                    border-radius: 10px !important;
                    margin-bottom: 10px;
                    &-active {
                        .ant-collapse {
                            &-header {
                                .collapse__trips-guest {
                                    &-icon {
                                        > [class*="fa-"]{
                                            color: $c-1;
                                            font-weight: 600;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &-header {
                    background-color: transparent;
                    border-radius: 0 !important;
                    padding: 15px !important;
                    display: flex;

                    .collapse__trips-guest {
                        &-icon {
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $c-gr-2;
                            border-radius: 5px;
                            margin-right: 15px;
                            transition: all .2s;
                            flex: 1 0 auto;
                            > [class*="fa-"] {
                                font-size: 20px;
                                color: $c-dr;
                                opacity: 0.6;
                            }
                        }
                        &-description{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            .trips-guest{
                                &--host{
                                    color: $c-dr;
                                    opacity: .6;
                                    font-size: 14px;
                                    line-height: normal;
                                }
                                &--name{
                                    font-size: 20px;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                    > .anticon {
                        display: none !important;
                    }
                }
                &-content {
                    background-color: $c-gr-2;
                    border-top: none;
                    &-box {
                        display: flex;

                        > .ant-btn {
                            height: 45px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
