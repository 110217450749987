@import "assets/sass/variables";
@import "assets/sass/mixins";

.notFound {
    display: flex;
    height: 100%;
    &-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 200px;
        .title__ppal {
            margin-bottom: 20px;
            font-size: 70px;
            line-height: 1;
        }
        .subtitle__ppal {
            font-size: 30px;
            font-weight: 200;
            margin-bottom: 20px;
        }
        .text__enr {
            margin-bottom: 20px;
        }
    }
    &-image {
        width: calc(50% + 500px);
        margin-right: -100px;
        background-color: #e7edf4;
        display: flex;
        position: relative;
        box-shadow: 0 0 0 10px $c-wh, 0 0 0 12px $c-3, 20px 30px 50px 0 rgba(0, 0, 0, 0.15);
        animation: borderBubble 15s ease-in-out alternate infinite;

        > svg {
            width: 600px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (max-width: 720px) {
        flex-direction: column-reverse;
        &-image {
            width: 80%;
            height: 300px;
            margin-bottom: 20px;
            > svg {
                width: 100%;
            }
        }
        &-description {
            padding-right: 0;
            .title__ppal{
                font-size: 45px;
            }
            .subtitle__ppal {
                font-size: 20px;
            }
            .title__ppal,
            .subtitle__ppal,
            .text__enr {
                margin-bottom: 15px;
            }
        }
    }
}

@keyframes borderBubble {
    0% {
        border-radius: 66% 34% 35% 65% / 40% 40% 60% 60%;
    }
    25% {
        border-radius: 29% 71% 22% 78% / 78% 78% 22% 22%;
    }
    50% {
        border-radius: 89% 11% 61% 39% / 57% 49% 51% 43%;
    }
    75% {
        border-radius: 23% 77% 16% 84% / 57% 26% 74% 43%;
    }
    100% {
        border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
    }
}
