@import "../variables";
@import "../maths/trigonometry";

@keyframes microAction {
	0%,
	17.5% {
		font-size: 0;
	}
}
$bubble-d: 3rem; // bubble diameter
$bubble-r: .5 * $bubble-d; // bubble-radius
$particle-d: 0.375rem;
$particle-r: 0.5 * $particle-d;
@mixin particles($k) {
	$shadow-list: ();
	$n-groups: 7;
	$group-base-angle: 360deg / $n-groups;
	$group-distr-r: (1 + $k * 0.25) * $bubble-r;
	$n-particles: 2;
	$particle-base-angle: 360deg / $n-particles;
	$particle-off-angle: 60deg; // offset angle from radius
	$spread-r: -$k * 1.1 * $particle-r;

	@for $i from 0 to $n-groups {
		$group-curr-angle: $i * $group-base-angle - 90deg;
		$xg: $group-distr-r * cos($group-curr-angle);
		$yg: $group-distr-r * sin($group-curr-angle);

		@for $j from 0 to $n-particles {
			$particle-curr-angle: $group-curr-angle + $particle-off-angle + $j * $particle-base-angle;
			$xs: $xg + $particle-d * cos($particle-curr-angle);
			$ys: $yg + $particle-d * sin($particle-curr-angle);

			$shadow-list: $shadow-list, $xs $ys 0 $spread-r $c-3;
			// $shadow-list: $shadow-list, $xs $ys 0 $spread-r hsl(($i + $j)*$group-base-angle, 100%, 75%);
		}
	}

	box-shadow: $shadow-list;
}

@mixin bubble($ext) {
	transform: scale(1);
	border-color: $c-gr;
	border-width: $ext;
}

.dest__sidebar-interactions {
	&-visited {
		align-self: center;
		position: relative;
		user-select: none;
		cursor: pointer;

		width: 100%;
		height: 100%;

		> [class*="fa-"] {
			transform: scale(1);
			transition: all .2s;
		}
		&::before,
		&::after {
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			border-radius: 50%;
			content: "";
		}
		&::before {
			box-sizing: border-box;
			margin: -$bubble-r;
			border: solid $bubble-r $c-1;
			width: $bubble-d;
			height: $bubble-d;
			transform: scale(0);
		}
		&::after {
			margin: -$particle-r;
			width: $particle-d;
			height: $particle-d;
			@include particles(1);
		}
		&.active {
			box-shadow: inset 0 0 0 0 $c-wh;
			will-change: font-size;
			animation: microAction 1s cubic-bezier(0, 0.89, 0.32, 1.49);

			> [class*="fa-"] {
				color: $c-1;
				transform: scale(1.2);
			}
			&::before,
			&::after {
				animation: inherit;
				animation-timing-function: ease-out;
			}
			&::before {
				will-change: transform, border-width, border-color;
				animation-name: bubble;
			}
			&::after {
				will-change: opacity, box-shadow;
				animation-name: particles;
			}
		}
	}
}

@keyframes microAction {
	0%,
	17.5% {
		font-size: 0;
	}
}

@keyframes bubble {
	15% {
		@include bubble($bubble-r);
	}
	30%,
	100% {
		@include bubble(0);
	}
}

@keyframes particles {
	0%,
	20% {
		opacity: 0;
	}
	25% {
		opacity: 1;
		@include particles(0);
	}
}
