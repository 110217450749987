@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.page-container {
    width: 100%;
    height: 100%;
    background-color: $c-wh;

    &.flex {
        &-row {
            display: flex;
            overflow: hidden;

            @media (min-width: 861px) {
                > .header__ppal {
                    .container {
                        &__left {
                            > .logo {
                                > svg {
                                    filter: drop-shadow(0 0 9px rgba(0, 0, 0, .3));
                                }
                            }
                        }
                    }
                }
                > .footer__ppal {
                    display: none;
                }
            }
            @media (max-width: 860px) {
                height: auto;
                flex-direction: column;
                > .footer__ppal {
                    display: flex;
                }
            }
        }
        &-destination {
            display: flex;
            overflow: hidden;

            @media (min-width: 861px) {
                > .header__ppal {
                    .container {
                        &__left {
                            > .logo {
                                > svg {
                                    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
                                }
                            }
                        }
                    }
                }
                > .footer__ppal {
                    display: none;
                }
            }
            @media (max-width: 860px) {
                overflow: hidden auto;
                flex-direction: column;
                > .footer__ppal {
                    display: flex;
                }
            }
        }
        &-column {
            flex-direction: column;
            overflow: auto;
            > .footer__ppal {
                display: flex;
            }
        }
        &-profile,
        &-column {
            @media (min-width: 861px) {
                > .header__ppal {
                    .container {
                        &__left {
                            > .logo {
                                > svg {
                                    filter: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-profile {
            > .footer__ppal {
                display: none;
            }
        }
    }

    // container
    .container {
        margin: 0 auto;
        position: relative;

        @media (min-width: 1061px) {
            width: 1050px;
        }
        @media (max-width: 1060px) {
            width: 100%;
        }
    }

    // section default
    .sec {
        &__default {
            &-basic {
                position: relative;
            }
            &-compact {
                .container {
                    max-width: 700px;
                    &-compact {
                        max-width: 500px;
                        margin: 0 auto;
                    }
                }
            }
            &-full {
                width: 100%;
                margin: 0 !important;
                padding: 100px 0;
                > .container {
                    height: 100%;
                }
            }
            @media (min-width: 1301px) {
                $spacing: 100px;

                margin: $spacing 0;
                &-basic {
                    &:first-of-type {
                        min-height: calc(100vh - 300px);
                    }
                    > .container {
                        .title__ppal {
                            margin-bottom: 30px;
                        }
                        > .ant-typography {
                            margin-top: -25px;
                            margin-bottom: 20px;
                        }
                        > .filters__category {
                            margin: 15px 0 30px;
                        }
                    }
                }
            }
            @media (min-width: 861px) and (max-width: 1300px) {
                $spacing: 60px;

                margin: $spacing 0;
                &-basic {
                    &:first-of-type {
                        margin-top: 100px;
                        min-height: calc(100vh - 300px);
                    }
                    > .container {
                        padding: 0 $spacing;
                        > .filters__category {
                            margin: 15px 0 20px;
                        }
                        > .title__ppal,
                        > .title__with-extra > .title__ppal {
                            &.sm {
                                margin-bottom: 10px;
                            }
                            &.md {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
            @media (min-width: 721px) and (max-width: 860px) {
                $spacing: 80px;

                margin: $spacing 0;
                &-basic {
                    &:first-of-type {
                        min-height: calc(100vh - 645px);
                    }
                    > .container {
                        padding: 0 $spacing;
                        > .filters__category {
                            width: calc(100% + 80px * 2);
                            margin-top: 15px;
                            margin-left: -$spacing;
                            margin-bottom: 20px;
                            padding: 0 $spacing;
                        }
                        > .title__ppal,
                        > .title__with-extra > .title__ppal {
                            &.sm {
                                margin-bottom: 10px;
                            }
                            &.md {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            @media (max-width: 720px) {
                $spacing: 35px;

                margin: $spacing 0;
                &-basic {
                    &:first-of-type {
                        margin-top: 35px;
                        min-height: calc(100vh - 70px);
                    }
                    > .container {
                        padding: 0 $spacing;
                        > .filters__category {
                            width: calc(100% + 35px * 2);
                            margin-top: 15px;
                            margin-left: -$spacing;
                            margin-bottom: 10px;
                            padding: 0 $spacing;
                            &::after {
                                width: 0;
                            }
                        }
                        > .title__ppal,
                        > .title__with-extra > .title__ppal {
                            &.sm {
                                margin-bottom: 5px;
                            }
                            &.md {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                &-full {
                    padding: 35px;
                }
            }
        }

        &__keepgoing {
            width: 100%;
            position: relative;
            @media (min-width: 1301px) {
                $s-header: 70px;
                height: calc(100% - 70px);
                margin-top: $s-header;
            }
        }
    }

    // header
    &.header-active {
        > .header__ppal {
            &.active-bg {
                background-color: $c-wh;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                > .container {
                    &__left {
                        > .logo {
                            > svg {
                                > path {
                                    fill: $c-1;
                                }
                            }
                            > span {
                                color: $c-dr;
                                opacity: 0.7;
                            }
                        }
                    }
                    &__center {
                        box-shadow: none;
                    }
                    &__right {
                        > .dropdown {
                            &__default,
                            &__deptos {
                                box-shadow: none;
                            }
                        }
                        > .shopping__cart {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    // no background
    &.no-bg {
        > .header__ppal {
            > .container {
                &__right {
                    > .access__profile {
                        border-color: rgba(0, 0, 0, 0.1);
                    }
                }
                &__left {
                    > .logo {
                        > svg {
                            > path {
                                fill: $c-1;
                            }
                        }
                        > span {
                            color: $c-dr;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}
