@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.home__container {
    background-color: $c-wh;
    z-index: 1;
    &-inner {
        // title ppal
        > .title__ppal {
            font-weight: 700;
        }
        // tabs
        > .tabs__home {
            .ant-tabs {
                &-nav {
                    &::before {
                        display: none;
                    }
                    &-wrap {
                        > .ant-tabs-nav-list {
                            &::after {
                                content: "";
                                height: 1px;
                                display: block;
                                flex: 1 0 auto;
                            }
                            .ant-tabs {
                                &-tab {
                                    margin-right: 25px;
                                    padding: 0;
                                    &-btn {
                                        > a {
                                            color: $c-dr;
                                            font-weight: 400;
                                            font-size: 15px;
                                            font-family: $f-second;
                                            padding-bottom: 10px;
                                            display: block;
                                        }
                                    }
                                    &-active {
                                        > .ant-tabs-tab-btn {
                                            > a {
                                                color: $c-1;
                                            }
                                        }
                                    }

                                    &:hover {
                                        color: $c-1;
                                    }
                                }
                                &-ink-bar {
                                    width: 30px !important;
                                    height: 1.5px;
                                    background: $c-1;
                                }
                            }
                        }
                    }
                    &-operations {
                        display: none;
                    }
                }
            }
        }
    }

    @media (min-width: 1301px) {
        $spacing: 80px;

        width: 600px;
        height: 100%;
        padding: 80px 0 0 0;
        margin-left: -20px;
        border-radius: 20px 0 0 20px;
        flex: 1 0 auto;
        &-inner {
            height: 100%;
            padding: 30px 0 $spacing;
            overflow: hidden auto;
            @include scroll($c-wh, rgba(0, 0, 0, 0.3));
            > .title__ppal,
            > .subtitle__ppal {
                padding: 0 $spacing;
                line-height: 1;
            }

            > .subtitle__ppal {
                margin-top: 40px;
                margin-bottom: 10px;
                font-weight: 700;
            }

            .swipe {
                margin-left: $spacing;
                margin-top: 15px;
                opacity: 0.8;
            }

            &--search {
                margin: 30px 0 10px;
                padding: 0 $spacing;
                > .search {
                    &__big {
                        height: 55px;
                    }
                }
            }

            > .tabs__home {
                .ant-tabs {
                    &-nav {
                        margin: 25px 0 15px;
                        &-wrap {
                            > .ant-tabs-nav-list {
                                padding-left: $spacing;
                                > .ant-tabs-tab {
                                    &:nth-last-child(2) {
                                        margin-right: 0;
                                    }
                                }
                                &::after {
                                    width: $spacing;
                                }
                            }
                        }
                    }
                    &-content-holder {
                        > .ant-tabs-content {
                            .ant-tabs-tabpane {
                                > .list__items-home,
                                > .ant-skeleton,
                                &-actions {
                                    padding: 5px $spacing 0;
                                }
                                > .list__items-home {
                                    transform: scale(1);
                                    scroll-behavior: smooth;
                                    > .destination__item {
                                        margin-right: 15px;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }

                                &-actions {
                                    display: flex;
                                    margin-top: 15px;
                                    > .ant-btn {
                                        margin: 0 5px 0 0;
                                        box-shadow: none;
                                        &:hover {
                                            background-color: $c-2;
                                            color: $c-wh;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        $spacing: 60px;

        width: 600px;
        height: 100%;
        padding: 80px 0 0 0;
        margin-left: -20px;
        border-radius: 20px 0 0 20px;
        flex: 1 0 auto;

        &-inner {
            height: 100%;
            padding: 30px 0 $spacing;
            overflow: hidden auto;
            @include scroll($c-wh, rgba(0, 0, 0, 0.3));
            > .title__ppal,
            > .subtitle__ppal {
                padding: 0 $spacing;
                line-height: 1;
            }

            > .subtitle__ppal {
                margin-top: 40px;
                margin-bottom: 15px;
                font-weight: 700;
            }

            .swipe {
                margin-left: $spacing;
                margin-top: 15px;
                opacity: 0.8;
            }

            &--search {
                margin: 30px 0 10px;
                padding: 0 $spacing;
                > .search {
                    &__big {
                        height: 55px;
                    }
                }
            }

            > .tabs__home {
                .ant-tabs {
                    &-nav {
                        margin: 20px 0 15px;
                        &-wrap {
                            > .ant-tabs-nav-list {
                                padding-left: $spacing;
                                > .ant-tabs-tab {
                                    &:nth-last-child(2) {
                                        margin-right: 0;
                                    }
                                }
                                &::after {
                                    width: $spacing;
                                }
                            }
                        }
                    }
                    &-content-holder {
                        > .ant-tabs-content {
                            .ant-tabs-tabpane {
                                > .list__items-home,
                                > .ant-skeleton,
                                &-actions {
                                    padding: 5px $spacing 0;
                                }
                                > .list__items-home {
                                    scroll-behavior: smooth;
                                    > .destination__item {
                                        margin-right: 12px;
                                        &:last-child {
                                            margin-right: $spacing;
                                        }
                                    }
                                }

                                &-actions {
                                    display: flex;
                                    margin-top: 15px;
                                    > .ant-btn {
                                        margin: 0 5px 0 0;
                                        box-shadow: none;
                                        &:hover {
                                            background-color: $c-2;
                                            color: $c-wh;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 80px;

        width: 100%;
        height: max-content;
        overflow: visible;

        &-inner {
            height: 100%;
            padding: 100px 0 80px;
            display: block;
            overflow: auto;
            > .title__ppal,
            > .subtitle__ppal {
                padding: 0 $spacing;
                line-height: 1;
            }

            > .subtitle__ppal {
                margin-top: 40px;
                margin-bottom: 15px;
                font-weight: 700;
            }

            .swipe {
                margin-left: $spacing;
                margin-top: 15px;
                opacity: 0.4;
            }

            &--search {
                margin: 20px 0 30px;
                padding: 0 $spacing;
                > .search {
                    &__big {
                        height: 70px;
                    }
                }
            }

            > .tabs__home {
                .ant-tabs {
                    &-nav {
                        margin: 20px 0 15px;
                        &-wrap {
                            > .ant-tabs-nav-list {
                                padding-left: $spacing;
                                > .ant-tabs-tab {
                                    &:nth-last-child(2) {
                                        margin-right: 0;
                                    }
                                }
                                &::after {
                                    width: $spacing;
                                }
                            }
                        }
                    }
                    &-content-holder {
                        > .ant-tabs-content {
                            .ant-tabs-tabpane {
                                > .list__items-home,
                                > .ant-skeleton {
                                    padding: 5px $spacing 0;
                                }
                                > .list__items-home {
                                    > .destination__item {
                                        margin-right: 15px;
                                        &:last-child {
                                            margin-right: $spacing;
                                        }
                                    }
                                }
                                &-actions {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        $spacing: 35px;

        width: 100%;
        height: max-content;
        overflow: visible;

        &-inner {
            height: 100%;
            padding: $spacing 0 $spacing;
            display: block;
            overflow: auto;
            > .title__ppal,
            > .subtitle__ppal {
                padding: 0 $spacing;
                line-height: 1;
            }

            > .subtitle__ppal {
                margin-top: 40px;
                margin-bottom: 15px;
                font-weight: 700;
            }

            .swipe {
                margin-left: $spacing;
                margin-top: 15px;
                opacity: 0.4;
            }

            &--search {
                margin: 30px 0 10px;
                padding: 0 $spacing;
                > .search {
                    &__big {
                        height: 55px;
                    }
                }
            }

            > .tabs__home {
                .ant-tabs {
                    &-nav {
                        margin: 20px 0 15px;
                        &-wrap {
                            > .ant-tabs-nav-list {
                                padding-left: $spacing;
                                > .ant-tabs-tab {
                                    &:nth-last-child(2) {
                                        margin-right: 0;
                                    }
                                }
                                &::after {
                                    width: $spacing;
                                }
                            }
                        }
                    }
                    &-content-holder {
                        > .ant-tabs-content {
                            .ant-tabs-tabpane {
                                > .list__items-home,
                                > .ant-skeleton {
                                    padding: 5px $spacing 0;
                                }
                                > .list__items-home {
                                    > .destination__item {
                                        margin-right: 15px;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                                &-actions {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.list__items-activities {
    padding: 5px 0 15px !important;
    > li {
        width: auto;
        height: auto;
        position: relative;
        cursor: pointer;
        flex: 0 0 auto;
        display: flex;
        background-color: $c-1;
        border-radius: 10px;

        > .activity__container {
            width: 100%;
            height: 100%;
            padding: 8px 20px 8px 8px;
            border-radius: 8px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $c-wh;
            overflow: hidden;
            top: 0;
            left: 0;
            transition: all 0.2s;
            box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.1);

            > .icon {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 17px;
                margin-right: 15px;
                color: $c-1;
                border-radius: 5px;
                background-color: $c-gr;
                transition: all 0.2s;
            }
            > span {
                font-size: 13.5px;
                font-family: $f-second;
                font-weight: 400;
            }
        }

        &:hover {
            > .activity__container {
                margin-left: -3px;
                margin-top: -3px;
                margin-right: 3px;
                margin-bottom: 3px;
            }
        }
    }

    @media (min-width: 1401px) {
        $spacing: 80px;

        padding-left: $spacing !important;
        padding-right: $spacing !important;
        flex-wrap: wrap !important;
        margin-top: -10px;

        > li {
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
                margin-right: $spacing;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1400px) {
        $spacing: 60px;

        padding-left: $spacing !important;
        padding-right: $spacing !important;
        flex-wrap: wrap !important;
        margin-top: -10px;

        > li {
            margin-right: 15px;
            margin-top: 10px;
            &:last-child {
                margin-right: $spacing;
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 80px;
        padding-left: $spacing !important;
        padding-right: $spacing !important;
        flex-wrap: wrap !important;
        margin-top: -15px;
        > li {
            margin-right: 15px;
            margin-top: 15px;
            &:last-child {
                margin-right: $spacing;
            }
        }
    }
    @media (max-width: 720px) {
        $spacing: 35px;

        padding-left: $spacing !important;
        padding-right: $spacing !important;
        flex-wrap: wrap !important;
        margin-top: -10px;
        > li {
            margin-right: 10px;
            margin-top: 10px;
            &:last-child {
                margin-right: $spacing;
            }
        }
    }
}

.swipe {
    display: flex;
    flex-direction: column;
    height: 17px;
    > span {
        font-size: 15px;
        margin-right: 10px;
    }
    &-hand {
        width: 100px;
        min-height: 15px;
        max-height: 15px;
        position: relative;
        border: 1px solid #dddddd;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            font-size: 10px;
            opacity: 0.5;
        }

        > [class*="fa"] {
            color: #5c5c5c;
            font-size: 17px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            animation: dragIcon 3.8s cubic-bezier(0.57, -0.04, 0.58, 1) infinite both;
        }
    }
    &-help {
        width: 100px;
        text-align: center;
        font-size: 11px;
        margin-top: 5px;
        color: $c-dr;
        letter-spacing: 2px;
        opacity: 0.7;
    }
    @media (max-width: 500px) {
        opacity: 0.3;
    }
}

@keyframes dragIcon {
    0% {
        left: 10px;
    }
    25% {
        left: 50px;
    }
    50% {
        left: 40px;
    }
    75% {
        left: 75px;
    }
    100% {
        left: 10px;
    }
}
