@import "assets/sass/_variables";

.dashboard__profile {
    width: 100%;
    &-content {
        height: 100%;
        overflow: auto;
        .container {
            .ant-btn-add-new {
                position: fixed;
                border-radius: 100%;
                padding: 0;
                z-index: 10;

                > [class*="fa-"] {
                    margin-right: 0;
                }
            }
        }
    }
    @media (min-width: 1201px) {
        padding-top: 70px;
        &-content {
            padding: 50px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            > .container {
                width: 900px;
                height: auto;
                > .filters__category {
                    margin-top: 15px;
                    margin-bottom: 25px;
                }
                > .resume__dashboard,
                > .empty__data{
                    margin-top: 25px;
                }
                .ant-btn-add-new {
                    width: 80px;
                    height: 80px;
                    bottom: 50px;
                    padding-top: 5px;
                    right: 50px;
                    > [class*="fa-"] {
                        font-size: 35px;
                    }
                }
            }
        }
    }
    @media (min-width: 1051px) and (max-width: 1200px) {
        padding-top: 70px;
        overflow: hidden;
        &-content {
            padding: 50px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            > .container {
                width: 100%;
                height: auto;
                > .filters__category {
                    margin-top: 15px;
                    margin-bottom: 25px;
                }
                > .resume__dashboard,
                > .empty__data{
                    margin-top: 25px;
                }
                .ant-btn-add-new {
                    width: 80px;
                    height: 80px;
                    bottom: 50px;
                    padding-top: 5px;
                    right: 50px;
                    > [class*="fa-"] {
                        font-size: 35px;
                    }
                }
                .list__items-mosaic-card {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1050px) {
        padding-top: 70px;
        overflow: hidden;
        &-content {
            padding: 50px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            > .container {
                width: 100%;
                height: auto;
                > .filters__category {
                    width: calc(100% + 100px);
                    margin-left: -50px;
                    padding: 0 50px;
                    margin-top: 10px;
                    margin-bottom: 25px;
                }
                > .resume__dashboard,
                > .empty__data{
                    margin-top: 25px;
                }
                .ant-btn-add-new {
                    width: 80px;
                    height: 80px;
                    bottom: 50px;
                    padding-top: 5px;
                    right: 50px;
                    > [class*="fa-"] {
                        font-size: 35px;
                    }
                }
                .list__items-mosaic-card {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        &-content {
            padding: 100px 80px 80px;
            > .container {
                > .filters__category {
                    width: calc(100% + 160px);
                    margin-left: -80px;
                    padding: 0 80px;
                    margin-top: 15px;
                    margin-bottom: 20px;
                }
                > .resume__dashboard,
                > .empty__data{
                    margin-top: 20px;
                }
                .ant-btn-add-new {
                    width: 70px;
                    height: 70px;
                    bottom: 50px;
                    padding-top: 5px;
                    right: 50px;
                    > [class*="fa-"] {
                        font-size: 35px;
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        &-content {
            height: auto;
            min-height: calc(100vh - 60px);
            padding: 35px 35px 120px;
            > .container {
                > .filters__category {
                    width: calc(100% + 70px);
                    margin-left: -35px;
                    padding: 0 0 0 35px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                > .resume__dashboard,
                > .empty__data{
                    margin-top: 15px;
                }
                .ant-btn-add-new {
                    width: 60px;
                    height: 60px;
                    bottom: 80px;
                    right: 35px;
                    padding-top: 5px;
                    > [class*="fa-"] {
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

.current__photo {
    width: 100px;
    height: 100px;
    margin: 10px auto 30px;
    border-radius: 100%;
    border: 1px solid $c-gr-2;
    background-color: $c-3;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-family: $f-ppal;
    font-weight: 200;
    color: $c-wh;
    > .ant-btn {
        position: absolute;
        width: 32px;
        height: 32px;
        right: -5px;
        bottom: -5px;
        box-shadow: 0 0 0 3px $c-wh !important;
        > [class*="fa-"] {
            font-size: 14px;
        }
    }
    > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
    }
}
