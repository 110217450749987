@import 'assets/sass/variables';

.add-review-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__score-container {
    display: flex;
    align-items: center;
  }

  &__score-value {
    height: auto;
    margin-top: -35px;
    border: 1px solid $c-3;
    padding: 5px 18px;
    border-radius: 6px;
  }

  &__buttons {
    .ant-form-item-control-input-content {
      display: flex;
    }
  }
}