@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.drawer {
    &__default {
        .ant-drawer {
            &-content {
                border-radius: 20px 0 0 20px;
            }
            &-header,
            &-footer {
                border: none;
            }
            &-header {
                padding: 20px 40px;
                > .ant-drawer-title {
                    font-family: $f-ppal;
                    font-size: 28px;
                    line-height: 1.2;
                }
            }
            &-body {
                padding: 10px 40px 20px;
            }
            &-footer {
                padding: 20px 40px;
                display: flex;
                justify-content: flex-end;
                > .ant-btn {
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &-close {
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background-color: rgba(255, 255, 255, 0.5);
                line-height: normal;
                transition: all 0.2s;
                color: $c-dr;
                background-color: $c-gr-2;
                top: 10px;
                right: 10px;
            }
        }
        // modificators
        &-comments {
            .ant-drawer {
                &-header {
                    padding: 30px 40px 0;
                }
                &-body {
                    padding: 30px 40px;
                }
            }
        }
        &-provider {
            left: auto;
            right: 0;

            .ant-drawer {
                &-close {
                    display: none;
                }
                &-header {
                    position: relative;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                    .ant-btn-close {
                        position: absolute;
                        height: 40px;
                        width: 40px;
                        right: 15px;
                        bottom: 20px;
                        border: none;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $c-gr-2;
                        > [class*="fa-"] {
                            margin-right: 0;
                            font-size: 25px;
                            color: $c-dr;
                            opacity: 0.5;
                        }
                    }
                    .title__ppal {
                        display: flex;
                        flex-direction: column;
                        > span {
                            font-size: 13px;
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            opacity: 0.5;
                            margin-bottom: -2px;
                        }
                    }
                }
                &-body {
                    padding: 0;
                }
                &-footer {
                    display: flex;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                    &-price {
                        flex: 1 0 auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: $c-dr;
                        > strong {
                            font-family: $f-second;
                            text-transform: uppercase;
                            letter-spacing: 2.5px;
                            font-size: 10px;
                            opacity: 0.8;
                            font-weight: 400;
                        }
                        > span {
                            margin-top: 1px;
                            font-family: $f-ppal;
                            font-size: 25px;
                            font-weight: 400;
                            text-transform: uppercase;
                            line-height: 1;
                            &::first-letter {
                                opacity: 0.5;
                                margin-right: 3px;
                            }

                            > sub{
                                font-size: 13px;
                                top: -2px;
                                margin-left: 5px;
                                letter-spacing: 2px;
                                font-weight: 300;
                            }
                        }
                    }
                    &-actions {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        > .ant-btn {
                            margin-right: 5px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            @media (min-width: 1101px) {
                width: 600px;
                .ant-drawer {
                    &-header {
                        padding: 70px 80px 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                    }
                    &-body {
                        height: 100%;
                    }
                    &-footer {
                        padding: 0 40px 0 0;
                        &-price {
                            padding: 0 40px;
                        }
                        &-actions {
                            padding: 15px 0;
                        }
                    }
                }
            }
            @media (min-width: 861px) and (max-width: 1100px) {
                width: 50%;
                .ant-drawer {
                    &-header {
                        padding: 70px 80px 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                    }
                    &-body {
                        padding: 0;
                        height: 100%;
                    }
                }
            }
            @media (min-width: 721px) and (max-width: 860px) {
                width: 100%;
                .ant-drawer {
                    &-content{
                        border-radius: 0;
                    }
                    &-header {
                        padding: 10px 50px;
                    }
                    &-body {
                        padding: 0;
                        height: 100%;
                    }
                }
            }
            @media (max-width: 720px) {
                width: 100%;
                .ant-drawer {
                    &-header {
                        padding: 10px 35px !important;
                        .ant-btn-close {
                            bottom: 15px;
                            > [class*="fa-"] {
                                font-size: 20px;
                            }
                        }
                        &-no-title {
                            height: 0px;
                            flex: 1 0 auto;
                        }
                    }
                    &-body {
                        padding: 0 !important;
                        height: 100%;
                    }
                }
            }
        }
        @media (max-width: 720px) {
            .ant-drawer {
                &-content {
                    border-radius: 0;
                    &-wrapper {
                        width: 100% !important;
                    }
                }
                &-header {
                    padding: 20px 35px 20px;
                    .ant-drawer-title {
                        font-size: 25px;
                    }
                }
                &-body {
                    padding: 10px 35px;
                }
                &-footer {
                    padding: 10px 15px;
                    &-price {
                        padding: 0 15px;
                        > span{
                            > sub{
                                margin-left: 2px;
                                letter-spacing: 1px;
                                font-size: 11px;
                            }
                        }
                    }
                    &-actions {
                        padding: 10px 0;
                    }
                }
            }

            // modificators
            &-comments {
                .ant-drawer {
                    &-header {
                        padding: 20px 35px 5px;
                    }
                    &-body {
                        padding: 20px 35px 35px;
                    }
                }
            }
        }
    }
    &__login {
        .ant-drawer {
            &-wrapper {
                &-body {
                    .ant-drawer {
                        &-body {
                            width: 100%;
                            height: 100%;
                            @include scroll($c-wh, rgba(0, 0, 0, 0.2));
                        }
                    }
                }
            }
        }
        @media (min-width: 721px) {
            .ant-drawer {
                &-wrapper {
                    &-body {
                        .ant-drawer {
                            &-body {
                                padding: 30px 44px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 720px) {
            .ant-drawer {
                &-wrapper {
                    &-body {
                        .ant-drawer {
                            &-body {
                                padding: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
    &__search {
        .ant-drawer {
            &-content {
                &-wrapper {
                    box-shadow: none !important;
                }
            }
            &-wrapper {
                &-body {
                    .ant-drawer {
                        &-header {
                            padding-bottom: 30px;
                            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                            .search__big {
                                height: 55px;
                                margin-top: 20px;
                            }
                        }
                        &-body {
                            padding-top: 30px;
                            padding-bottom: 50px;
                            .subtitle__ppal {
                                margin-top: 30px;
                                margin-bottom: 25px;
                                font-weight: 300;
                                > strong {
                                    font-weight: 500;
                                }

                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }
                        }

                        @media (max-width: 720px) {
                            &-header {
                                box-shadow: none;
                                padding-bottom: 25px;
                            }
                            &-body {
                                padding-top: 0;
                                padding-bottom: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}
