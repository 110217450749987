@import "assets/sass/variables";
@import "assets/sass/mixins";

.mission__vision {
    width: 100%;
    display: grid;

    &-content {
        > [class*="fa-"] {
            color: $c-1;
        }
    }

    @media (min-width: 1301px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 70px;
        &-content {
            &:first-child {
                margin-top: 250px;
            }
            > [class*="fa-"] {
                font-size: 50px;
                margin-bottom: 20px;
            }
            > .title__ppal {
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        $spacing: 80px;
        grid-template-columns: repeat(2, 1fr);
        gap: 70px;
        padding: 0 $spacing;

        &-content {
            &:first-child {
                margin-top: 250px;
            }
            > [class*="fa-"] {
                font-size: 50px;
                margin-bottom: 20px;
            }
            > .title__ppal {
                margin-bottom: 20px;
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 60px;

        gap: 0;
        padding: $spacing;
        display: flex;
        flex-direction: column-reverse;
        
        &-content {
            > [class*="fa-"] {
                font-size: 40px;
                margin-bottom: 15px;
            }
            > .title__ppal {
                margin-bottom: 15px;
            }
            &:first-child {
                margin-top: 50px;
                > .title__ppal {
                    margin-top: 0;
                }
            }
        }
    }

    @media (max-width: 720px) {
        $spacing: 35px;
        padding: $spacing;
        &-content {
            > [class*="fa-"] {
                font-size: 40px;
                margin-bottom: 15px;
            }
            > .title__ppal {
                margin-bottom: 15px;
            }
            &:first-child {
                margin-bottom: 50px;
                > .title__ppal {
                    margin-top: 0;
                }
            }
        }
    }
}
