@import "assets/sass/_variables";
@import "assets/sass/_mixins";

$radius: 10px;

.ant-form {
    &-item {
        margin-bottom: 25px;
        &-label {
            line-height: normal !important;
            padding: 0 !important;
            margin-bottom: 5px;
            > label {
                font-size: 14px;
                display: block;
                > span {
                    font-weight: 200;
                    opacity: 0.7;
                }
                > strong {
                    font-weight: 600;
                }
                &::before {
                    display: none !important;
                }
            }
        }
        &-required {
            display: flex;
            flex-direction: row-reverse;
            &::before {
                margin-right: 0;
                margin-left: 5px;
            }

            &::after {
                content: "*" !important;
                color: $c-danger;
                display: inline-block !important;
                margin-left: 5px;
            }
        }
        &-control {
            &-input {
                &-content {
                    border-radius: $s-controls;
                    min-height: $s-controls;
                    background-color: transparent;
                    .ant {
                        // input
                        &-input {
                            height: $s-controls + 1px;
                            border-radius: $radius;
                            padding: 0 20px;
                            font-size: 17px;
                            font-family: $f-second;
                            color: $c-dr;
                            background-color: $c-gr-5;
                            border-color: transparent;
                            &:hover {
                                border-color: #eee !important;
                                box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.1);
                            }
                            &:focus {
                                outline: none;
                                box-shadow: 4px 4px 0 0 $c-2 !important;
                                color: $c-dr;
                                border-color: $c-2 !important;
                                background-color: $c-wh;
                            }
                        }
                        // select
                        &-select {
                            width: 100%;
                            height: $s-controls;
                            border-radius: $radius;
                            &-selector {
                                height: 100% !important;
                                border-radius: $radius !important;
                                padding: 0 20px !important;
                                border-color: transparent !important;
                                background-color: $c-gr-5 !important;
                                .ant-select-selection {
                                    &-search {
                                        left: 20px;
                                        &-input {
                                            height: 100% !important;
                                            font-size: 15px;
                                        }
                                    }
                                    &-item {
                                        display: flex;
                                        align-items: center;
                                        line-height: normal;
                                        font-size: 17px;
                                        color: $c-dr;
                                        font-family: $f-second;
                                    }
                                    &-placeholder {
                                        display: flex;
                                        align-items: center;
                                        line-height: normal;
                                        font-size: 15px;
                                        color: $c-dr;
                                        opacity: 0.7;
                                        font-family: $f-second;
                                    }
                                }
                            }
                            &-arrow {
                                width: 20px;
                                height: 20px;
                                line-height: normal;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 20px;
                                > .anticon {
                                    font-size: 18px;
                                }
                            }
                            &-focused {
                                &:not(.ant-select-disabled) {
                                    &.ant-select {
                                        &:not(.ant-select-customize-input) {
                                            > .ant-select-selector {
                                                box-shadow: 4px 4px 0 0 $c-2;
                                                border-color: $c-2 !important;
                                                background-color: $c-wh !important;
                                            }
                                        }
                                    }
                                }
                            }
                            &:not(.ant-select-disabled) {
                                &:hover {
                                    > .ant-select-selector {
                                        border-color: $c-gr-5 !important;
                                        background-color: $c-wh !important;
                                        box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.1);
                                    }
                                }
                            }
                        }
                        // datepikcer
                        &-picker {
                            width: 100%;
                            height: $s-controls;
                            border-radius: $radius;
                            border-color: transparent;
                            background-color: $c-gr-5;
                            padding: 0 10px 0 20px !important;
                            box-shadow: 0 0 0 0 transparent;
                            &-input {
                                > input {
                                    font-size: 17px;
                                    font-family: $f-second;
                                    color: $c-dr;
                                }
                            }
                            &-focused {
                                box-shadow: 4px 4px 0 0 $c-2;
                                border-color: $c-2 !important;
                                background-color: $c-wh;
                            }
                            &:hover:not(.ant-picker-focused) {
                                border-color: $c-gr-5 !important;
                                background-color: $c-wh !important;
                                box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.1);
                            }
                        }
                        // password
                        &-input-password {
                            padding: 0;
                            border: none;
                            .ant-input {
                                padding: 0 40px 0 20px;
                                border: 1px solid transparent;
                                &-suffix {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: 5px;
                                    margin-left: 0;
                                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                                    > .anticon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 40px;
                                        height: 40px;
                                    }
                                }
                                &:hover {
                                    border-color: #eee;
                                    background-color: $c-wh;
                                    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                        // afix
                        &-input-affix-wrapper {
                            position: relative;
                            &-focused {
                                box-shadow: none;
                            }
                        }
                    }
                    // textarea
                    textarea {
                        padding-top: 10px !important;
                        padding-bottom: 10px !important;
                    }
                    // recaptcha
                    #reCaptcha {
                        > div {
                            > div {
                                margin: 0 auto;
                            }
                        }
                    }

                    &:hover {
                        > .ant-input,
                        > .ant-select {
                            transition: all 0.2s;
                            background-color: $c-wh;
                            color: $c-dr;
                        }
                    }
                }
            }
            .ant-form-item-explain {
                margin-top: 4px;
                font-size: 14px;
                line-height: 1.2;
                min-height: auto;
                &-error {
                    color: $c-danger;
                }
            }
        }
        &-extra {
            &-action {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
            }
            .ant-form {
                &-item {
                    &-control {
                        &-input {
                            &-content {
                                .ant-input {
                                    padding-right: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-has {
            &-error {
                .ant-form-item {
                    &-label {
                        > label {
                            &::before {
                                color: $c-danger !important;
                            }
                        }
                    }
                    &-control {
                        &-input {
                            &-content {
                                .ant {
                                    &-input,
                                    &-select-selector,
                                    &-picker {
                                        border-color: $c-danger;
                                        background-color: $c-wh !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-success {
                .ant-form-item {
                    &-control {
                        &-input {
                            &-content {
                                .ant {
                                    &-input,
                                    &-select-selector,
                                    &-picker {
                                        background-color: $c-wh;
                                        border-color: $c-gr-3;
                                        &:focus {
                                            border-color: $c-2 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-extra {
                position: relative;
                .ant-input {
                    padding-right: 100px;
                }
                .ant-form {
                    &-item {
                        &-extra {
                            .ant-btn {
                                width: 90px;
                                &.outline {
                                    &.c-dr {
                                        background-color: $c-wh;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // double
        &.inline {
            .ant-form-item-control-input-content {
                display: flex;
            }
        }

        // social
        &-social {
            display: flex;
            flex-direction: column;
            .ant-btn {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 14px;

                &.facebook {
                    flex-direction: row-reverse;
                    &::after {
                        @include fontAwesome("\f39e");
                        display: flex;
                        font-family: "Font Awesome 5 Brands";
                        font-weight: 400;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }

                &:last-child {
                    margin: 0;
                    margin-bottom: 0;
                }
            }
        }

        // modifiers
        &--last {
            margin-bottom: 0;
        }
        &--disabled{
            .ant-form-item{
                &-label{
                    > label{
                        opacity: .6;
                    }
                }
            }
        }

        &.actions {
            margin-bottom: 0;
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-end;
                .ant-btn {
                    &.c-1,
                    &.c-2 {
                        flex: none;
                        margin: 0 0 0 20px;
                    }
                    &:not(.c-1):not(.c-2) {
                        width: 100%;
                        > span {
                            width: 100%;
                            text-align: left;
                            white-space: initial;
                            line-height: 1.2;
                        }
                    }
                }
            }

            &-one {
                .ant-form-item-control-input-content {
                    > .ant-btn {
                        width: auto !important;
                        margin: 0;
                    }
                }
            }

            @media (max-width: 720px) {
                .ant-form-item-control-input-content {
                    > .ant-btn {
                        &:first-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        // customs
        &-editName {
            border-radius: 10px;
            textarea {
                color: $c-dr;
                font-weight: bold;
                font-family: $f-ppal !important;
                line-height: normal;
                min-height: 90px;
                max-height: 120px;

                &:hover,
                &:active,
                &:focus {
                    box-shadow: none !important;
                }

                @media (min-width: 721px) {
                    font-size: 30px !important;
                    padding: 20px;
                }
                @media (max-width: 720px) {
                    font-size: 32px;
                    padding: 10px;
                }
            }
        }

        // featured
        &-featured {
            width: calc(100% + 40px);
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            padding: 20px;
            margin-left: -20px;
        }
    }
}

// controls
///// input number
.ant-input-number {
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: none;
    &-focused {
        box-shadow: none;
    }
    &-handler-wrap {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        opacity: 1;
        border: none;
        .ant-input-number-handler {
            width: 33%;
            height: 100% !important;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.2);
            color: $c-dr;
            > .anticon {
                display: none;
            }
            &-up {
                &::after {
                    @include fontAwesome("\f067");
                    font-weight: 300;
                }
            }
            &-down {
                &::after {
                    @include fontAwesome("\f068");
                    font-weight: 300;
                }
            }
            &:hover {
                height: 100% !important;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }
    &-input-wrap {
        width: 33%;
        height: 100%;
        z-index: 1;
        > input {
            height: 100%;
            padding: 0;
            text-align: center;
            font-family: $f-ppal;
            font-size: 17px;
            font-weight: 400;
        }
    }
    &:hover {
        border-color: transparent;
    }
}
///// input checkbox
.ant-checkbox {
    height: 25px;
    &-checked {
        .ant-checkbox {
            &-inner {
                border: none;
                background-color: $c-1;
            }
        }
        &::after {
            border-color: $c-1;
        }
    }
    &-inner {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            @include fontAwesome("\f00c");
            display: flex;
            color: $c-wh;
        }
        &::after {
            display: none !important;
        }
    }
    &-wrapper {
        display: inline-flex;
        > span {
            &:not(.ant-checkbox) {
                font-weight: 300;
                margin-top: -5px;
            }
        }
        &:hover {
            .ant-checkbox {
                &-inner {
                    border-color: $c-1 !important;
                }
            }
        }
    }
    &:hover {
        .ant-checkbox {
            &-inner {
                border-color: $c-1;
            }
        }
    }
    + span {
        padding: 0 0 0 10px;
    }
}
///// input rate
.ant-rate {
    font-size: 30px;
    color: $c-1;
    &-text {
        width: 50px;
        font-size: 17px;
        margin-left: 20px;
        top: -2px;
        font-family: $f-ppal;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 1px;
        position: relative;
        display: flex;
        justify-content: center;
        padding: 3px 5px;
        border-radius: 8px;
        border: 1px solid $c-gr-3;
        box-shadow: 2px 2px 0 0 $c-gr-3;
    }
}
