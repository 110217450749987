@import "assets/sass/_variables";

.header__ppal {
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 11;
    transition: all 0.3s;
    justify-content: space-between;

    .container {
        &__left {
            width: auto;
            display: flex;
            margin-right: 20px;
            > .logo {
                width: auto;
                height: auto;
                display: flex;
                align-items: center;
                cursor: pointer;
                border-radius: 6px;
                > svg {
                    height: 27px;
                    margin-top: 5px;
                    .logo__noomada {
                        &-type,
                        &-text {
                            fill: $c-wh;
                        }
                    }
                }
                > span {
                    font-family: $f-ppal;
                    margin-left: 8px;
                    font-weight: 400;
                    font-size: 23px;
                    color: $c-wh;
                }
            }
        }
        &__right {
            width: auto;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &-navigation {
                width: auto;
                height: 40px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                position: relative;
                &--item {
                    height: 100%;
                    flex: 1 0 auto;
                    > a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        padding: 0 15px;
                        color: $c-dr;
                        font-weight: 400;
                        font-family: $f-ppal;
                        cursor: pointer;
                        position: relative;
                        transition: all 0.4s;
                        opacity: 0.8;

                        > [class*="fa"] {
                            font-size: 17px;
                            margin-right: 10px;
                            opacity: 0.7;
                            margin-top: 1px;
                            transition: all 0.2s;
                        }
                        > span {
                            font-size: 14px;
                            opacity: 0.9;
                        }
                        &::after {
                            content: "";
                            width: 0px;
                            height: 1px;
                            position: absolute;
                            left: 15px;
                            bottom: 0;
                            background-color: $c-dr;
                            transition: all 0.2s;
                        }
                        &.active {
                            &::after {
                                width: 20px;
                            }
                            > [class*="fa"],
                            > span {
                                color: $c-dr;
                                opacity: 1;
                                letter-spacing: 1px;
                                font-size: 13px;
                            }
                            &:hover {
                                > [class*="fa"],
                                > span {
                                    color: $c-dr;
                                }
                            }
                        }
                        &:hover {
                            &::after {
                                width: 10px;
                            }
                            > [class*="fa"] {
                                color: $c-2;
                                opacity: 0.9;
                            }
                        }
                    }
                    > .ant-dropdown-icon {
                        width: 40px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        justify-content: center;
                        height: 100%;
                        border-radius: 100%;
                        > [class*="fa-"] {
                            font-size: 25px;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                        > .ant-dropdown-icon {
                            > [class*="fa-"] {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

            > .open-search {
                width: 40px;
                height: 40px;
                padding: 0;
                margin-top: 1px;
                box-shadow: none;
                border: none;
                transition: all 0.2s;
                background-color: rgba(255, 255, 255, 0.7);
                > [class*="fa-"] {
                    font-size: 18px;
                    margin-right: 0;
                    color: $c-dr;
                }
                &:focus {
                    > [class*="fa-"] {
                        color: $c-dr;
                    }
                }
                &:hover {
                    box-shadow: none;
                    background-color: $c-dr;
                    color: $c-wh;
                    > [class*="fa-"] {
                        color: $c-wh !important;
                    }
                }
                &-active {
                    background-color: $c-dr;
                    > [class*="fa-"] {
                        color: $c-wh !important;
                    }
                }
            }

            > .dropdown {
                &__default,
                &__deptos {
                    background-color: $c-wh;
                }
            }

            > .shopping__cart {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $c-wh;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                border-radius: 100%;
                margin-right: 10px;
                cursor: pointer;
                position: relative;
                > [class*="fa-"] {
                    font-size: 18px;
                }
                > .counter {
                    width: 22px;
                    height: 22px;
                    background-color: $c-wh;
                    color: $c-dr;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    position: absolute;
                    top: -6px;
                    right: -8px;
                    font-family: $f-ppal;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    font-size: 13px;
                    font-weight: 500;
                    padding-left: 2px;
                }
                &:hover {
                    background-color: $c-gr;
                }

                &.active {
                    background-color: $c-1;
                    > [class*="fa-"] {
                        color: $c-wh;
                    }
                }
            }
        }
    }

    &-mode {
        &--light {
            .container {
                &__right {
                    &-navigation {
                        &--item {
                            > a {
                                color: $c-wh;
                                &::after {
                                    background-color: $c-wh;
                                }
                                &:hover {
                                    > [class*="fa-"] {
                                        color: $c-wh;
                                    }
                                }
                                &.active,
                                &.active:hover {
                                    > [class*="fa-"],
                                    > span {
                                        color: $c-wh;
                                    }
                                }
                            }
                            > .ant-dropdown-icon {
                                > [class*="fa-"] {
                                    color: $c-wh;
                                }
                                &:hover {
                                    background-color: rgba(255, 255, 255, 0.1);
                                }
                            }
                        }
                    }

                    > .open-search {
                        > [class*="fa-"] {
                            color: $c-dr;
                        }
                        &:hover {
                            > [class*="fa-"] {
                                color: $c-wh;
                            }
                        }
                    }
                }
            }
        }
        &--dark {
            .container {
                &__left {
                    > .logo {
                        > svg {
                            .logo__noomada {
                                &-type {
                                    fill: $c-1;
                                }
                                &-text {
                                    fill: $c-dr;
                                }
                            }
                        }
                    }
                }
                &__right {
                    &-navigation {
                        &--item {
                            > a {
                                color: $c-dr;
                                &::after {
                                    background-color: $c-dr;
                                }
                                &:hover {
                                    > [class*="fa-"] {
                                        color: $c-dr;
                                    }
                                }
                                &.active,
                                &.active:hover {
                                    > [class*="fa-"],
                                    > span {
                                        color: $c-dr;
                                    }
                                }
                            }
                            > .ant-dropdown-icon {
                                > [class*="fa-"] {
                                    color: $c-dr;
                                }
                            }
                        }
                    }
                    > .open-search {
                        > [class*="fa-"] {
                            color: $c-dr;
                        }
                        &:hover {
                            > [class*="fa-"] {
                                color: $c-dr;
                            }
                        }
                    }
                }
            }
        }
        &--duotone {
            .container {
                &__left {
                    > .logo {
                        > svg {
                            > path {
                                fill: $c-1;
                            }
                        }
                        > span {
                            color: $c-dr;
                        }
                    }
                }
                &__right {
                    &-navigation {
                        &--item {
                            > a {
                                color: $c-wh;
                                &::after {
                                    background-color: $c-wh;
                                }
                                &:hover {
                                    > [class*="fa-"] {
                                        color: $c-wh;
                                    }
                                }
                                &.active,
                                &.active:hover {
                                    > [class*="fa-"],
                                    > span {
                                        color: $c-wh;
                                    }
                                }
                            }
                            > .ant-dropdown-icon {
                                > [class*="fa-"] {
                                    color: $c-wh;
                                }
                            }
                        }
                    }
                    > .open-search {
                        > [class*="fa-"] {
                            color: $c-wh;
                        }
                        &:hover {
                            > [class*="fa-"] {
                                color: $c-wh;
                            }
                        }
                    }
                }
            }
        }
    }
    &-active {
        &--scroll {
            background-color: rgba(255, 255, 255, .85);
            backdrop-filter: blur(3px);
            .container {
                &__left {
                    .logo {
                        > svg {
                            .logo__noomada {
                                &-type {
                                    fill: $c-1;
                                }
                                &-text {
                                    fill: $c-dr;
                                }
                            }
                        }
                    }
                }
                &__right {
                    &-navigation {
                        &--item {
                            > a {
                                color: $c-dr;
                                &.active,
                                &.active:hover {
                                    > [class*="fa-"],
                                    > span {
                                        color: $c-dr;
                                    }
                                    &::after {
                                        background-color: $c-dr;
                                    }
                                }
                                &:hover {
                                    > [class*="fa-"] {
                                        color: $c-dr;
                                    }
                                }
                                &::after {
                                    background-color: $c-dr;
                                }
                            }
                            > .ant-dropdown-icon {
                                > [class*="fa-"] {
                                    color: $c-dr;
                                }
                            }
                        }
                    }
                    > .open-search {
                        > [class*="fa-"] {
                            color: $c-dr;
                        }
                    }
                }
            }
        }
        &--search {
            .container {
                &__right {
                    &-navigation {
                        &--item {
                            .ant-dropdown-icon {
                                > [class*="fa-"] {
                                    color: $c-dr;
                                }
                            }
                            > a {
                                color: $c-dr;
                                &.active {
                                    > span {
                                        color: $c-dr;
                                    }
                                    &::after {
                                        background-color: $c-dr;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 861px) {
        padding: 15px 50px;
        .container {
            &__right {
                &-navigation {
                    &--item {
                        > a {
                            > [class*="fa-"] {
                                display: none;
                            }

                            &.active {
                                text-transform: uppercase;
                                font-weight: 600;
                            }
                        }

                        &-generic {
                            &.user-connected {
                                display: flex;
                            }
                        }
                        &-special {
                            &.user-connected {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &-show {
            &--search,
            &--search-home {
                .open-search {
                    display: block !important;
                }
            }
        }
        &-active {
            &--scroll {
                padding: 15px 50px !important;
            }
            &--search {
                .container {
                    &__left {
                        > .logo {
                            > svg {
                                > path {
                                    fill: $c-wh;
                                }
                            }
                            > span {
                                color: $c-wh;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        padding: 10px 30px;
        &-mode {
            &--light {
                .container {
                    &__left {
                        .logo {
                            > svg {
                                > path {
                                    fill: $c-wh !important;
                                }
                            }
                            > span {
                                color: $c-wh !important;
                            }
                        }
                    }
                }

                &.header__ppal {
                    &-active--scroll {
                        .container {
                            &__left {
                                .logo {
                                    > svg {
                                        > path {
                                            fill: $c-1 !important;
                                        }
                                    }
                                    > span {
                                        color: $c-dr !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-active {
            &--scroll {
                padding: 10px 30px !important;
            }
            &--search {
                .container {
                    &__left {
                        > .logo {
                            > svg {
                                > path {
                                    fill: $c-wh !important;
                                }
                            }
                            > span {
                                color: $c-wh !important;
                            }
                        }
                    }
                }
            }
        }
        &.not-bg-home {
            &.active-bg {
                background-color: $c-wh;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            }
        }

        .container {
            &__left {
                > .logo {
                    > svg {
                        .logo__noomada {
                            &-type {
                                fill: $c-1;
                            }
                            &-text {
                                fill: $c-dr;
                            }
                        }
                    }
                    > span {
                        color: $c-dr;
                    }
                }
            }
            &__right {
                > .access__profile {
                    box-shadow: none;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                }
                &-navigation {
                    &--item {
                        > a {
                            > [class*="fa-"] {
                                display: none;
                            }
                            &.active {
                                > span {
                                    text-transform: uppercase;
                                    font-weight: 600;
                                    font-style: 13px;
                                }
                            }
                        }
                        &-generic {
                            &.user-connected {
                                display: flex;
                            }
                        }
                        &-special {
                            &.user-connected {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &-show {
            &--search,
            &--search-home {
                .open-search {
                    display: block !important;
                }
            }
        }
    }
    @media (max-width: 720px) {
        padding: 0;
        z-index: 12;
        &-mode {
            &--light {
                .container {
                    &__left {
                        .logo {
                            > svg {
                                fill: $c-wh !important;
                            }
                        }
                    }
                }

                &.header__ppal {
                    &-active--scroll {
                        .container {
                            &__left {
                                .logo {
                                    > svg {
                                        .logo__noomada {
                                            &-type {
                                                color: $c-1 !important;
                                            }
                                            &-text {
                                                color: $c-dr !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.not-bg-home {
            &.active-bg {
                background-color: $c-wh;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            }
        }

        .container {
            &__left {
                display: none;
                > .logo {
                    > svg {
                        margin-top: 1px;
                        > path {
                            fill: $c-1;
                        }
                    }
                    > span {
                        display: none;
                    }
                }
            }
            &__right {
                > .access__profile {
                    box-shadow: none;
                    position: fixed;
                    bottom: 21px;
                    right: 30px;
                    padding: 3px;
                    > img,
                    > .icon {
                        width: 30px;
                        height: 30px;
                        margin-right: 0;
                        background-color: $c-3;
                        color: $c-wh;
                    }
                    > [class*="fa-"] {
                        display: none;
                    }
                }
                &-navigation {
                    transition: all 0.2s;
                    position: fixed;
                    bottom: 10px;
                    left: 20px;
                    width: calc(100% - 40px);
                    background-color: $c-wh;
                    height: 60px;
                    padding: 8px 50px 8px 8px;
                    border-radius: 30px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    &--item {
                        flex: 1 0;
                        > a {
                            border-radius: 30px;
                            &::after {
                                display: none;
                            }
                            > [class*="fal"] {
                                margin-right: 0;
                                color: $c-dr;
                            }
                            > span {
                                opacity: 0;
                                max-width: 0;
                                transition: max-width 0.3s ease-out; // note that we're transitioning max-height, not height!
                            }
                            &.active {
                                background-color: $c-gr-2;
                                padding: 0 25px;
                                > span {
                                    opacity: 1;
                                    max-width: 100px;
                                    color: $c-dr !important;
                                }
                                > [class*="fal"] {
                                    margin-right: 10px;
                                    transform: scale(1.4);
                                    opacity: 1;
                                    color: $c-1 !important;
                                    font-weight: 600;
                                }
                            }
                        }
                        > .ant-dropdown-icon {
                            > [class*="fa-"] {
                                transition: all 0.2s;
                            }
                            &-open {
                                > [class*="fa-"] {
                                    width: 38px;
                                    height: 38px;
                                    padding-top: 2px;
                                    border-radius: 100%;
                                    background-color: $c-dr;
                                    color: $c-wh !important;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                        &-generic {
                            &.user-connected {
                                display: none;
                            }
                        }
                        &:first-child {
                            margin-right: 10px;
                            > a {
                                padding: 0;
                                > svg {
                                    height: 18px;
                                    opacity: 0.5;
                                }
                                > span {
                                    display: none;
                                }

                                &.active {
                                    background-color: $c-1;
                                    opacity: 1;
                                    > svg {
                                        opacity: 1;
                                        > path {
                                            fill: $c-wh;
                                        }
                                    }
                                }
                            }
                        }
                        &-more {
                            display: none;
                        }
                        &-search {
                            > .ant-dropdown-icon {
                                width: 100%;
                                opacity: 0.8;
                                > [class*="fa-"] {
                                    color: $c-dr;
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }

                > .open-search {
                    position: fixed;
                    bottom: 20px;
                    right: 85px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    opacity: 0.8;

                    > [class*="fa-"] {
                        font-size: 18px;
                        opacity: 0.7;
                    }
                    &:focus {
                        background-color: rgba(0, 0, 0, 0.05);
                        > [class*="fa-"] {
                            color: $c-dr !important;
                        }
                    }
                    &-active {
                        background-color: $c-dr;
                    }
                }
            }
        }
        &-active {
            &--search {
                .container {
                    &__left {
                        > .logo {
                            > svg {
                                > path {
                                    fill: $c-1 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
