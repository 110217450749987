@import "assets/sass/variables";
@import "assets/sass/mixins";

.ant-alert {
    &-cookies {
        width: 900px;
        background-color: $c-dr;
        border: none;
        border-radius: 17px;
        line-height: normal;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 12;
        &::before {
            @include fontAwesome("\f564");
            margin-right: 20px;
            font-size: 40px;
            font-weight: 200;
            color: $c-1;
        }
    }
    &-with-description {
        &.ant-alert-no-icon {
            padding: 20px 30px;
            .ant-alert {
                &-message {
                    font-family: $f-ppal;
                    font-size: 25px;
                    color: $c-wh;
                }
                &-description {
                    font-family: $f-second;
                    font-weight: 200;
                    font-size: 18px;
                    color: $c-wh;
                }
            }
        }
    }

    &-action {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        .ant-space {
            &-item {
                > a {
                    color: $c-wh;
                    font-weight: 200;
                    font-family: $f-second;
                    opacity: .6;
                    > [class*="fa-"]{
                        margin-left: 5px;
                    }
                }
                &:last-child{
                    margin-left: 10px;
                }
            }
        }
    }

    @media (max-width: 900px){
        &-cookies{
            width: calc(100% + 1px);
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
            bottom: 0;
            margin-left: -1px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            &::before{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
        &-action{
            width: 100%;
            margin-top: 30px;
            padding-left: 0;
            margin-left: 0;
            border-left: none;
            .ant-space{
                width: 100%;
                justify-content: space-between;
                &-item{
                    margin-left: 0;
                    > a{
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
