@import "assets/sass/_variables";
@import "assets/sass/_mixins";
@import "assets/sass/components/_tabs";
@import "assets/sass/components/_caption-place.scss";

.dest__sidebar-caption {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    &--extra {
        background-color: $c-wh;
        margin-top: -20px;
    }
    &-resume {
        position: relative;
        background-color: $c-wh;
        .title__ppal {
            font-weight: 600;
            display: flex;
            flex-direction: column;
            position: relative;
            > .slogan {
                font-weight: 700;
                color: $c-1;
                font-family: $f-third;
                line-height: 1;
            }
            > .destination {
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 2px;
                opacity: 0.45;
                font-weight: 900;
            }

            > .badge__image {
                position: absolute;
                top: 0;
            }
        }
        > .text__enr {
            > h6 {
                font-size: 14px;
                line-height: 1.4;
                opacity: 0.6;
                font-family: $f-second;
                > strong {
                    font-weight: 700;
                }
            }
            > p {
                margin: 10px 0;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-state {
            background-color: $c-gr-5;
            display: inline-block;
            margin: 10px 0 0;
            border-radius: 15px;
        }
    }
    &-extra {
        .rates,
        .schedules,
        .service {
            &__list {
                display: grid;
                margin-bottom: 20px;
                > li {
                    display: flex;
                    > [class*="fa-"] {
                        color: $c-1;
                        font-size: 30px;
                        opacity: 0.8;
                        flex: 1 0 auto;
                        margin-right: 15px;
                        margin-top: 5px;
                    }
                    > span {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        font-size: 12px;
                        font-family: $f-ppal;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.4);
                        line-height: 1.2;
                        text-transform: uppercase;
                        letter-spacing: 0.8px;

                        > strong {
                            letter-spacing: normal;
                            font-family: $f-ppal;
                            text-transform: none;
                            font-weight: 200;
                            line-height: normal;
                            color: $c-dr;
                            > sup {
                                display: inline-block;
                                position: relative;
                                top: -10px;
                                margin-left: 3px;
                                font-size: 12px;
                                opacity: 0.5;
                                font-weight: 500;
                            }
                        }
                        ul{
                            display: flex;
                            flex-direction: column;
                            &:first-of-type{
                                margin-top: 10px;
                            }
                            > li{
                                font-size: 15px;
                                margin-bottom: 8px;
                                strong{
                                    font-weight: 500;
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            &__restrictions {
                background-color: $c-wh;
                border-radius: 10px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
                font-family: $f-second;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 15px 20px 15px 45px;
                &::before {
                    content: "*";
                    color: $c-1;
                    font-weight: 700;
                    display: block;
                    flex: 1 0 auto;
                    font-size: 25px;
                    position: absolute;
                    left: 20px;
                    top: 7px;
                }
                > span {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.2;
                    width: 100%;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                strong{
                    font-weight: 600;
                }
            }
        }
        .rates {
            &__list {
                > li {
                    > span {
                        > strong {
                            font-size: 25px;
                        }
                    }
                }
            }
        }
        .schedules {
            &__list {
                > li {
                    > span {
                        > strong {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .list {
            &__services,
            &__recommendations {
                display: grid;
                > li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-radius: 10px;

                    > [class*="fa-"] {
                        width: 18px;
                        display: flex;
                        justify-content: center;
                        font-size: 18px;
                        color: $c-1;
                        margin-right: 15px;
                    }
                    > span {
                        font-size: 14px;
                        font-family: $f-second;
                        letter-spacing: .3px;
                        font-weight: 300;
                        text-align: left;
                        color: $c-dr;
                        line-height: 1.2;
                    }
                }
            }
            &__services{
                margin-bottom: 30px;
            }
        }
    }
    &-reservation {
        background-repeat: no-repeat;
        > .filters__category {
            margin-bottom: 20px;
        }
        > .empty__data {
            box-shadow: none;
            border-radius: 0;
            overflow-x: hidden;
        }
        .ant-typography {
            display: block;
        }
        .ant-btn {
            width: fit-content;
        }
    }

    .text__enr {
        > .info__adi {
            font-size: 13px;
            font-weight: bold;
            font-family: $f-second;
            opacity: 0.6;
            margin-top: 30px;
            line-height: 1.2;
            > a {
                color: $c-1;
                text-decoration: underline;
            }
        }
    }

    @media (min-width: 1301px) {
        border-radius: 0 0 0 20px;
        @include scroll($c-wh, $c-wh);
        &-resume {
            padding: 10px 0 30px;
            > .title__ppal,
            > .text__enr,
            > .place__dates,
            > .ant-skeleton {
                padding: 0 80px;
            }
            > .title__ppal {
                margin-bottom: 15px;
                > .slogan {
                    font-size: 30px;
                }
                &.with-badge {
                    padding-left: 130px;
                    > .badge__image {
                        left: 80px;
                        width: 40px;
                    }
                }
            }
        }

        &-extra {
            .rates {
                &__list {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            .schedules {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 25px;
                    margin-bottom: 25px;
                }
            }
            .list {
                &__services,
                &__recommendations {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px 20px;
                }
            }
            .how__to-get {
                display: flex;
                flex-wrap: wrap;
                > .ant-btn {
                    width: calc(50% - 10px);
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &-info {
                    width: 100%;
                }
            }
        }

        &-reservation {
            padding: 40px 0;
            background-size: 200px;
            background-position: 100% center;
            > .filters__category {
                padding: 0 80px;
                &::after {
                    width: 1px;
                }
            }
            > .empty__data {
                padding: 10px 0 15px 80px;
            }
            > .list__items {
                &-providers {
                    padding: 5px 80px;
                }
            }
            &.no-data {
                padding: 40px 150px 40px 80px;

                .ant-typography {
                    margin: 10px 0 20px;
                    padding-right: 80px;
                }
            }
        }

        .place {
            &__dates {
                margin: 0 0 25px;
                border-radius: 10px;
            }
            &__info {
                padding: 0 80px 40px;
            }
        }

        &:hover {
            @include scroll($c-wh, rgba(0, 0, 0, 0.2));
        }
    }
    @media (min-width: 1101px) and (max-width: 1300px) {
        $spacing: 60px;

        border-radius: 0 0 0 20px;
        &-resume {
            padding: 20px 0 30px;
            > .title__ppal,
            > .text__enr,
            > .place__dates,
            > .ant-skeleton {
                padding: 0 $spacing;
            }
            > .title__ppal {
                margin-bottom: 15px;
                > .slogan {
                    font-size: 30px;
                }
            }
        }

        &-extra {
            .rates {
                &__list {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            .schedules {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                }
            }
            .list {
                &__services,
                &__recommendations {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px 20px;
                }
            }
            .how__to-get {
                display: flex;
                flex-wrap: wrap;
                > .ant-btn {
                    width: calc(50% - 10px);
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &-info {
                    width: 100%;
                }
            }
        }

        &-reservation {
            padding: 30px 0;
            background-size: 200px;
            background-position: 100% center;
            > .filters__category {
                padding: 0 60px;
                &::after {
                    width: 1px;
                }
            }

            > .list__items {
                &-providers {
                    padding: 5px 60px;
                }
            }

            &.no-data {
                padding: 40px 150px 40px 60px;

                .ant-typography {
                    margin: 10px 0 20px;
                    padding-right: 80px;
                }
            }
        }

        .place {
            &__dates {
                margin: 0 0 20px;
                border-radius: 10px;
            }
            &__info {
                padding: 0 $spacing 30px;
            }
        }
    }
    @media (min-width: 861px) and (max-width: 1100px) {
        $spacing: 60px;

        border-radius: 0 0 0 20px;
        &-resume {
            padding: 20px 0 30px;
            > .title__ppal,
            > .text__enr,
            > .place__dates,
            > .ant-skeleton {
                padding: 0 $spacing;
            }
            > .title__ppal {
                margin-bottom: 20px;
                > .slogan {
                    font-size: 25px;
                }
                &.md {
                    font-size: 35px;
                }
            }
        }

        &-extra {
            .rates {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }
            }
            .schedules {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }
            }
            .list {
                &__services,
                &__recommendations {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 20px;
                }
            }
            .how__to-get {
                display: flex;
                flex-wrap: wrap;
                > .ant-btn {
                    width: calc(50% - 10px);
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &-info {
                    width: 100%;
                }
            }
        }

        &-reservation {
            padding: 30px 0;
            background-size: 200px;
            background-position: 100% center;
            > .filters__category {
                padding: 0 60px;
                &::after {
                    width: 1px;
                }
            }
            > .list__items {
                &-providers {
                    padding: 5px 60px;
                }
            }
            .ant-typography {
                margin: 10px 0 20px;
                padding-right: 160px;
            }
            &.no-data {
                padding: 40px 150px 40px 60px;

                .ant-typography {
                    margin: 10px 0 20px;
                    padding-right: 80px;
                }
            }
        }

        .place {
            &__dates {
                margin: 0 0 20px;
                border-radius: 10px;
            }
            &__info {
                padding: 0 $spacing 30px;
            }
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 50px;

        border-radius: 20px 20px 0 0;
        &-resume {
            padding: 10px 0 40px;
            border-radius: 20px 20px 0 0;
            > .title__ppal,
            > .text__enr,
            > .place__dates,
            > .ant-skeleton {
                padding: 0 $spacing;
            }
            > .title__ppal {
                margin-bottom: 20px;
                > .slogan {
                    font-size: 25px;
                }
            }
        }

        &-extra {
            .rates {
                &__list {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }
            }
            .schedules {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }
            }
            .list {
                &__services,
                &__recommendations {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px 20px;
                }
            }
            .how__to-get {
                display: flex;
                flex-wrap: wrap;
                > .ant-btn {
                    width: calc(50% - 10px);
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                &-info {
                    width: 100%;
                }
            }
        }

        &-reservation {
            padding: 40px 0;
            background-size: 200px;
            background-position: 100% center;
            > .filters__category {
                padding: 0 50px;
                &::after {
                    width: 1px;
                }
            }
            > .list__items {
                &-providers {
                    padding: 5px 50px;
                }
            }
            &.no-data {
                padding: 40px 150px 40px 50px;

                .ant-typography {
                    margin: 10px 0 20px;
                    padding-right: 80px;
                }
            }
        }

        .place {
            &__dates {
                margin: 0 0 40px;
                border-radius: 10px;
            }
            &__info {
                padding: 0 $spacing 40px;
            }
        }
    }
    @media (max-width: 720px) {
        $spacing: 35px;
        border-radius: 20px 20px 0 0;
        &-extra {
            .rates {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 30px;
                }
            }
            .schedules {
                &__list {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 25px;
                }
            }
            .list {
                &__services,
                &__recommendations {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px 20px;
                }
            }
            .how__to-get {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                > .ant-btn {
                    width: 100%;
                }
            }
        }
        &-reservation {
            padding: 35px 0;
            background-size: 200px;
            background-position: calc(100% + 50px) center;
            > .filters__category {
                padding: 0 35px;
                &::after {
                    width: 1px;
                }
            }
            > .empty__data {
                position: relative;
                padding: 10px 120px 15px 35px;
                > .text__enr-2 {
                    z-index: 2;
                }
                > img {
                    width: 200px;
                    right: -50px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
            }
            > .list__items {
                &-providers {
                    padding: 5px 35px;
                }
            }
            &.no-data {
                padding: 35px 70px 35px 35px;

                .ant-typography {
                    margin: 10px 0 20px;
                    padding-right: 80px;
                }
            }
        }
        &-resume {
            border-radius: 20px 20px 0 0;
            padding: $spacing 0 35px;
            > .title__ppal,
            > .text__enr,
            > .place__dates,
            > .ant-skeleton {
                padding: 0 $spacing;
            }
            > .title__ppal {
                margin-bottom: 20px;
                > .slogan {
                    font-size: 25px;
                }
            }
            > .place__dates {
                margin: 20px 0;
            }
        }
        &-extra {
            padding: 0;
        }
        .place {
            &__dates {
                margin: 0 0 20px;
                border-radius: 10px;
            }
            &__info {
                padding: 0 35px 35px;
            }
        }
    }
}
