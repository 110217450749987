@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.search__results {
    display: flex;
    flex-direction: column;

    &-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom: 15px;
        position: relative;
        > .ant-btn {
            position: absolute;
            top: 0;
            height: 40px;
            width: 40px;
            right: 0;
            border: none;
            padding: 0;
            box-shadow: none;
            z-index: 2;
            > [class*="fa-"] {
                margin-top: 2px;
                opacity: 0.5;
                margin-right: 0;
                font-size: 18px;
            }
            &:focus {
                > [class*="fa-"] {
                    color: $c-dr;
                }
            }
            &:hover {
                background-color: $c-1;
                > [class*="fa-"] {
                    color: $c-wh;
                    opacity: 1;
                }
            }
        }
        > a {
            display: flex;
        }
        &--image {
            object-fit: cover;
            object-position: center;
            border-radius: 10px;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $c-gr-2;

            > [class*="fa-"] {
                color: $c-dr;
                opacity: 0.6;
            }
        }
        &--content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $c-dr;

            .result {
                &-title {
                    font-family: $f-ppal;
                    line-height: 1.1;
                    padding-right: 50px;
                }
                &-complement {
                    font-family: $f-second;
                    font-size: 15px;
                    opacity: 0.6;
                }
            }
        }

        &--saved {
            > .ant-btn {
                background-color: $c-1;
                > [class*="fa-"] {
                    color: $c-wh;
                    opacity: 1;
                    font-weight: 600;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    @media (min-width: 861px) {
        &-item {
            margin-bottom: 15px;
            &--image {
                width: 60px;
                height: 60px;
                margin-right: 15px;
                > [class*="fa-"] {
                    font-size: 25px;
                }
            }
            &--content {
                .result {
                    &-title {
                        font-size: 22px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
    @media (max-width: 860px) {
        &-item {
            margin-bottom: 15px;
            &--image {
                width: 50px;
                height: 50px;
                margin-right: 15px;
                > [class*="fa-"] {
                    font-size: 18px;
                }
            }
            &--content {
                .result {
                    &-title {
                        font-size: 17px;
                        font-weight: 400;
                        margin-bottom: 1px;
                    }
                    &-complement {
                        font-size: 14px;
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}
