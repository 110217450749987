@import "assets/sass/_variables";

.score {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  &__stars {
    display: flex;
    align-items: center;
    width: 100%;
    > [class*="fa-"] {
      font-size: 18px;
      color: $c-1;
      font-weight: 600;
      margin-left: 7px;
      margin-right: 10px;
    }
    &-value {
      width: 100%;
      font-weight: 400;
      font-size: 25px;
      display: flex;
      align-items: center;
      > span {
        font-size: 16px;
        font-weight: 300;
        margin-left: 10px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          content: "/";
          display: flex;
          font-size: 20px;
          margin-right: 10px;
          opacity: 0.1;
        }
      }

      &--empty {
        > span {
          &::before {
            content: "";
          }
        }
      }
    }
  }
}
