@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.credits__panel {
    .artists__list {
        display: grid;
        padding: 10px 0;
        &-item {
            border: 1px solid $c-gr-5;
            padding: 10px 40px 10px 20px;
            color: $c-dr;
            border-radius: 10px;
            line-height: 1.2;
            position: relative;
            transition: all 0.2s;

            &-category {
                text-transform: uppercase;
                font-family: $f-ppal;
                font-weight: 600;
                opacity: 0.5;
                letter-spacing: 1px;
                font-size: 11px;
            }
            &-name {
                font-family: $f-second;
                margin-top: -1px;
                opacity: 0.9;
                @include truncate(18px, 1.2, 1);
            }

            > [class*="fa-"] {
                position: absolute;
                font-size: 18px;
                opacity: 0.5;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: all .2s;
            }

            &:hover {
                border-color: rgba(0, 0, 0, 0.4);
            }
            &.link {
                &:hover {
                    border-color: $c-1;
                    > [class*="fa-"]{
                        color: $c-1;
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media (min-width: 1301px) {
        .artists__list {
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
        > .title__ppal {
            margin-bottom: 10px;
        }
    }
    @media (min-width: 861px) and (max-width: 1300px) {
        .artists__list {
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
        > .title__ppal {
            margin-bottom: 10px;
        }
    }
    @media (min-width: 721px) and (max-width: 860px) {
        .artists__list {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }
        > .title__ppal {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 720px) {
        .artists__list {
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
        }
        > .title__ppal {
            margin-bottom: 5px;
        }
    }
}
