@import "assets/sass/_variables";

.access__profile {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, .1);
  padding: 4px 5px 3px 3px;
  border-radius: 40px;
  cursor: pointer;
  margin-left: 10px;
  transition: all .2s;
  position: relative;
  > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: $c-2;
      background-color: rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
  }
  > .icon,
  > img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin-right: 7px;
  }
  > img {
      background-color: #bdbdbd;
      object-fit: cover;
      object-position: center;
  }
  > [class*="fa-"] {
      font-size: 16px;
      margin-right: 5px;
  }
  &-have{
      &--notifi{
          &::after{
              content: '';
              width: 12px;
              height: 12px;
              background-color: $c-1;
              border-radius: 100%;
              position: absolute;
              top: 0px;
              right: -2px;
          }
      }
  }
  &:hover {
      border-color: $c-2;
      box-shadow: none;
  }

  &--loading {
    > .icon {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: transparent;
        animation: rotate-spinner 2.5s linear infinite;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        border-color: $c-1 transparent $c-1 $c-1;
      }
    }
  }
}

@keyframes rotate-spinner {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
