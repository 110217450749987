@import 'assets/sass/_variables';

.ant-typography{
    display: block;
    &-secondary{
        font-family: $f-second;
        font-weight: 500;
        font-size: 16px;
        line-height: normal;
        > strong{
            color: rgba(0, 0, 0, .6);
            font-weight: bold;
        }
    }
    &-helping{
        .subtitle__ppal{
            font-size: 20px;
            margin-bottom: 10px !important;
            color: $c-dr !important;
        }
        > p{
            line-height: 1.3;
            color: #9f9f9f !important;
            > quote{
                background-color: $c-gr-2;
                padding: 5px 7px;
            }
        }
    }
    > a{
        color: $c-2;
        text-decoration: underline;
        &:hover{
            color: $c-2;
            text-decoration: underline;
        }
    }
    > .fake-link{
        color: $c-1;
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
    }

    &.m-b{
        margin-bottom: 20px;
    }
    &.m-t{
        margin-top: 20px;
    }

    
    @media (max-width: 600px){
        &-secondary{
            font-size: 15px;
            line-height: 1.5;
        }
    }
}