@import "assets/sass/_variables";

.upload__photo{
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: $c-gr-2;
    border: 2px dashed rgba(0, 0, 0, .1);
    border-radius: 10px;
    > .ant-btn{
        margin: 0 auto 20px;
        position: relative;
        > [type="file"]{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }
    }
    .ant-typography{
        > p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}