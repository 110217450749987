@import "assets/sass/_variables";

.banner__depto {
    background-color: $c-dr;
    flex: 1 0 auto;
    position: relative;
    overflow: hidden;

    .banner__bg {
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            z-index: 2;
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            top: 0;
        }

        > img {
            object-fit: cover;
            pointer-events: none;
            object-position: center;
        }
        &-copy {
            position: absolute;
            left: 20px;
            font-size: 13px;
            text-align: left;
            color: $c-wh;
            font-family: $f-second;
            font-weight: 500;
            opacity: 0.7;
            text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
        }
    }

    > .region__depto {
        position: absolute;
        border: 1px solid $c-wh;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.2);
        color: $c-wh;
        border-radius: 30px;
        font-family: $f-second;
        transition: all 0.2s;

        &:hover {
            border-color: transparent;
            background-color: $c-1;
        }
    }

    @media (min-width: 860px) {
        $pdn-rest: 15px;

        width: 100%;
        height: 100%;

        .banner__bg {
            position: absolute;
            height: 100%;
            > img {
                width: 100%;
                height: 100%;
            }
            &-copy {
                bottom: 20px;
            }
        }

        > .title__banner {
            font-weight: 600;
            font-size: 40px;
            font-family: $f-ppal;
            line-height: 1;
            text-transform: uppercase;
            text-shadow: -3px 3px 0 rgba(0, 0, 0, 0.2);

            &.c-dr {
                color: $c-dr;
            }
            &.c-wh {
                color: $c-wh;
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        height: 100%;
        .banner__bg {
            position: fixed;
            height: 300px;
            > img {
                width: 100%;
                height: 100%;
            }
            &-copy {
                bottom: 30px;
            }
        }
    }
    @media (max-width: 720px) {
        height: 100%;
        .banner__bg {
            position: fixed;
            height: 200px;
            > img {
                width: 100%;
                height: 100%;
            }
            &-copy {
                bottom: 30px;
            }
        }
    }
}
