@import "assets/sass/variables";
@import "assets/sass/mixins";

.popover {
    &__default {
        width: 350px;
        > .ant-popover-content {
            .ant-popover {
                &-inner {
                    border-radius: 10px;
                    padding: 10px 4px 10px 10px;
                    &-content {
                        max-height: 310px;
                        overflow: auto;
                        padding: 10px;
                        @include scroll($c-wh, rgba(0, 0, 0, 0.2));
                        .title__ppal {
                            font-size: 20px;
                            margin-bottom: 10px;
                        }
                        .ant-typography {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &-compact {
            width: 250px;
        }
    }
    &__search {
        width: 350px;
        > .ant-popover-content {
            .ant-popover {
                &-inner {
                    border-radius: 10px;
                    padding: 10px;
                    &-content {
                        max-height: 250px;
                        overflow: auto;
                        padding: 0;
                        @include scroll($c-wh, rgba(0, 0, 0, 0.2));
                        > .list__results {
                            display: flex;
                            flex-direction: column;
                            > li {
                                > a {
                                    padding: 10px 10px;
                                    display: flex;
                                    align-items: center;
                                    border-radius: 5px;
                                    transition: all 0.2s;
                                    > [class*="fa"] {
                                        transition: all 0.2s;
                                        font-size: 17px;
                                        width: 35px;
                                        height: 35px;
                                        margin-right: 10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: rgba(0, 0, 0, 0.1);
                                        color: rgba(0, 0, 0, 0.5);
                                        border-radius: 5px;
                                        flex: 1 0 auto;
                                    }
                                    > span {
                                        width: 100%;
                                        font-size: 15px;
                                        color: $c-dr;
                                        font-family: $f-second;
                                        font-weight: 200;
                                    }
                                    &:hover {
                                        background-color: $c-gr;
                                        > [class*="fa"] {
                                            background-color: $c-1;
                                            color: $c-wh;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__generic {
        width: 230px;
        > .ant-popover-content {
            .ant-popover {
                &-inner {
                    border-radius: 10px;
                    padding: 15px 0;
                    max-height: 300px;
                    overflow: auto;
                    @include scroll($c-wh, rgba(0, 0, 0, 0.2));
                    .ant-popover {
                        &-title {
                            min-width: auto;
                            min-height: auto;
                            padding: 0 15px;
                            border-bottom: none;
                            font-family: $f-ppal;
                            font-size: 18px;
                            line-height: normal;
                            margin-bottom: 10px;
                        }
                        &-inner {
                            &-content {
                                padding: 0;
                                .more__noomada {
                                    display: flex;
                                    flex-direction: column;
                                    &-item {
                                        padding: 10px 15px;
                                        display: flex;
                                        position: relative;
                                        transition: all .2s;
                                        &-data {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                        }
                                        &-photo {
                                            width: 23px;
                                            height: 23px;
                                            background-color: $c-gr;
                                            border-radius: 100%;
                                            margin-right: 6px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 11px;
                                            font-family: $f-second;
                                            font-weight: 500;
                                            flex: 1 0 auto;

                                            > img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                object-position: center;
                                                border-radius: 100%;
                                            }
                                        }
                                        &-name {
                                            width: 100%;
                                            font-family: $f-second;
                                            padding-right: 30px;
                                            @include truncate(14px, 1.1, 1);
                                        }
                                        &-actions {
                                            width: 30px;
                                            height: 30px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex: 1 0 auto;
                                            border: none;
                                            opacity: 0;
                                            position: absolute;
                                            right: 15px;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            border-radius: 100%;
                                            cursor: pointer;
                                            transition: all .2s;
                                            &:hover {
                                                background-color: rgba(0, 0, 0, 0.1);
                                            }
                                        }
                                        &:hover {
                                            background-color: $c-gr-2;
                                            .more__noomada {
                                                &-item {
                                                    &-actions {
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    @media (max-width: 1301px){
                                        &-item{
                                            &-actions{
                                                opacity: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
