@import "assets/sass/variables";
@import "assets/sass/mixins";

.you__find {
    display: flex;
    flex-direction: column;
    > .list__activities {
        display: grid;
        > li {
            .activity {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                &-image {
                    border-radius: 10px;
                    background-color: $c-1;
                    > img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                        object-position: center;
                        background-color: $c-gr;
                        transition: all 0.2s;
                    }
                }

                .text__enr {
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: 1301px) {
        > .title__ppal {
            margin-bottom: 30px;
        }
        > .list__activities {
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;
            > li {
                height: auto;
                .activity {
                    &-image {
                        width: 65px;
                        height: 65px;
                        margin-right: 20px;
                        flex: 1 0 auto;
                    }
                    .text__enr {
                        > p {
                            font-size: 16px;
                            line-height: 1.3;
                        }
                    }
                }

                &:hover {
                    .activity {
                        &-image {
                            > img {
                                margin-left: -5px;
                                margin-top: -5px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 861px) and (max-width: 1300px) {
        $spacing: 80px;
        padding: 0 $spacing;
        > .title__ppal {
            margin-bottom: 30px;
        }
        > .list__activities {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
            > li {
                height: auto;
                .activity {
                    &-image {
                        width: 80px;
                        height: 80px;
                        margin-right: 20px;
                        flex: 1 0 auto;
                    }
                    .text__enr {
                        > p {
                            line-height: 1.4;
                        }
                    }
                }

                &:hover {
                    .activity {
                        &-image {
                            > img {
                                margin-left: -8px;
                                margin-top: -5px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 721px) and (max-width: 860px) {
        $spacing: 60px;
        padding: 0 $spacing;
        > .title__ppal {
            margin-bottom: 30px;
        }
        > .list__activities {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
            .activity {
                flex-direction: row;
                padding: 0;
                &-image {
                    width: 60px;
                    height: 60px;
                    flex: 1 0 auto;
                    margin-bottom: 0;
                    margin-right: 20px;
                }
                > .text__enr {
                    > p {
                        line-height: 1.3;
                    }
                }
            }
        }
    }
    @media (max-width: 720px) {
        $spacing: 35px;
        padding: 0 $spacing;
        > .title__ppal {
            margin-bottom: 30px;
        }
        > .list__activities {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            > li {
                .activity {
                    flex-direction: row;
                    padding: 0;
                    &-image {
                        width: 50px;
                        height: 50px;
                        flex: 1 0 auto;
                        margin-bottom: 0;
                        margin-right: 15px;
                    }
                    > .text__enr {
                        > p {
                            font-size: 16px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}
