@import "assets/sass/_variables";

.ant-btn {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    width: auto;
    height: 50px;
    padding: 0 35px;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto 0 0;
    font-size: 17px;
    font-family: $f-second;
    font-weight: 600;

    > [class*="fa-"] {
        font-size: 20px;
        margin-right: 10px;
        margin-top: 2px;
        pointer-events: none;
    }

    > .text {
        pointer-events: none;
    }

    &.outline {
        border: 1px solid transparent;
        box-shadow: none;
        background-color: transparent;
        &.c-dr {
            border-color: rgba(0, 0, 0, 0.1);
            > .text {
                color: $c-dr;
                transition: all 0.2s;
                opacity: 0.7;
            }
            &:hover {
                color: $c-dr;
                border-color: $c-dr;
                > .text {
                    opacity: 1;
                }
            }
        }
        &.c-wh {
            border-color: rgba(255, 255, 255, 0.2);
            > .text {
                color: $c-dr;
                transition: all 0.2s;
                opacity: 0.7;
            }
            &:hover {
                color: $c-dr;
                border-color: $c-dr;
                > .text {
                    opacity: 1;
                }
            }
        }
        &.c-danger {
            border-color: $c-danger;
            > .text {
                color: $c-dr;
                transition: all 0.2s;
                opacity: 0.7;
            }
            &:hover {
                color: $c-dr;
                border-color: $c-dr;
                > .text {
                    opacity: 1;
                }
            }
        }

        &:focus {
            color: $c-dr;
        }
    }
    &.solid {
        border: none;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
        &.c-wh {
            background-color: $c-wh;
            color: $c-dr;
        }
        &.c-1 {
            background-color: $c-1;
            color: $c-wh;
        }
        &.c-2 {
            background-color: $c-2;
            color: $c-wh;
        }
        &.c-3 {
            background-color: $c-2;
            color: $c-wh;
        }
        &.c-gr {
            background-color: $c-gr;
            color: $c-dr;
        }
        &.c-gr-2 {
            background-color: $c-gr-2;
            color: $c-dr;
        }
        &.c-gr-4 {
            background-color: $c-gr-4;
            color: $c-dr;
        }
        &.c-gr-5 {
            background-color: $c-gr-5;
            color: $c-dr;
        }
        &.c-danger {
            background-color: $c-danger;
            color: $c-danger-bg;
        }
        &.c-google {
            background-color: $c-google;
            color: $c-wh;
        }
        &.c-waze {
            background-color: $c-waze;
            color: $c-dr;
        }
        &.c-whatsapp {
            background-color: $c-whatsapp-2;
            color: $c-wh;
        }
        &.c-rgba {
            background-color: rgba(0, 0, 0, 0.05);
            box-shadow: none;
            color: $c-dr;
        }
        &.c-transparent {
            background-color: transparent;
            box-shadow: none;
            color: $c-dr;
            &:hover {
                background-color: $c-gr-2;
                box-shadow: none;
            }
        }

        &:hover {
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
        }
    }
    // modificators
    &-no-shdw {
        box-shadow: none !important;
    }
    &-no-pdng {
        padding: 0;
    }
    &-loading {
        > [class*="fa-"]{
            display: none;
        }
        &-icon {
            > .anticon {
                padding-right: 0 !important;
                margin-right: 10px;
            }
        }
    }
    &-only {
        &--link {
            padding: 0;
            box-shadow: none;
            border: none;
            font-size: 14px !important;
            font-weight: 400;
            border-radius: 0 !important;
            opacity: 0.8;
            &:hover {
                color: $c-dr;
                opacity: 1;
            }
        }
        &--icon {
            padding: 0;
            width: 50px;
            > [class*="fa-"] {
                margin-right: 0;
            }

            &.ant-btn {
                &-size {
                    &--xs {
                        height: 42px;
                        width: 42px;
                        padding: 0;
                        font-size: 14px !important;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    &-size {
        &--xs {
            height: 42px !important;
            padding: 0 20px;
            font-size: 14px !important;
            font-weight: 400;
        }
    }

    &[disabled],
    &-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    @media (max-width: 500px) {
        font-size: 17px;
        height: 55px;
        border-radius: (55px/2);
    }
}
